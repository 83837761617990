export const getHourBtwTwoTimes = (
  startDate: any,
  endDate: any,
  startTime: any,
  endTime: any
) => {
  let nstartDate = new Date(
    startDate && startDate.split('-')[0],
    startDate && startDate.split('-')[1],
    startDate && startDate.split('-')[2],
    startTime && startTime.split(':')[0],
    startTime && startTime.split(':')[1],
    Number('00')
  );
  let nendDate = new Date(
    endDate && endDate.split('-')[0],
    endDate && endDate.split('-')[1],
    endDate && endDate.split('-')[2],
    endTime && endTime.split(':')[0],
    endTime && endTime.split(':')[1],
    Number('00')
  );

  let difference = nendDate.getTime() - nstartDate.getTime();

  difference = difference / 1000;
  return Math.floor(difference / 3600);
};
