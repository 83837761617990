export const getClientsOfOffice = (officeId: any, storedClients: any) => {
  let arr = [];
  for (let client of storedClients) {
    let clientt =
      client &&
      client.offices &&
      client.offices.filter((office: any) => office.value === officeId);

    if (clientt && clientt.length > 0) {
      arr.push(client.name);
    }
  }
  return arr;
};
