export default interface Office {
  id?: string;
  name: string;
  seats: string;
  client?: any;
}

export const isOffice = (obj: any, validate = false): obj is Office => {
  const hasProperties = ('name' in obj && 'seats' in obj) || 'client' in obj;

  if (validate && hasProperties) {
    if (obj.name.length === 0 || obj.seats.length === 0) {
      return false;
    }
  }

  return hasProperties;
};
