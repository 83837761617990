export const LeadFormObj = {
  name: '',
  companyName: '',
  number: '',
  email: '',
  seats: '',
  source: '',
  status: '',
  recentStatus: [],
  requirments: '',
  leadDate: '',
  visitDate: '',
  // tour: '',
  notes: '',
};

export const OfficeFormObj = {
  id: '',
  name: '',
  seats: '',
};

export const ClientFormObj = {
  name: '',
  companyName: '',
  number: '',
  email: '',
  contract: [],
  offices: [],
  invoices: [],
  type: [],
  sharedPrice: '',
  privatePrice: '',
  scheduledMeetingRoom: {},
  consumedHours: {},
  advancePayment: '',
  firstPaymentLessThan1Month: '',
  clientBalance: '',
};

export const ContractFormObj = {
  contractorName: '',
  contractId: '',
  addendumContract: [],
  advancePayment: 0,
  firstPaymentLessThan1Month: 0,
  security: '',
  creater: '',
  companyName: '',
  CNIC_NO: '',
  email: '',
  number: '',
  address: '',
  startDate: '',
  endDate: '',
  startTime: '',
  endTime: '',
  duration: 0,
  offices: [],
  privateSeats: '',
  sharedSeats: '',
  virtualSeats: '',
  isSigned: 'No',
  type: [],
  dedicatedParkingPrice: '',
  sharedPrice: '',
  totalSeatsForSecurity: '',
  nameForInvoice: '',
  invoiceEmail: '',
  privatePrice: '',
  virtualPrice: '',
  recentChangedOffice: [],
};

export const ScheduleClientObj = {
  client: { label: '', value: '' },
  startDate: '',
  endDate: '',
  startTime: '',
  endTime: '',
};

export const sourceOptions = [
  { label: 'Facebook', value: 'Facebook' },
  { label: 'Zameen', value: 'Zameen' },
  { label: 'Olx', value: 'Olx' },
  { label: 'Google Ads', value: 'Google Ads' },
  { label: 'Reference', value: 'Reference' },
  { label: 'Regus', value: 'Regus' },
  { label: 'The Hive', value: 'The Hive' },
  { label: 'Kickstart', value: 'Kickstart' },
  { label: 'Walk-In', value: 'Walk-In' },
];

export const tourOptions = [
  { label: 'Yes', value: 'YES' },
  { label: 'No', value: 'NO' },
];

export const statusOptions: any = [
  { label: 'New', value: 'New' },
  { label: 'Visited', value: 'Visited' },
  { label: 'Scheduled', value: 'Scheduled' },
  { label: 'Pending', value: 'Pending' },
  { label: 'Tour Canceled', value: 'Tour Canceled' },
  { label: 'Missed Call', value: 'Missed Call' },
  { label: 'Proposal Sent', value: 'Proposal Sent' },
  { label: 'Budget Constraints', value: 'Budget Constraints' },
  { label: 'Decision Delayed', value: 'Decision Delayed' },
  { label: 'Went with Competitor', value: 'Went with Competitor' },
  { label: 'Built own Office', value: 'Built own Office' },
  { label: 'Retained by Competitor', value: 'Retained by Competitor' },
  { label: 'Not Interested', value: 'Not Interested' },
  { label: 'Wronge Information', value: 'Wronge Information' },
  { label: 'No Response', value: 'No Response' },
  { label: 'Call For Information Only', value: 'Call For Information Only' },
  { label: 'Working Remotely', value: 'Working Remotely' },

  {
    label: 'Interested in Different Area',
    value: 'Interested in Different Area',
  },
  { label: 'Closed', value: 'Closed' },
];
