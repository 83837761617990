import axios, { AxiosError } from 'axios';

const urlSearchParams = new URLSearchParams(window.location.search);
const params = Object.fromEntries(urlSearchParams.entries());

const isLocal = 'local' in params;

const BASE_URL = isLocal
  ? 'http://localhost:3001'
  : 'https://api.msbtechnologies.pk';

let headers: { [key: string]: string } = {};

export class HttpError extends Error {
  public status: number;
  public body: any;

  constructor(status: number, body: any) {
    super('HTTP Error');
    this.status = status;
    this.body = body;
  }
}

export default class HTTP {
  static setHeader(key: string, value: string) {
    headers[key] = value;
  }

  private static async request<T>(method: string, path: string, data?: any) {
    try {
      const resp = await axios.request<T>({
        headers,
        method,
        baseURL: BASE_URL,
        url: path,
        data,
      });
      return resp.data;
    } catch (e) {
      if (e instanceof AxiosError && e.code && e.response) {
        throw new HttpError(Number.parseInt(e.code), e.response.data);
      }
      console.error(e);
    }
  }

  static async get<T>(path: string) {
    return HTTP.request<T>('GET', path);
  }

  static async post<T>(path: string, body: any) {
    return HTTP.request<T>('POST', path, body);
  }

  static async put<T>(path: string, body: any) {
    return HTTP.request<T>('PUT', path, body);
  }

  static async delete<T>(path: string, body?: any) {
    return HTTP.request<T>('DELETE', path, body);
  }
}
