export const leadFormItems = [
  {
    id: 'name',
    type: 'text',
    label: 'Name',
    isRequired: true,
  },
  {
    id: 'number',
    type: 'tel',
    label: 'Number',
    isRequired: true,
  },
  {
    id: 'companyName',
    type: 'text',
    label: 'Company',
    isRequired: false,
  },

  {
    id: 'email',
    type: 'email',
    label: 'Email',
    isRequired: false,
  },

  {
    id: 'leadDate',
    type: 'date',
    label: 'Lead Date',
    isRequired: false,
  },
  {
    id: 'visitDate',
    type: 'date',
    label: 'Visit Date',
    isRequired: false,
  },
];

export const clientFormItems = [
  {
    id: 'name',
    type: 'text',
    label: 'Name',
    isRequired: true,
  },
  {
    id: 'number',
    type: 'number',
    label: 'Number',
    isRequired: true,
  },
  {
    id: 'companyName',
    type: 'text',
    label: 'Company',
    isRequired: false,
  },

  {
    id: 'email',
    type: 'email',
    label: 'Email',
    isRequired: false,
  },
  // {
  //   id: 'contract',
  //   type: 'text',
  //   label: 'Contract',
  //   isRequired: false,
  // },
];

export const officeFormItems = [
  {
    id: 'name',
    type: 'text',
    label: 'Name',
    isRequired: true,
  },

  {
    id: 'seats',
    type: 'number',
    label: 'Seats',
    isRequired: true,
    min: '0',
  },
  // {
  //   id: 'clientId',
  //   type: 'text',
  //   label: 'Client ID',
  // },
];

export const meetingRoomFormItems = [
  {
    id: 'name',
    type: 'text',
    label: 'Name',
    isRequired: true,
  },
];

export const contractFormItems = [
  {
    id: 'contractorName',
    type: 'text',
    label: 'Contractor Name',
    isRequired: true,
  },
  {
    id: 'companyName',
    type: 'text',
    label: 'Company Name',
    isRequired: true,
  },
  {
    id: 'nameForInvoice',
    type: 'text',
    label: 'Business or Personal Name for Invoice',
    isRequired: true,
  },
  {
    id: 'invoiceEmail',
    type: 'email',
    label: 'Email Address for Invoice',
    isRequired: true,
  },
  {
    id: 'number',
    type: 'tel',
    label: 'Contractor Number',
    isRequired: true,
  },
  {
    id: 'CNIC_NO',
    type: 'tel',
    label: 'CNIC No',
    isRequired: true,
    max: 13,
  },
  {
    id: 'email',
    type: 'email',
    label: 'Email',
    isRequired: true,
  },

  {
    id: 'address',
    type: 'text',
    label: 'Contractor Permanent Address',
    isRequired: true,
  },
  // {
  //   id: 'startDate',
  //   type: 'date',
  //   label: 'Contract Start Date',
  //   isRequired: true,
  // },
  // {
  //   id: 'endDate',
  //   type: 'date',
  //   label: 'Contract End Date',
  //   isRequired: true,
  // },
  {
    id: 'freeHours',
    type: 'number',
    label: 'Free Hours',
    isRequired: true,
    min: '0',
  },
  // {
  //   id: 'duration',
  //   type: 'number',
  //   label: 'Contract Duration',
  //   isRequired: true,
  //   min: 0,
  // },

  {
    id: 'dedicatedParkingPrice',
    type: 'number',
    label: 'Dedicated Parking Price',
  },
  // {
  //   id: 'sharedPrice',
  //   type: 'text',
  //   label: 'Shared Seat Price',
  //   isRequired: true,
  // },
  // {
  //   id: 'privatePrice',
  //   type: 'text',
  //   label: 'Private Seat Price',
  //   isRequired: true,
  // },
];
