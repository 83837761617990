import { useState, useMemo, useEffect, useCallback, useRef } from 'react';
import { Table } from '../../Components/Table';
import { PageWrapper } from '../Page.styles';
import { FaEdit, FaTrash } from 'react-icons/fa';
import { getLeads } from '../../api/leads';

import store from '@jitcoder/usestore';

export default function Leads() {
  const [rowId, setRowId] = useState('');
  const childRef: any = useRef();
  const [modalType, setModalType] = useState('');

  const columns = useMemo(
    () => [
      {
        id: 1,
        name: 'Name',
        selector: (row: any) => row.name,
        sortable: true,
        reorder: true,
        center: true,
        cell: (row: any) => <span title={row.name}> {row.name}</span>,
      },

      {
        id: 2,
        name: 'Number',
        selector: (row: any) => row.number,
        sortable: true,
        reorder: true,
        center: true,
        maxWidth: 400,
        minWidth: 140,
        width: 200,
      },

      {
        id: 3,
        name: 'Seats',
        selector: (row: any) => row.seats,
        sortable: true,
        reorder: true,
        center: true,
      },
      {
        id: 4,
        name: 'Lead Date',
        selector: (row: any) => row.leadDate,
        sortable: true,
        reorder: true,
        center: true,
        maxWidth: 400,
        minWidth: 140,
        width: 200,
      },
      {
        id: 5,
        name: 'Visit Date',
        selector: (row: any) => row.visitDate,
        sortable: true,
        reorder: true,
        center: true,
        maxWidth: 400,
        minWidth: 140,
        width: 200,
      },
      {
        id: 9,
        right: false,
        center: true,
        cell: (row: any) => (
          <>
            <FaEdit
              style={{ marginRight: '5px', fontSize: '1rem' }}
              onClick={() => {
                childRef && childRef.current && childRef.current.handleShow();
                setModalType('EDIT LEAD');
                setRowId(row.id);
              }}
            ></FaEdit>
          </>
        ),
      },
      {
        id: 10,
        right: false,
        center: true,
        cell: (row: any) => (
          <>
            <FaTrash
              style={{ marginRight: '5px', fontSize: '1rem' }}
              onClick={() => {
                childRef && childRef.current && childRef.current.handleShow();
                setModalType('Delete');
                setRowId(row.id);
                store.set('active-modal', true);
              }}
            ></FaTrash>
          </>
        ),
      },
    ],
    []
  );

  const fetchAPI = useCallback(async () => {
    await getLeads();
  }, []);

  useEffect(() => {
    fetchAPI();
  }, [fetchAPI]);

  return (
    <PageWrapper>
      <Table
        defaultSortFieldId={4}
        columns={columns}
        title={'Leads'}
        modalType={modalType}
        buttonTitle={'ADD LEAD'}
        runCallBack={fetchAPI}
        rowId={rowId}
        childRef={childRef}
      ></Table>
    </PageWrapper>
  );
}
