import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
export const Nav = styled.nav`
  display: flex;
  width: 20%;
  flex-direction: column;
  background-color: #047073;
  line-height: 70px;
  padding-top: 20px;

  @media screen and (max-width: 400px) {
    width: auto;
  }
`;
export const NavHeader = styled.label`
  font-size: 1.4rem;
  color: white;
`;
export const FlexLabelIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
  padding-left: 20px;
  color: white;
  // width: 204px;
  > svg {
    font-size: 1.7rem;
    margin-right: 10px;
  }
`;

export const StyledNavLink = styled(NavLink)`
  color: white;
  text-decoration: none;
  // font-family: cursive;
  font-size: 1.2rem;

  & :hover {
    background: #078b8f;
    text-decoration: auto;
  }

  &.active {
    color: white;
    font-weight: bold;
    background: #3c8285;
  }
`;
