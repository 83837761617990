import './App.css';
import Navbar from './Components/Navbar';
import { MdLogout } from 'react-icons/md';
import Cookie from 'js-cookie';
import CSS from 'csstype';
const logoutStyle: CSS.Properties = {
  position: 'fixed',
  top: '80%',
  left: '91%',
  fontSize: '4rem',
  color: 'white',
  borderRadius: '32px',
  padding: '15px',
  backgroundColor: '#047073',
  boxShadow:
    'rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px',
  zIndex: '999',
};
function App() {
  return (
    <div className='App'>
      <div className='main-wrapper'>
        <MdLogout
          className='no-print'
          title='Logout'
          style={logoutStyle}
          onClick={() => {
            Cookie.remove('jwt');
            window.location.reload();
          }}
        />
        <Navbar />
      </div>
    </div>
  );
}

export default App;
