import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Table } from '../../Components/Table';
import { FaEdit, FaTrash } from 'react-icons/fa';
import { PageWrapper } from '../Page.styles';
import { getOffices } from '../../api/offices';
import { getClients } from '../../api/clients';

// import { getClientInOffice } from '../../lib/getClientInOffice';
import store from '@jitcoder/usestore';
import Client from '../../models/client';
import { getClientsOfOffice } from '../../lib/getClientsofOffice';
export default function Offices() {
  const childRef: any = useRef();
  const storedClients = store.get<Client[]>('clients', []);
  const [clients, setClients] = useState<any>([]);
  const [rowId, setRowId] = useState('');
  const [modalType, setModalType] = useState('');
  const columns = useMemo(
    () => [
      {
        id: 1,
        name: 'Name',
        selector: (row: any) => row.name,
        sortable: true,
        reorder: true,
      },

      {
        id: 2,
        name: 'Seats',
        selector: (row: any) => row.seats,
        sortable: true,
        reorder: true,
      },
      {
        id: 3,
        name: 'Type',
        selector: (row: any) => row.type && row.type.label,
        sortable: true,
        reorder: true,
      },
      {
        id: 4,
        name: 'Client',
        sortable: true,
        reorder: true,
        center: true,
        cell: (row: any) => {
          let client;
          if (storedClients.length === 0)
            client = getClientsOfOffice(row.id, clients);
          else client = getClientsOfOffice(row.id, storedClients);

          return (
            <>
              <span id={row.id}>{client && client.join(' , ')}</span>
            </>
          );
        },
      },

      {
        id: 5,
        right: false,
        center: true,
        cell: (row: any) => (
          <>
            <FaEdit
              style={{ marginRight: '5px', fontSize: '1rem' }}
              onClick={() => {
                childRef && childRef.current && childRef.current.handleShow();

                setModalType('EDIT OFFICE');
                setRowId(row.id);
                store.set('active-modal', true);
              }}
            ></FaEdit>
          </>
        ),
      },
      {
        id: 6,
        right: false,
        center: true,
        cell: (row: any) => (
          <>
            <FaTrash
              style={{ marginRight: '5px', fontSize: '1rem' }}
              onClick={() => {
                childRef && childRef.current && childRef.current.handleShow();

                setModalType('Delete');
                setRowId(row.id);
                store.set('active-modal', true);
              }}
            ></FaTrash>
          </>
        ),
      },
    ],
    [clients, storedClients]
  );

  const fetchAPI = useCallback(async () => {
    await getOffices();
    let clientss = await getClients();
    setClients(clientss);
  }, []);

  useEffect(() => {
    fetchAPI();
  }, [fetchAPI]);

  return (
    <PageWrapper>
      <Table
        columns={columns}
        defaultSortFieldId={1}
        title={'Offices'}
        rowId={rowId}
        modalType={modalType}
        childRef={childRef}
        buttonTitle={'ADD OFFICE'}
      ></Table>
    </PageWrapper>
  );
}
