export const get2ArrayofObjectsDiff = (
  array1: any,
  array2: any,
  compareWith: string
) => {
  return array1.filter((object1: any) => {
    return !array2.some((object2: any) => {
      return object1[compareWith] === object2[compareWith];
    });
  });
};
