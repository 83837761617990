export default interface Lead {
  id: string;
  name: string;
  companyName: string;
  number: number | string;
  email: string;
  seats: number | string;
  source: string;
  leadDate: string;
  visitDate: string;
  notes: string;
}

export const isLead = (obj: any, validate = false): obj is Lead => {
  const hasProperties =
    'name' in obj ||
    'companyName' in obj ||
    'number' in obj ||
    'email' in obj ||
    'seats' in obj ||
    'leadDate' in obj ||
    'visitDate' in obj ||
    'notes' in obj;

  if (validate && hasProperties) {
    if (
      obj.name.length === 0 ||
      obj.companyName.length === 0 ||
      obj.number.length === 0 ||
      obj.email.length === 0 ||
      obj.seats.length === 0 ||
      obj.leadDate.length === 0 ||
      obj.visitDate.length === 0 ||
      obj.notes.length === 0
    ) {
      return false;
    }
  }

  return hasProperties;
};
