import store from '@jitcoder/usestore';
import Client from '../models/client';

let clientOfficeMap: { [key: string]: any } = {};

const generateClientOfficeMap = () => {
  const clients = store.get<Client[]>('clients', []);

  clientOfficeMap = {};
  for (const client of clients) {
    for (const office of client.offices) {
      clientOfficeMap[office.value] = client;
    }
  }
};

store.addEventListener('clients', generateClientOfficeMap);
store.addEventListener('offices', generateClientOfficeMap);

export const getClientInOffice = (officeId: string) => {
  let client = clientOfficeMap[officeId];

  return client;
};
