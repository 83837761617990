import store from '@jitcoder/usestore';
import HTTP from './http';

import MeetingRoom, { isMeetingRoom } from '../models/meetingRoom';

export const getMeetingRooms = async () => {
  const leads = await HTTP.get<MeetingRoom[]>('/paxico/meetingRooms');
  store.set('meetingRooms', leads);
  return leads;
};

export const getMeetingRoom = async (id: string) => {
  return await HTTP.get<MeetingRoom>(`/paxico/meetingRooms/${id}`);
};

export const upsertMeetingRoom = async (meetingRoom: any) => {
  if (isMeetingRoom(meetingRoom)) {
    if ('id' in meetingRoom) {
      await HTTP.put<MeetingRoom>(
        `/paxico/meetingRooms/${meetingRoom.id}`,
        meetingRoom
      );
      await getMeetingRooms();
    } else {
      const insertedMeetingRoom = await HTTP.post<MeetingRoom>(
        '/paxico/meetingRooms',
        meetingRoom
      );
      const MeetingRooms = store.get<MeetingRoom[]>('meetingRooms', []);
      MeetingRooms.push(insertedMeetingRoom);
      store.set('meetingRooms', [...MeetingRooms]);
    }
    return;
  }

  throw Error('An error occured while upserting MeetingRoom');
};

export const deleteMeetingRoom = async (id: string) => {
  await HTTP.delete(`/paxico/meetingRooms/${id}`);
  await getMeetingRooms();
};
