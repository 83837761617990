import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { PageWrapper } from '../Page.styles';
import { Table } from '../../Components/Table';
import { getMissedCalls, upsertCallLog } from '../../api/callLogs';
import { TableButton } from '../../Components/Table/Table.styles';
import store from '@jitcoder/usestore';
export default function MeetingRooms() {
  const [modalType, setModalType] = useState('');
  const childRef: any = useRef();
  const [isActionPending, setActionPending] = useState(false);

  const columns = useMemo(
    () => [
      {
        id: 1,
        name: 'Number',
        selector: (row: any) => row.number,
        sortable: true,
        reorder: true,
        center: true,
      },
      {
        id: 2,
        name: 'Last Updated',
        selector: (row: any) => row.lastUpdated,
        right: false,
        sortable: true,
        center: true,
        cell: (row: any) => {
          let date = new Date(row.lastUpdated);
          return <span>{date.toDateString()}</span>;
        },
      },
      {
        id: 3,
        name: 'Status',
        selector: (row: any) => row.status,
        right: false,
        center: true,
      },
      {
        id: 4,
        right: false,
        center: true,
        cell: (row: any) => {
          return (
            <>
              {row.status !== 'REJECTED' && row.status !== 'ACCEPTED' && (
                <TableButton
                  heigth='30px'
                  onHoverBackgroundColor='#5c1515'
                  style={{
                    pointerEvents: isActionPending === true ? 'none' : 'auto',
                    background:
                      isActionPending === true ? 'lightgrey' : '#5c1515',
                  }}
                  onClick={async () => {
                    setActionPending(true);
                    let obj = {
                      number: row.number,
                      status: 'REJECTED',
                    };
                    await upsertCallLog(obj);
                    await getMissedCalls();
                    setActionPending(false);
                  }}
                >
                  Reject
                </TableButton>
              )}
            </>
          );
        },
      },
      {
        id: 5,
        right: false,
        center: true,
        cell: (row) => (
          <>
            {row.status !== 'ACCEPTED' && row.status !== 'REJECTED' && (
              <TableButton
                heigth='30px'
                onClick={() => {
                  childRef && childRef.current && childRef.current.handleShow();
                  setModalType('ADD');
                  store.set('dataFromCallLog', { data: row, type: 'Missed' });
                }}
              >
                Create Lead
              </TableButton>
            )}
          </>
        ),
      },
    ],
    [isActionPending]
  );

  const fetchAPI = useCallback(async () => {
    await getMissedCalls();
  }, []);

  useEffect(() => {
    fetchAPI();
  }, [fetchAPI]);

  return (
    <PageWrapper>
      <Table
        columns={columns}
        defaultSortFieldId={2}
        title={'Missed Calls'}
        onRowClick={false}
        modalType={modalType}
        buttonTitle={'ADD LEAD'}
        childRef={childRef}
        refreshIcon={true}
        hideButton={true}
      ></Table>
    </PageWrapper>
  );
}
