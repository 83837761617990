import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Table } from '../../Components/Table';
import { FaEdit, FaTrash } from 'react-icons/fa';
import { HiOutlineDocumentText } from 'react-icons/hi';
import { PageWrapper } from '../Page.styles';
import { getOffices } from '../../api/offices';
import { upsertClient } from '../../api/clients';
import ViewContract from '../Contracts/Contract';

import { getContracts, upsertContract } from '../../api/contracts';
import { ClientFormObj } from '../../consts/FormObjs';
import { toast } from 'react-toastify';
import Client from '../../models/client';
import store from '@jitcoder/usestore';
import Contract from '../../models/contract';
import { Office } from '../../interfaces';

export default function Contracts() {
  const childRef: any = useRef();
  const clients = store.get<Client[]>('clients', []);
  const storedOffices = store.get<Office[]>('offices', []);

  const storedContracts = store.get<Contract[]>('contracts', []);

  const [rowId, setRowId] = useState('');
  const [contractData, setContractData] = useState({});
  const [contracts, setContracts] = useState<any>([]);

  const [renderStop, setRenderStop] = useState(false);
  const [viewContract, setViewContract] = useState(false);

  const [isSigned, setIsSigned] = useState('No');
  const [modalType, setModalType] = useState('');

  const columns = useMemo(
    () => [
      {
        id: '0',
        name: 'Contract ID',
        selector: (row: any) => row.contractId,
        sortable: true,
        reorder: true,
      },
      // {
      //   id: 1,
      //   name: 'Contractor Name',
      //   selector: (row: any) => row.contractorName,
      //   sortable: true,
      //   reorder: true,
      // },

      // {
      //   id: 2,
      //   name: 'Start Date',
      //   selector: (row: any) => row.startDate,
      //   sortable: true,
      //   reorder: true,
      // },
      // {
      //   id: 3,
      //   name: 'End Date',
      //   selector: (row: any) => row.endDate,
      //   sortable: true,
      //   reorder: true,
      // },
      {
        id: 4,
        name: 'Duration',
        sortable: true,
        selector: (row: any) => row.duration + ' Months',

        reorder: true,
        center: true,
      },
      {
        id: 5,
        name: 'Is Signed?',
        sortable: true,
        reorder: true,
        center: true,
        cell: (row: any) => (
          <>
            <input
              type='checkBox'
              disabled={isSigned === 'Yes' || row.isSigned === 'Yes'}
              checked={row.isSigned === 'Yes' ? true : false}
              onChange={async () => {
                if (!renderStop) {
                  try {
                    let dummyContract: any = { ...row };
                    let dummyClients: any = [...clients];
                    let filteredClient = dummyClients.filter(
                      (client: any) =>
                        client.name === dummyContract.contractorName &&
                        client.email === dummyContract.email
                    );

                    let obj: any = { ...ClientFormObj };

                    if (filteredClient.length === 0) {
                      let contractObj: any = {
                        label:
                          row.contractorName + '-' + (obj.contract.length + 1),
                        value: row.id,
                      };
                      let arr: any = [];
                      arr.push({ ...contractObj });
                      obj.contract = arr;
                    } else {
                      let contractObj: any = {
                        label:
                          row.contractorName +
                          '-' +
                          (filteredClient[0].contract.length + 1),
                        value: row.id,
                      };
                      let arr: any = [...filteredClient[0].contract];
                      arr.push({ ...contractObj });
                      obj.contract = arr;
                      obj.invoices = [...filteredClient[0].invoices];
                      obj.scheduledMeetingRoom = {
                        ...filteredClient[0].scheduledMeetingRoom,
                      };
                    }

                    obj.name = row.contractorName;
                    obj.companyName = row.companyName;
                    obj.type = row.type;
                    obj.freeHours = row.freeHours;
                    obj.email = row.email;

                    obj.number = row.number;

                    obj.offices = row.offices;

                    obj.privatePrice = row.privatePrice;
                    obj.advancePayment = row.advancePayment;
                    obj.clientBalance = row.advancePayment;
                    // obj.firstPaymentLessThan1Month =
                    //   row.firstPaymentLessThan1Month;

                    obj.sharedPrice = row.sharedPrice;
                    obj.virtualPrice = row.virtualPrice;

                    for (const office of row.offices) {
                      obj[`${office.label}_sharedSeats`] =
                        row[`${office.label}_sharedSeats`];
                    }
                    dummyContract.isSigned = 'Yes';
                    obj.security = row.security;
                    obj.totalSeatsForSecurity = row.totalSeatsForSecurity;
                    setIsSigned('Yes');

                    if (dummyContract.hasOwnProperty('_id')) {
                      delete dummyContract._id;
                    }

                    let clientt: any = { ...filteredClient[0] };
                    if (filteredClient.length > 0) {
                      Object.entries(obj) // Array(2) [["a", "something"], ["b", 42]]
                        .forEach(([key, value]) => {
                          clientt[key] = value;
                        });
                      let sharedSeats: any = 0;
                      let privateSeats: any = 0;
                      let virtualSeats: any = 0;

                      dummyContract &&
                        dummyContract.offices.forEach((office: any) => {
                          if (dummyContract[`${office.label}_sharedSeats`]) {
                            sharedSeats =
                              sharedSeats +
                              Number(
                                dummyContract[`${office.label}_sharedSeats`]
                              );
                          } else {
                            let filteredOffice = storedOffices.filter(
                              (off: any) => off.id === office.value
                            );
                            if (filteredOffice[0].name === '')
                              privateSeats =
                                Number(filteredOffice[0].seats) + privateSeats;
                            else
                              virtualSeats =
                                Number(filteredOffice[0].seats) + virtualSeats;
                          }
                        });

                      clientt.privateSeats = privateSeats;
                      clientt.sharedSeats = sharedSeats;
                      clientt.virtualSeats = virtualSeats;

                      Promise.allSettled([
                        await upsertClient(clientt),
                        await upsertContract(dummyContract),
                      ]);

                      toast.success('Client Contract has been Renewed');
                    } else {
                      Promise.allSettled([
                        await upsertClient(obj),
                        await upsertContract(dummyContract),
                      ]);

                      toast.success('Client has Created Successfully');
                    }
                  } catch (e) {
                    toast.error('an Error accured during Creating Client');
                    console.log('error', e);
                  }
                  setRenderStop(true);
                }
              }}
            ></input>
          </>
        ),
      },
      {
        id: 6,
        name: 'Status',
        right: false,
        center: true,
        cell: (row: any) => {
          let dueDate = row.endDate;
          let currentData = new Date().toISOString().substring(0, 10);
          currentData = String(new Date(currentData).getTime());
          dueDate = String(new Date(dueDate).getTime());
          let status = dueDate <= currentData;

          return (
            <>
              <span
                style={{
                  color:
                    row.isSigned === 'No'
                      ? '#8e8e8d'
                      : status
                      ? 'red'
                      : 'green',
                  fontWeight: '500',
                }}
              >
                {row.isSigned === 'No'
                  ? 'Not Signed'
                  : status
                  ? 'Expired'
                  : 'Active'}
              </span>
            </>
          );
        },
      },

      {
        id: 7,
        name: 'View Contract',

        right: false,
        center: true,
        cell: (row: any) => (
          <>
            <HiOutlineDocumentText
              style={{ marginRight: '5px', fontSize: '1.2rem' }}
              onClick={() => {
                setViewContract(true);
                setContractData(row);
                let nav = document.getElementById('navbar');
                if (nav) nav.style.display = 'none';
              }}
            ></HiOutlineDocumentText>
          </>
        ),
      },
      {
        id: 10,
        name: 'Renew',

        right: false,
        center: true,
        cell: (row: any) => {
          let filtered: any;

          if (storedContracts.length === 0)
            filtered =
              contracts &&
              contracts.filter(
                (contract: any) =>
                  contract.contractorName === row.contractorName
              );
          else
            filtered =
              storedContracts &&
              storedContracts.filter(
                (contract: any) =>
                  contract.contractorName === row.contractorName
              );
          let index =
            filtered && filtered.findIndex((item: any) => item.id === row.id);

          let showIt = false;
          if (row.isSigned === 'Yes') {
            if (
              (filtered && filtered.length === 1 && index === 0) ||
              (filtered && filtered.length > 1 && index === filtered.length - 1)
            ) {
              showIt = true;
            }
          } else {
          }
          return (
            <>
              {showIt && (
                <span
                  style={{ cursor: 'pointer', color: 'blue' }}
                  onClick={() => {
                    childRef &&
                      childRef.current &&
                      childRef.current.handleShow();
                    setModalType('RENEW CONTRACT');
                    setRowId(row.id);
                  }}
                >
                  Renew Contract
                </span>
              )}
            </>
          );
        },
      },
      {
        id: 8,
        right: false,
        center: true,
        cell: (row: any) => {
          let filtered: any;

          if (storedContracts.length === 0)
            filtered =
              contracts &&
              contracts.filter(
                (contract: any) =>
                  contract.contractorName === row.contractorName
              );
          else
            filtered =
              storedContracts &&
              storedContracts.filter(
                (contract: any) =>
                  contract.contractorName === row.contractorName
              );
          let index =
            filtered && filtered.findIndex((item: any) => item.id === row.id);
          let showIt = false;
          if (row.isSigned === 'Yes') {
            if (
              (filtered && filtered.length === 1 && index === 0) ||
              (filtered && filtered.length > 1 && index === filtered.length - 1)
            ) {
              showIt = true;
            }
          } else {
            showIt = true;
          }
          return (
            <>
              {showIt && (
                <FaEdit
                  style={{ marginRight: '5px', fontSize: '1rem' }}
                  onClick={() => {
                    childRef &&
                      childRef.current &&
                      childRef.current.handleShow();
                    setModalType('EDIT CONTRACT');
                    setRowId(row.id);
                  }}
                ></FaEdit>
              )}
            </>
          );
        },
      },

      {
        id: 9,
        right: false,
        center: true,
        cell: (row: any) => (
          <>
            <FaTrash
              style={{ marginRight: '5px', fontSize: '1rem' }}
              onClick={() => {
                childRef && childRef.current && childRef.current.handleShow();
                setModalType('Delete');
                setRowId(row.id);
                // store.set('active-modal', true);
              }}
            ></FaTrash>
          </>
        ),
      },
    ],
    [clients, contracts, isSigned, renderStop, storedContracts, storedOffices]
  );

  const fetchAPI = useCallback(async () => {
    await getOffices();
    let cont = await getContracts();
    setContracts(cont);
  }, []);

  useEffect(() => {
    fetchAPI();
  }, [fetchAPI]);

  return (
    <>
      {viewContract ? (
        <ViewContract contractData={contractData} />
      ) : (
        <PageWrapper>
          <Table
            columns={columns}
            defaultSortFieldId={1}
            title={'Contracts'}
            rowId={rowId}
            modalType={modalType}
            childRef={childRef}
            buttonTitle={'ADD CONTRACT'}
          ></Table>
        </PageWrapper>
      )}
    </>
  );
}
