import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { PageWrapper } from '../Page.styles';
import { Table } from '../../Components/Table';
import { FaEdit, FaTrash } from 'react-icons/fa';
import { AiFillSchedule } from 'react-icons/ai';
import { getMeetingRooms } from '../../api/meetingRooms';

export default function MeetingRooms() {
  const [data, setData] = useState([]);
  const [rowId, setRowId] = useState('');
  const [modalType, setModalType] = useState('');
  const childRef: any = useRef();

  const columns = useMemo(
    () => [
      {
        id: 1,
        name: 'Name',
        selector: (row: any) => row.name,
        sortable: true,
        reorder: true,
        center: true,
      },
      {
        id: 2,
        name: 'Schedule',
        right: false,
        center: true,
        cell: (row: any) => (
          <>
            <AiFillSchedule
              style={{ marginRight: '5px' }}
              onClick={() => {
                childRef && childRef.current && childRef.current.handleShow();

                setModalType('SCHEDULE ROOM');
                setRowId(row.id);
              }}
            ></AiFillSchedule>
          </>
        ),
      },
      {
        id: 3,
        right: false,
        center: true,
        cell: (row: any) => (
          <>
            <FaEdit
              style={{ marginRight: '5px', fontSize: '1rem' }}
              onClick={() => {
                childRef && childRef.current && childRef.current.handleShow();

                setModalType('EDIT MEETINGROOM');
                setRowId(row.id);
                // store.set('active-modal', true);
              }}
            ></FaEdit>
          </>
        ),
      },
      {
        id: 4,
        right: false,
        center: true,
        cell: (row: any) => (
          <>
            <FaTrash
              style={{ marginRight: '5px', fontSize: '1rem' }}
              onClick={() => {
                childRef && childRef.current && childRef.current.handleShow();

                setModalType('Delete');
                setRowId(row.id);
                // store.set('active-modal', true);
              }}
            ></FaTrash>
          </>
        ),
      },
    ],
    []
  );

  const fetchAPI = useCallback(async () => {
    let meetingRooms: any = await getMeetingRooms();

    setData(meetingRooms);
  }, []);

  useEffect(() => {
    fetchAPI();
  }, [fetchAPI]);

  return (
    <PageWrapper>
      <Table
        columns={columns}
        dataa={data}
        title={'Meeting Rooms'}
        rowId={rowId}
        modalType={modalType}
        childRef={childRef}
        buttonTitle={'ADD MEETING ROOM'}
      ></Table>
    </PageWrapper>
  );
}
