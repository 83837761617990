import { useEffect, useState } from 'react';
import { FormWrapper, InputWrapper } from './Forms.styles';
import { officeFormItems } from '../../consts/FormFields';
import { IOfficeForm } from '../../interfaces';
import Select from 'react-select';
// import store from '@jitcoder/usestore';
// import Client from '../../models/client';
// import { getClientInOffice } from '../../lib/getClientInOffice';
import CustomRequired from '../CustomRequired/CustomRequired';

export default function OfficeForm({
  modalType,
  handleChange,
  officeData,
  handleOptionChange,
  officeAlreadyAssigned,

  rowId,
}: any) {
  // const clients = store.get<Client[]>('clients', []);
  // const [option, setOption] = useState([]);
  // const [selectedOptionsClient, setSelectedOptionsClient] = useState();
  const [selectedOptionsType, setSelectedOptionsType] = useState();

  // const [renderStop, setRenderStop] = useState(false);

  // const handleMultiChangeClient = (opt: any) => {
  //   setSelectedOptionsClient(opt);
  //   handleOptionChange(opt);
  // };

  const handleMultiChangeType = (opt: any) => {
    setSelectedOptionsType(opt);
    handleOptionChange(opt, 0, 'type');
  };

  // const updateOption: any = useCallback(async () => {
  //   if (!renderStop) {
  //     let opt: any = {};
  //     let options: any = [];

  //     for (let i = 0; i < clients.length; i++) {
  //       opt['label'] = clients[i].name;
  //       opt['value'] = clients[i].id;
  //       options.push({ ...opt });
  //     }
  //     setOption(options);
  //     setRenderStop(true);
  //   }
  // }, [clients, renderStop]);

  // useEffect(() => {
  //   updateOption();
  // }, [updateOption]);

  useEffect(() => {
    // let client = getClientInOffice(rowId);
    // let obj: any = { label: client && client.name, value: client && client.id };
    // // setSelectedOptionsClient(obj);
    setSelectedOptionsType(officeData.type);
  }, [officeData.type, rowId]);

  return (
    <FormWrapper
      style={{
        pointerEvents: modalType === 'SHOW OFFICE' ? 'none' : 'auto',
      }}
    >
      {officeFormItems.map((item) => (
        <InputWrapper key={item.id}>
          <label className={item.isRequired ? 'should-required' : ''}>
            {item.label}
          </label>
          <input
            type={item.type}
            id={item.id}
            onChange={(e) => handleChange(e, 'Office')}
            value={officeData && officeData[item.id as keyof IOfficeForm]}
            disabled={modalType === 'SHOW OFFICE'}
            required={item.isRequired}
            min={item.min}
          ></input>
          {item.label === 'Seats' && (
            <>
              <InputWrapper key='type'>
                <label className='should-required'>Type</label>
                <CustomRequired
                  requiredCondition={true}
                  value={selectedOptionsType}
                >
                  <Select
                    name='colors'
                    placeholder='Select type of Office'
                    options={[
                      { label: 'Private', value: 'Private' },
                      { label: 'Shared', value: 'Shared' },
                      { label: 'Virtual', value: 'Virtual' },
                    ]}
                    onChange={handleMultiChangeType}
                    value={selectedOptionsType}
                    className='basic-multi-select'
                    classNamePrefix='select'
                    isDisabled={modalType === 'SHOW OFFICE'}
                  />
                </CustomRequired>
              </InputWrapper>
              {/* <InputWrapper key='client'>
                <label>Client</label>
                <Select
                  name='colors'
                  placeholder='Select Client'
                  options={option}
                  onChange={handleMultiChangeClient}
                  value={selectedOptionsClient}
                  className='basic-multi-select'
                  classNamePrefix='select'
                  isDisabled={modalType === 'SHOW OFFICE'}
                />
              </InputWrapper> */}
            </>
          )}
        </InputWrapper>
      ))}
      <div style={{ textAlign: 'center' }}>
        <span style={{ color: 'red' }}>{officeAlreadyAssigned}</span>
      </div>
    </FormWrapper>
  );
}
