import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { TiWarningOutline } from 'react-icons/ti';
import { toastMSG } from '../../lib/toastMSG';
import { TableButton } from '../Table/Table.styles';
import { toast } from 'react-toastify';
import { getHourBtwTwoTimes } from '../../lib/getHourBwtTwoTimes';
import { upsertClient } from '../../api/clients';

export default function DeleteModal({
  rowId,
  title,
  show,
  handleClose,
  optionalData,
  handleCloseOuterModal,
  afterDeleteCallback,
}: any) {
  const [isActionPending, setActionPending] = useState(false);
  const [showModal, setModalShow] = useState(false);

  //   const handleShow = () => {
  //     setShow(true);
  //   };
  //   const handleClose = () => {
  //     setShow(false);
  //   };
  useEffect(() => {
    setModalShow(show);
  }, [show]);

  const handleDelete = async () => {
    try {
      setActionPending(true);
      if (title === 'DELETE SCHEDULE ROOM') {
        let fulteredClient = optionalData.storedClients.filter(
          (client: any) => client.id === optionalData.item.client.value
        );
        let index1 =
          fulteredClient[0].scheduledMeetingRoom.scheduledClients.findIndex(
            (client: any) =>
              client.endTime === optionalData.item.endTime &&
              client.startDate === optionalData.item.startDate &&
              client.startTime === optionalData.item.startTime
          );
        fulteredClient[0].scheduledMeetingRoom.scheduledClients.splice(
          index1,
          1
        );
        let hour = getHourBtwTwoTimes(
          optionalData.item.startDate,
          optionalData.item.startDate,
          optionalData.item.startTime,
          optionalData.item.endTime
        );
        let consumedHours = { ...fulteredClient[0].consumedHours };

        if (consumedHours.hasOwnProperty(optionalData.item.startDate)) {
          consumedHours[optionalData.item.startDate] =
            consumedHours[optionalData.item.startDate] - hour;
        }

        fulteredClient[0].consumedHours = consumedHours;

        afterDeleteCallback();
        await upsertClient(fulteredClient[0]);

        setModalShow(false);
        toastMSG('delete', 'Scheduled Client');
      } else if (title === 'DELETE INVOICE') {
        let dummyData = { ...optionalData };
        dummyData.invoices.splice(rowId, 1);
        await upsertClient(dummyData);

        setModalShow(false);
        handleCloseOuterModal();
        toastMSG('delete', 'Invoice');
      }
    } catch (e) {
      toast.error('an Error occured while Deleting ');
      console.error(e);
    }
  };
  return (
    <Modal show={showModal} onHide={handleClose}>
      <Modal.Body>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <TiWarningOutline style={{ fontSize: '7rem', color: '#f7c220' }} />
          <label style={{ textAlign: 'center' }}>
            {`Are you Sure? You want to ${title}.`}
          </label>
        </div>
      </Modal.Body>
      <Modal.Footer style={{ display: 'flex', justifyContent: 'center' }}>
        <Button
          variant='secondary'
          onClick={handleClose}
          style={{
            height: '38px',
            borderRadius: '12px',
            fontSize: '0.8rem',
            width: '98px',
          }}
        >
          Close
        </Button>

        <TableButton
          onClick={() => handleDelete()}
          style={{
            pointerEvents: isActionPending ? 'none' : 'auto',
            background: isActionPending ? 'lightgrey' : 'cadetblue',
          }}
        >
          Delete
        </TableButton>
      </Modal.Footer>
    </Modal>
  );
}
