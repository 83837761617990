export const navItems = [
  {
    id: '0',
    title: 'Leads',
    to: '/',
    icon: 'FaRoad',
  },
  {
    id: '1',
    title: 'Clients',
    to: '/clients',
    icon: 'FaDoorOpen',
  },
  {
    id: '5',
    title: 'Contracts',
    to: '/contracts',
    icon: 'FaUserFriends',
  },
  {
    id: '2',
    title: 'Offices',
    to: '/offices',
    icon: 'HiUserGroup',
  },
  {
    id: '3',
    title: 'Meeting Rooms',
    to: '/meetingRooms',
    icon: 'FaFileContract',
  },
  {
    id: '4',
    title: 'Call Logs',
    to: '/missedcall',
    icon: 'FaRoad',
    subMenu: [
      {
        id: '4.1',
        title: 'Missed Call',
        to: '/missedcalls',
        icon: 'FaRoad',
      },
      {
        id: '4.2',
        title: 'Answered Calls',
        to: '/answeredcalls',
        icon: 'FaRoad',
      },
    ],
  },
];
