import { findOfficeById } from './findOfficeById';
// const storedClients = store.get<Client[]>('clients', []);

export const getTotalSeatsOfClientByType = (
  storedClients: any,
  storedOffices: any
) => {
  let totalSeats: any = {};
  storedClients.forEach((client: any, i: any) => {
    totalSeats[client.name] = 0;
    client.offices.forEach((office: any, j: any) => {
      let getOffice = findOfficeById(office.value, storedOffices);

      if (client.type.length > 1) {
        client.type.forEach((ty: any) => {
          if (getOffice[0].type.label === 'Private') {
            let dumObj = {
              private_Seats: Number(getOffice[0].seats),
            };
            totalSeats[client.name] = { ...totalSeats[client.name], ...dumObj };
          } else if (getOffice[0].type.label === 'Virtual') {
            let dumObj = {
              virtual_Seats: Number(getOffice[0].seats),
            };
            totalSeats[client.name] = { ...totalSeats[client.name], ...dumObj };
          } else {
            let dumObj = {
              shared_Seats: Number(client[`${office.label}_sharedSeats`]),
            };
            totalSeats[client.name] = { ...totalSeats[client.name], ...dumObj };
          }
        });
      } else if (getOffice[0].type.label === 'Private') {
        totalSeats[client.name] = {
          private_Seats: Number(getOffice[0].seats),
        };
      } else if (getOffice[0].type.label === 'Shared') {
        if (typeof totalSeats[client.name] === 'object') {
          totalSeats[client.name] = {
            shared_Seats:
              totalSeats[client.name]['shared_Seats'] +
              Number(client[`${office.label}_sharedSeats`]),
          };
        } else {
          totalSeats[client.name] = {
            shared_Seats:
              totalSeats[client.name] +
              Number(client[`${office.label}_sharedSeats`]),
          };
        }
      } else if (getOffice[0].type.label === 'Virtual') {
        totalSeats[client.name] = {
          virtual_Seats: Number(getOffice[0].seats),
        };
      }
    });
  });

  return totalSeats;
};
