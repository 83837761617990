import { FormWrapper, InputWrapper } from './Forms.styles';
import { meetingRoomFormItems } from '../../consts/FormFields';

export default function MeetingRooms({ handleChange, meetingRoomData }: any) {
  return (
    <FormWrapper>
      {meetingRoomFormItems.map((item) => (
        <InputWrapper key={item.id}>
          <label className={item.isRequired ? 'should-required' : ''}>
            {item.label}
          </label>
          <input
            type={item.type}
            id={item.id}
            onChange={(e) => handleChange(e, 'MeetingRoom')}
            value={meetingRoomData && meetingRoomData[item.id]}
            required={item.isRequired}
          ></input>
        </InputWrapper>
      ))}
    </FormWrapper>
  );
}
