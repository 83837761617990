import { useState, useMemo, useEffect, useCallback, useRef } from 'react';
import { Table } from '../../Components/Table';
import { PageWrapper } from '../Page.styles';
import store from '@jitcoder/usestore';
import { TableButton } from '../../Components/Table/Table.styles';
import { getCalled, upsertCallLog } from '../../api/callLogs';

export default function Leads() {
  const childRef: any = useRef();
  const [modalType, setModalType] = useState('');
  const [isActionPending, setActionPending] = useState(false);

  const columns = useMemo(
    () => [
      {
        id: 1,
        name: 'Number',
        selector: (row: any) => row.number,
        sortable: true,
        reorder: true,
        center: true,
      },

      {
        id: 2,
        name: 'Status',
        selector: (row: any) => row.status,
        right: false,
        center: true,
      },
      {
        id: 3,
        name: 'Last Updated',
        selector: (row: any) => row.lastUpdated,
        right: false,
        sortable: true,
        center: true,
        cell: (row: any) => {
          if (row.hasOwnProperty('lastUpdated')) {
            let date = new Date(row.lastUpdated);
            return <span>{date.toDateString()}</span>;
          }
        },
      },
      {
        id: 4,
        right: false,
        center: true,
        cell: (row: any) => {
          return (
            <>
              {row.status !== 'REJECTED' && row.status !== 'ACCEPTED' && (
                <TableButton
                  heigth='30px'
                  style={{
                    pointerEvents: isActionPending === true ? 'none' : 'auto',
                    background:
                      isActionPending === true ? 'lightgrey' : '#5c1515',
                  }}
                  onHoverBackgroundColor='#5c1515'
                  onClick={async () => {
                    setActionPending(true);

                    let obj = {
                      number: row.number,
                      status: 'REJECTED',
                    };
                    await upsertCallLog(obj);
                    await getCalled();
                    setActionPending(false);
                  }}
                >
                  Reject
                </TableButton>
              )}
            </>
          );
        },
      },
      {
        id: 5,
        right: false,
        center: true,
        cell: (row) => (
          <>
            {row.status !== 'ACCEPTED' && row.status !== 'REJECTED' && (
              <TableButton
                heigth='30px'
                onClick={() => {
                  childRef && childRef.current && childRef.current.handleShow();
                  setModalType('ADD');
                  store.set('dataFromCallLog', { data: row, type: 'Called' });
                }}
              >
                Create Lead
              </TableButton>
            )}
          </>
        ),
      },
    ],
    [isActionPending]
  );
  const fetchAPI = useCallback(async () => {
    await getCalled();
  }, []);

  useEffect(() => {
    fetchAPI();
  }, [fetchAPI]);

  return (
    <PageWrapper>
      <Table
        columns={columns}
        defaultSortFieldId={3}
        title={'Answered Calls'}
        modalType={modalType}
        refreshIcon={true}
        hideButton={true}
        onRowClick={false}
        buttonTitle={'ADD LEAD'}
        // runCallBack={fetchAPI}
        childRef={childRef}
      ></Table>
    </PageWrapper>
  );
}
