import React, { useEffect, useRef, useState } from 'react';
import { capitalizeFirstLetter } from '../../lib/capitalizeFirstLetter';
import Select from 'react-select';
import { numberWithCommas } from '../../lib/numberWithCommas';

function Contract({ contractData }: any) {
  const componentRef: any = useRef();
  const [Data, setData] = useState<any>({ ...contractData });
  const [selectedOptionsAddendum] = useState<any>();
  const [optionsAddendum, setOptionsAddendum] = useState([]);
  const [renderStopp, setRenderStopp] = useState(false);

  let of: any = '';
  let sharedSeats: any = 0;
  let totalAmount: any = 0;
  let packageName: any = '';
  // const generateContract: any = useRef();
  const handleMultiChangeAddendum = async (option: any) => {
    if (option.value === 'Orignal Contract') {
      setData(contractData);
    } else {
      let filtered = contractData.addendumContract.filter(
        (item: any) => item.id === option.value
      );

      setData(filtered[0]);
    }
  };

  useEffect(() => {
    if (contractData.addendumContract.length > 0) {
      setData(
        contractData.addendumContract[contractData.addendumContract.length - 1]
      );
    } else setData({ ...contractData });
  }, [contractData]);

  useEffect(() => {
    if (
      contractData &&
      contractData.addendumContract &&
      contractData.addendumContract.length > 0
    ) {
      let opt: any = {};
      let options: any = [];
      if (!renderStopp) {
        for (let i = 0; i < contractData.addendumContract.length; i++) {
          opt['label'] = capitalizeFirstLetter(
            contractData.addendumContract[i].id
          );
          opt['value'] = contractData.addendumContract[i].id;
          options.push({ ...opt });
          if (i === contractData.addendumContract.length - 1) {
            opt['label'] = 'Orignal Contract';
            opt['value'] = 'Orignal Contract';
            options.unshift({ ...opt });
          }
        }

        setOptionsAddendum(options);
        setRenderStopp(true);
      }
    }
  }, [contractData, contractData.addendumContract, renderStopp]);

  return (
    <>
      <div className='A4' ref={componentRef}>
        <div className='page' style={{ marginTop: '20px', fontFamily: 'auto' }}>
          <img
            src='./paxicoLogo.png'
            alt=''
            style={{ width: '122px', height: '80px' }}
          ></img>
          {contractData.addendumContract.length > 0 && (
            <div
              style={{ position: 'absolute', left: '75%', width: '250xp' }}
              className='no-print'
            >
              <Select
                name='colors'
                placeholder='Check Addendums'
                options={optionsAddendum}
                onChange={handleMultiChangeAddendum}
                value={selectedOptionsAddendum}
                className='basic-multi-select'
                // noOptionsMessage={() => 'No Type available'}
                classNamePrefix='select'
              />
            </div>
          )}
          <div
            className='header'
            style={{ marginTop: '100px', fontSize: '14px' }}
          >
            SERVICES AGREEMENT
          </div>
          <div className='header' style={{ fontSize: '14px' }}>
            &
          </div>
          <div className='header' style={{ fontSize: '14px' }}>
            TERMS OF USE
          </div>
          <div className='terms_paragraph'>
            <div style={{ display: 'flex', marginTop: '30px' }}>
              <span
                style={{ width: '20%', fontWeight: '600', textAlign: 'left' }}
              >
                BETWEEN
              </span>
              <span style={{ width: '80%', textAlign: 'initial' }}>
                <span style={{ fontWeight: '600' }}>
                  PAXICO (SMC-PRIVATE) LIMITED.
                </span>
                , hereinafter referred to as{' '}
                <span style={{ fontWeight: '600' }}>(“Paxico”) </span>a
                corporation organized and existing under the laws of Islamic
                Republic of Pakistan with its registered office located at;{' '}
                <br />
                <br />
                <span style={{ fontWeight: '600' }}>Address :</span> Plot
                No.398, Service Road, I-9/3 Industrial Area
                <br />
                <span style={{ fontWeight: '600' }}>Tel. No :</span>{' '}
                +923030888761
                <br />
                <span style={{ fontWeight: '600' }}>E-mail :</span>{' '}
                PaxicoCoworking@gmail.com
              </span>
            </div>
            <div style={{ display: 'flex', marginTop: '30px' }}>
              <span
                style={{ width: '20%', fontWeight: '600', textAlign: 'left' }}
              >
                AND
              </span>
              <span style={{ width: '80%', textAlign: 'initial' }}>
                <span style={{ textDecorationLine: 'underline' }}>
                  {contractData.contractorName}
                </span>
                ., (the “Client”) a corporation or individuals organized and
                existing under the laws of Islamic Republic of Pakistan.
              </span>
            </div>
            <div
              style={{
                display: 'flex',
                paddingLeft: '37px',
                paddingRight: '37px',
                flexDirection: 'column',
              }}
            >
              <span style={{ marginTop: '50px', textAlign: 'left' }}>
                I, (hereinafter “I,” “Me,” or “Member”), agree that the Services
                provided to Me by PAXICO including but not limited to Internet
                access and use of the office space (“The Space”) at Plot No.398,
                Service Road East I-9/3, Islamabad—are subject to the following
                Terms of Use.
              </span>
              <div>
                {/*  */}

                <div className='termHeadingWrapper  '>
                  <span className='termList  '>1. </span>ACCEPTANCE OF TERMS
                </div>
                <div className='terms_paragraph '>
                  <br></br>
                  The services PAXICO provides to you, the undersigned
                  (including but not limited to use of office space and access
                  to Internet), are subject to the following Terms of Use
                  (“TOU”).
                </div>
                <div
                  className='terms_paragraph  '
                  style={{ paddingTop: '0px' }}
                >
                  <br></br>
                  PAXICO reserves the right to update the TOU at any time.
                  PAXICO will notify you of any updates within 30 days of their
                  enactment using the contact information provided in the TOU
                  after which it will be presumed that the updated TOU have been
                  accepted.
                </div>
                <div className='termHeadingWrapper'>
                  <span className='termList'>2. </span>DESCRIPTION OF SERVICES
                </div>
                <br></br>
                <div style={{ textAlign: 'left' }}>
                  <span className='termList'>2.1</span>{' '}
                  <span style={{ fontWeight: '600' }}>‘Base Services’</span>{' '}
                  PAXICO may provide you access to office space, workstations,
                  and amenities like Internet access. ‘Additional Services’ that
                  may be provided by PAXICO, subject to availability, the
                  determination of which is solely to be made by PAXICO, include
                  conference space, knowledge resources, and any other services
                  as PAXICO may provide from time to time. The additional
                  services may be subject to additional fees as may be
                  determined by PAXICO.
                </div>
              </div>
              {/*  */}
              <br></br>
              <br></br>
              <br></br>

              <div className='terms_paragraph'>
                <div className='terms_paragraph'>
                  <br></br>
                  <span className='termList'>2.2</span>{' '}
                  <span style={{ fontWeight: '600' }}>
                    Inspection and Maintenance
                  </span>{' '}
                  : PAXICO may provide you access to office space, workstations,
                  and amenities like Internet access. ‘Additional Services’ that
                  may be provided by PAXICO, subject to availability, the
                  determination of which is solely to be made by PAXICO, include
                  conference space, knowledge resources, and any other services
                  as PAXICO may provide from time to time. The additional
                  services may be subject to additional fees as may be
                  determined by PAXICO.
                </div>
                <div className='terms_paragraph'>
                  <span className='termList'>2.3</span> Interruption, Delay or
                  Non- availability of Services: In certain circumstances the
                  Services and amenities mentioned in this Services Agreement
                  and TOU might be unavailable. However, PAXICO shall not be
                  liable for any damages, losses or anticipated losses or
                  damages. PAXICO will try its best to provide alternative
                  solutions.
                </div>
                <div className='termHeadingWrapper '>
                  <span className='termList '>3. </span>LIMITED RIGHTS TO USE OF
                  SPACE
                </div>
                <div className='terms_paragraph'>
                  <br></br>
                  <span className='termList'>3.1</span> This business center
                  agreement is a mere permission for the Client to use the space
                  and other facilities of the PAXICO. The whole of the building
                  and facilities remains in PAXICO possession and control. No
                  tenancy or other, right title or interest and or possession
                  whatsoever is created or intended to be created by this
                  agreement in favour of the Client. Paxico is giving the Client
                  the right to share with Paxico the use of the space on these
                  terms and conditions, so that Paxico can provide services to
                  the Client. The Client agrees not to assign or sub-let or part
                  with possession of the space or attempt transfer of this
                  Agreement.
                </div>
                {/*  */}
                <div className='terms_paragraph'>
                  <br></br>
                  <span className='termList'>3.2</span> I understand that
                  membership is a privilege that may be revoked at PAXICO’s
                  discretion. If my membership is revoked, I understand that
                  PAXICO will return my membership dues for the remaining period
                  of membership, and I agree to forfeit my membership, keys, and
                  any other evidence of membership.
                </div>
                <div className='terms_paragraph'>
                  <br></br>
                  <span className='termList'>3.3</span> I understand that all
                  amenities provided by PAXICO, including but not limited to
                  opening hours, availability of a particular working area, use
                  of the printers and scanners etc., or availability of the
                  Internet are to be deemed as additional privileges of
                  membership not included in the cost of membership and subject
                  to change.
                </div>
                <div className='terms_paragraph'>
                  <br></br>
                  <span className='termList'>3.4</span> I understand that my use
                  of The Space may on occasion be disrupted. This may include,
                  but is not limited to: (i) the offering of events or Services
                  on the premises that are not included in the standard
                  membership and that may require additional fees to attend or
                  use, and (ii) the need to move to another work area and under
                  rare circumstances to vacate the premises entirely.
                </div>
                <div className='terms_paragraph'>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <span className='termList'>3.5</span> I understand that PAXICO
                  will do its best to announce to the member community in
                  advance of any known changes or disruptions to member access
                  to the Space or to the use of Services provided by PAXICO.
                </div>
                {/*  */}
                <div className='terms_paragraph'>
                  <br></br>
                  <span className='termList'>3.6</span> I understand that my
                  Membership of PAXICO is non-transferable and in case of team
                  packages, names of members shall be provided to PAXICO and no
                  change shall be made without the explicit consent of PAXICO
                  management.
                </div>
                <div className='terms_paragraph'>
                  <br></br>
                  <span className='termList'>3.7</span>I understand that I as a
                  Client am fully responsible for my employees & visitors.
                </div>
                <div className='terms_paragraph'>
                  <br></br>
                  <span className='termList'>3.8</span>Installations in the
                  Premises: The Client must not install any cabling, IT or
                  telecom connections without PAXICO’s prior written consent. As
                  a condition to such consent, the Client must permit PAXICO to
                  oversee any installations and to verify that such
                  installations do not interfere with the use of premises by
                  other Clients or PAXICO or any landlord of the building.
                </div>
                <div className='terms_paragraph'>
                  <br></br>
                  <span className='termList'>3.9</span>Alternations to the
                  Premises: The Client shall not make any alterations or
                  additions in the Premises without prior written consent of
                  PAXICO .
                </div>
                <br></br>
                <div className='terms_paragraph'>
                  <br></br>
                  <span className='termList'>3.10</span>Non-Compete Business:
                  The Client must not carry on a business that directly or
                  indirectly competes with PAXICO or any company affiliated with
                  PAXICO.
                </div>
                <div className='termHeadingWrapper '>
                  <span className='termList '>4. </span>ACCEPTABLE BEHAVIOR
                </div>
                <div className='terms_paragraph'>
                  <span className='termList'>4.1</span>PAXICO offers a shared
                  workspace and it is expected that all members shall deal with
                  the management of PAXICO and other members in a reasonable and
                  respectful manner. Members are expected to act in good faith,
                  have ethical business practices and any actions by any member
                  contrary to these expectations may result in membership being
                  terminated. Offensive language, threatening behavior, abuse of
                  PAXICO staff or other Members and damage to PAXICO Property,
                  or that of another Member, will not be tolerated.
                </div>
                <div className='terms_paragraph'>
                  <br></br>
                  <span className='termList'>4.2</span>PAXICO is a collaborative
                  workspace, and you may find that you are working in close
                  proximity to individuals or organizations that compete with
                  your business. It is your responsibility to ensure that any
                  obligations you may have regarding proximity and/or
                  confidentiality with respect to such competing organizations
                  and other Members generally are adhered to.
                </div>
                <div className='terms_paragraph'>
                  <br></br>
                  <span className='termList'>4.3</span>You acknowledge that due
                  to the shared nature of the Space, sensitive information may
                  sometimes be overheard, and you agree to respect the right of
                  privacy and confidentiality of other Members in such
                  circumstances.
                </div>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <div className='terms_paragraph'>
                  <br></br>
                  <span className='termList'>4.4</span>During the membership
                  period as well as for a period of 1 year from the termination
                  or end of membership, no member shall (i) induce, encourage or
                  attempt to induce any employee/contractor of PAXICO to leave
                  the employ of PAXICO, or in any way interfere with the
                  relationship between PAXICO and any employee thereof,(ii) hire
                  directly or through another entity any person who was an
                  employee of PAXICO at any time during the membership period,
                  or (iii) induce or attempt to induce any customer, supplier,
                  licensee or other business relation of PAXICO to cease doing
                  business with PAXICO, or in any way interfere with the
                  relationship between any such customer, supplier, licensee or
                  business relation and PAXICO (including, without limitation,
                  making any negative statements or communications concerning
                  PAXICO or any of its directors, officers, employees or
                  affiliates)
                </div>
                <div className='termHeadingWrapper '>
                  <span className='termList '>5. </span>PAYMENTS
                </div>
                <div className='terms_paragraph'>
                  <br></br>
                  <span className='termList'>5.1</span>All payments must be made
                  in advance to the following bank account details
                </div>
                <br />
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    lineHeight: '12px',
                  }}
                >
                  <span style={{ fontWeight: '600', textAlign: 'left' }}>
                    Account Name : PAXICO
                  </span>{' '}
                  <br />
                  <span style={{ fontWeight: '600', textAlign: 'left' }}>
                    Account No : 0002070235
                  </span>{' '}
                  <br />
                  <span style={{ fontWeight: '600', textAlign: 'left' }}>
                    IBAN : PK08JSBL9616000002070235
                  </span>{' '}
                  <br />
                  <span style={{ fontWeight: '600', textAlign: 'left' }}>
                    JS BANK
                  </span>{' '}
                </div>
                <div className='terms_paragraph'>
                  <br></br>
                  <span className='termList'>5.2</span>Memberships must be paid
                  at minimum in monthly installments, with the first installment
                  being made prior to the commencement of membership, and each
                  subsequent installment being made at least one day prior to
                  the month to which the Payment applies.
                </div>
                <div className='terms_paragraph'>
                  <br></br>
                  <span className='termList'>5.3</span>All members shall deposit
                  a sum equal to{' '}
                  <span style={{ fontWeight: '600' }}>1 Month Rent</span> as a
                  deposit against their membership, the amount of which shall be
                  returned at the end of the agreed time of conclusion of the
                  license. PAXICO reserves the right to make necessary
                  deductions from the deposit for any damage to PAXICO
                  facilities made intentionally or unintentionally by the
                  member. In case a member terminates his contract before the
                  agreed time, the deposit shall stand forfeited.
                </div>
                <div className='terms_paragraph'>
                  <br></br>
                  <span className='termList'>5.4</span>In the event of delay in
                  payment of a monthly bill by Client within thirty (30) days
                  beyond its due date, a Late Payment Surcharge shall be payable
                  by Client to Buyer at the rate of 5% per month on the
                  outstanding amount. The late payment surcharge shall be
                  claimed by Client within 3 business days.
                </div>
                <div className='terms_paragraph'>
                  <br></br>
                  <span className='termList'>5.5</span>Paxico reserves the right
                  to withhold services if payments are not received on time.
                  This includes revoking access to any PAXICO’s buildings.
                </div>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <div className='termHeadingWrapper '>
                  <span className='termList '>6. </span>NO UNLAWFUL OR
                  PROHIBITED USE
                </div>
                <div className='terms_paragraph'>
                  <br></br>
                  <span className='termList'></span>As a condition of your use
                  of the Services, you will not use the Services for any purpose
                  that is unlawful or prohibited by these terms, conditions and
                  notices. You may not use the Services in any manner that could
                  damage, disable, overburden, or impair any PAXICO server, or
                  the network(s) connected to any PAXICO server, or interfere
                  with any other party’s use and enjoyment of any Services. You
                  may not attempt to gain unauthorized access to any Services,
                  or accounts, computer systems or networks connected to any
                  PAXICO server or to any of the Services, through hacking,
                  password mining or any other means. You may not obtain or
                  attempt to obtain any materials or information through any
                  means not intentionally made available through the Services.
                  <br />
                  <br />
                  You hereby represent and warrant that you have all requisite
                  legal power and authority to enter into and abide by the terms
                  and conditions of this TOU and no further authorization or
                  approval is necessary. You further represent and warrant that
                  your participation or use of the Services will not conflict
                  with or result in any breach of any license, contract,
                  agreement or other instrument or obligation to which you are a
                  party.
                  <br />
                  <br />
                  PAXICO premises is a Smoke Free Zone and does not allow any
                  customer to smoke either in their respective offices,
                  Cafeteria, Common Space, Lounge or any other place
                </div>
                <div className='termHeadingWrapper '>
                  <span className='termList '>7. </span>CONFIDENTIALITY
                </div>
                <div className='terms_paragraph'>
                  <br></br>
                  <span className='termList'>7.1</span>PAXICO reserves the right
                  at all times to disclose any information about you, your
                  participation in and use of the Services as PAXICO deems
                  necessary to satisfy any applicable law, regulation, legal
                  process or governmental request, or to edit, refuse to post or
                  to remove any information or materials, in whole or in part,
                  in PAXICO’s sole discretion.
                </div>
                <div className='terms_paragraph'>
                  <br></br>
                  <span className='termList'>7.2</span>You acknowledge and agree
                  that during your participation in and use of the Services you
                  may be exposed to Confidential Information. “Confidential
                  Information” shall mean all information, in whole or in part,
                  that is disclosed by PAXICO, or any participant or user of the
                  Services or any employee, affiliate, or agent thereof, that is
                  non-public, confidential or proprietary in nature.
                  Confidential Information also includes, without limitation,
                  information about business, sales, operations, know-how, trade
                  secrets, technology, products, employees, customers, marketing
                  plans, financial information, services, business affairs, any
                  knowledge gained through examination or observation of or
                  access to the facilities, computer systems and/or books and
                  records of PAXICO,any analyses, compilations, studies or other
                  documents prepared by PAXICO or otherwise derived in any
                  manner from the Confidential Information and any information
                  that you are obligated to keep confidential or know or has
                  reason to know should be treated as confidential.
                </div>
                <div className='terms_paragraph'>
                  <br></br>
                  <span className='termList'>7.3</span>Your participation in
                  and/or use of the Services obligates you to:
                </div>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <div className='terms_paragraph'>
                  <br></br>
                  <span className='termList'>7.3.1</span>maintain all
                  Confidential Information in strict confidence;
                </div>
                <div className='terms_paragraph'>
                  <br></br>
                  <span className='termList'>7.3.2</span>not to disclose
                  Confidential Information to any third parties;
                </div>
                <div className='terms_paragraph'>
                  <br></br>
                  <span className='termList'>7.3.3</span>not to use the
                  Confidential Information in any way directly or indirectly
                  detrimental to PAXICO.
                </div>
                <div className='terms_paragraph'>
                  <br></br>
                  <span className='termList'>7.4</span>All Confidential
                  Information remains the sole and exclusive property of PAXICO
                  or the respective disclosing party. You acknowledge and agree
                  that nothing in this TOU or your participation or use of the
                  Services will be construed as granting any rights to you, by
                  license or otherwise, in or to any Confidential Information or
                  any patent, copyright or other intellectual property or
                  proprietary rights of PAXICO, or any participant or user of
                  the Services.
                </div>
                <div className='terms_paragraph'>
                  <br></br>
                  <span className='termList'>7.5</span>You are responsible for
                  ensuring that your Confidential Information, and that of any
                  of your employees, remains secure within the Space. PAXICO
                  will not be liable for any unauthorized disclosure of your
                  Confidential Information, unless such disclosure occurs as a
                  result of a breach of our confidentiality obligations to you.
                </div>
                <div className='terms_paragraph'>
                  <br></br>
                  <span className='termList'>7.6</span>PAXICO make no
                  representations about the security of our internet connection,
                  and you must take reasonable security measures (i.e.
                  encryption) as are necessary for your business or enterprise.
                </div>
                <div className='termHeadingWrapper '>
                  <span className='termList '>8. </span>DISCLAIMER OF WARRANTIES
                </div>
                <div className='terms_paragraph'>
                  <br></br>
                  To the maximum extent permitted by applicable law, PAXICO
                  provides the services “as is” and with all faults, and hereby
                  disclaim with respect to the services all warranties and
                  conditions, whether express, implied or statutory, including,
                  but not limited to, any (if any) warranties, duties or
                  conditions of or related to: merchantability, fitness for a
                  particular purpose, lack of viruses, accuracy or completeness
                  of responses, results, workmanlike effort and lack of
                  negligence. Also, there is no warranty, duty or condition of
                  title, quiet enjoyment, quiet possession, correspondence to
                  description or non-infringement. The entire risk as to the
                  quality, or arising out of participation in or the use of the
                  services, remains with you.
                </div>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <div className='termHeadingWrapper '>
                  <span className='termList '>9. </span>TERM & TERMINATION
                </div>
                <div className='terms_paragraph'>
                  <br></br>
                  <span className='termList'>9.1</span> The Term of the
                  Agreement will be as defined in Annex A of the Services
                  Agreement. In the event that a Client opts to terminate the
                  Services Agreement or TOU before the stated time period in
                  Annex A, they shall agree to forfeit the One (01) month
                  deposit without legal re course.
                </div>
                <div className='terms_paragraph'>
                  <br></br>
                  <span className='termList'>9.2</span> PAXICO reserves the
                  right to terminate any and all Service(s) and your membership
                  at any time, immediately and without notice or cause.
                </div>
                <div className='termHeadingWrapper '>
                  <span className='termList '>10. </span>INTERNET USE
                </div>
                <div className='terms_paragraph'>
                  <br></br>
                  <span className='termList'>10.1</span>Use of the internet by
                  Paxico members implies agreement with our internet use policy.
                </div>
                <div className='terms_paragraph'>
                  <br></br>
                  <span className='termList'>10.2</span>Members must ensure that
                  they;
                </div>
                <div className='terms_paragraph'>
                  <span className='termList'>a)</span>Comply with current
                  legislation
                </div>
                <div className='terms_paragraph'>
                  <span className='termList'>b)</span>Use the internet in an
                  acceptable way and
                </div>
                <div className='terms_paragraph'>
                  <span className='termList'>c)</span>Do not create unnecessary
                  business risk to PAXICO or their company by their misuse of
                  the internet.
                </div>
                <div className='terms_paragraph '>
                  <br></br>
                  <span className='termList '>10.3 </span>Unacceptable behavior
                  and in particular the following is deemed unacceptable use or
                  behavior by members;
                </div>
                <div className='terms_paragraph'>
                  <span className='termList'>a)</span>Visiting internet sites
                  that contain obscene, hateful, pornographic or otherwise
                  illegal material,
                </div>
                <div className='terms_paragraph'>
                  <span className='termList'>b)</span>Using the computer to
                  perpetrate any form of fraud, or software, film or music
                  piracy,
                </div>
                <div className='terms_paragraph'>
                  <span className='termList'>c)</span>Downloading commercial
                  software or any copyrighted materials belonging to third
                  parties, unless this download is covered or permitted under a
                  commercial agreement or other such license,
                </div>
                <div className='terms_paragraph'>
                  <span className='termList'>d)</span>hacking into unauthorized
                  areas,
                </div>
                <div className='terms_paragraph'>
                  <span className='termList'>e)</span>Undertaking deliberate
                  activities that waste staff effort or networked resources,
                </div>
                <div className='terms_paragraph'>
                  <span className='termList'>f)</span>Introducing any form of
                  malicious software into the corporate network.
                </div>
                <div className='terms_paragraph '>
                  <br></br>
                  <span className='termList '>10.4 </span>PAXICO maintains the
                  right to monitor the volume of internet and network traffic,
                  together with the internet sites visited.
                </div>
                <div className='terms_paragraph '>
                  <br></br>
                  <span className='termList '>10.5 </span>In the event that a
                  Client or one of its employee is found to be in breach of
                  Internet use policy as communicated in writing by PAXICO,
                  PAXICO shall have the right to terminate the said Agreement or
                  license to use the space dedicated with immediate effect and
                  to claim damages caused by the said event.
                </div>
                <br></br>
                <br></br>
                <div className='termHeadingWrapper '>
                  <span className='termList '>11 </span>INDEMNIFICATION,
                  LIABILITY & DISCLAIMER:
                </div>
                <div className='terms_paragraph '>
                  <br></br>
                  <span className='termList '>11.1 </span>You release, and
                  hereby agree to indemnify, defend and save harmless PAXICO and
                  PAXICO’s subsidiaries (whether or not wholly-owned),
                  affiliates, divisions, and their past, present and future
                  officers, agents, shareholders, members, representatives,
                  employees, successors and assigns, jointly and individually,
                  from and against all claims, liabilities, losses, damages,
                  costs, expenses, judgments, fines and penalties based upon or
                  arising out of your negligent actions, errors and omissions,
                  willful misconduct and fraud in connection with the
                  participation in or use of the Services. You further agree in
                  the event that you bring a claim or lawsuit in violation of
                  this agreement, you shall be liable for any attorneys’ fees
                  and costs incurred by PAXICO or its respective officers and
                  agents in connection with the defense of such claim or
                  lawsuit.
                </div>
                <div className='terms_paragraph '>
                  <br></br>
                  <span className='termList '>11.2 </span>PAXICO will not in any
                  circumstances have any liability for loss of business, loss of
                  profits, loss of anticipated savings, loss of or damage to
                  data, third party claims or any consequential loss.
                </div>
                <div className='terms_paragraph '>
                  <br></br>
                  <span className='termList '>11.3 </span>PAXICO, shall ensure a
                  safe working environment for its Client. However, the Clients
                  shall take due care of their belongings and assets. PAXICO
                  shall not be liable for any loss or claims in the event that a
                  loss occurs due to misconduct, negligence or carelessness of
                  PAXICO.
                </div>
                <div className='terms_paragraph '>
                  <br></br>
                  <span className='termList '>11.4 </span>PAXICO shall not be
                  responsible for any loss, damage, corruption of data or any
                  loss of information whether from hardware, software or
                  internet damage that may occur to the Client during the term
                  of this agreement. PAXICO shall not be responsible for any
                  loss, damage or loss of information resulting from
                  communications or data failure including voice, communication
                  and the internet.
                </div>
                <div className='terms_paragraph '>
                  <br></br>
                  <span className='termList '>11.5 </span>Subject to gross
                  negligence and deliberate misconduct, PAXICO is not liable for
                  any loss as a result of PAXICO’s failure to provide a service
                  as a result of mechanical breakdown, strike, termination of
                  PAXICO’s interest in the building containing the Centre or
                  otherwise.
                </div>
                <div className='terms_paragraph '>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <span className='termList '>11.6</span>In no event shall
                  PAXICO be liable for any loss or damage.
                </div>
                <div className='terms_paragraph '>
                  <br></br>
                  <span className='termList '>11.7</span>Client must take good
                  care of all parts of the business center, its equipment,
                  fittings and furnishings which you use. Client must not alter
                  any part of it. Client are liable for any damage caused by
                  Client or those in the business center with your permission or
                  at your invitation. Such damage cost shall be ascertained by
                  PAXICO at its sole discretion.
                </div>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <div className='termHeadingWrapper '>
                  <span className='termList '>12 </span>CONFERENCE ROOM
                </div>
                <div className='terms_paragraph '>
                  <br></br>
                  <span className='termList '>12.1</span>It is required to make
                  a reservation for the Conference Room with the PAXICO team as
                  early as possible to ensure availability.
                </div>
                <div className='terms_paragraph '>
                  <br></br>
                  <span className='termList '>12.2</span>Conference room may
                  only be used by 10 individuals at a time. This shall be
                  responsibility of the Client booking conference room to ensure
                  number of individuals do not increase the limit prescribed in
                  this clause.
                </div>
                <div className='termHeadingWrapper '>
                  <span className='termList '>13 </span>GOVERNING LAW AND
                  ARBITRATION
                </div>
                <div className='terms_paragraph '>
                  <br></br>
                  <span className='termList '>13.1</span>This Agreement along
                  with Annexes shall be governed by and construed in accordance
                  with the laws of Islamic Republic of Pakistan.
                </div>
                <div className='terms_paragraph '>
                  <br></br>
                  <span className='termList '>13.2</span>If at any time, any
                  differences or disputes arise between the Parties which cannot
                  be resolved by informal negotiation in a shorter time as
                  reasonably possible, then either Party may give the other
                  Party notice in writing of the existence of such difference or
                  dispute, specifying the nature and extent of the disputed
                  points at issue and the Parties shall then proceed
                  expeditiously and in good faith to resolve such matters by
                  formal consultation and negotiation.
                </div>
                <div className='terms_paragraph '>
                  <br></br>
                  <span className='termList '>13.3</span>If the Parties are
                  unable to resolve the matters in dispute within a period of
                  fifteen (15) days immediately commencing from the date of
                  original notice of the dispute(s), then all such dispute(s)
                  shall be settled through arbitration by three (03)
                  arbitrators; one to be appointed by each Party and third who
                  shall act as an umpire shall be appointed by the two said
                  appointed arbitrators. The arbitration shall be held under the
                  provisions of the Arbitration Act 1940 as amended. The
                  arbitration shall be in held in Islamabad in English language.
                  The award of the arbitration shall be binding on the Parties.
                  The Parties shall bear the cost of their appointed arbitrator
                  and the cost of the third arbitrator shall be shared equally.
                </div>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <div className='termHeadingWrapper '>
                  <span className='termList '>14 </span>COUNTERPARTS
                </div>
                <div className='terms_paragraph '>
                  <br></br>
                  This Services Agreement & TOU is executed / signed by the duly
                  authorized representative(s) (“representative(s)”) of the
                  respective Parties and such representative(s) assure and
                  guarantee that in accordance with the respective Parties’
                  internal policies, constitutional documents, board
                  resolutions, delegation of authority, relevant law and other
                  ancillary documents they are duly authorized by the respective
                  Parties to execute this agreement on their behalf. If at any
                  time it transpires otherwise, then such representative(s)
                  misrepresenting knowingly or unknowingly shall fully indemnify
                  the other affected Party and shall be personally liable to
                  criminal and civil legal recourse available to other party.
                </div>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <div style={{ textAlign: 'left' }}>
                  {' '}
                  <span style={{ fontWeight: '600' }}>
                    IN WITNESS WHEREOF
                  </span>{' '}
                  the Parties hereto have set their hands the day, month and
                  year first above written.
                </div>
                <br></br>
                <br></br>
                <div
                  style={{
                    display: 'flex',
                    textAlign: 'left',
                    lineHeight: '40px',
                  }}
                >
                  <div style={{ width: '50%' }}>
                    <span style={{ fontWeight: '600' }}>
                      For and on behalf of PAXICO
                      <br></br>
                    </span>
                    <br></br>
                    <br></br>
                    <span>Sign : _______________</span>
                    <br />
                    <span>Name :</span>
                    <br />
                    <span>Title :</span>
                  </div>
                  <div style={{ width: '50%' }}>
                    <span style={{ fontWeight: '600' }}>
                      For and On Behalf of Client
                      <br></br>
                    </span>
                    <br></br>
                    <br></br>

                    <span>Sign : _______________</span>
                    <br />
                    <span>Name :</span>
                    <br />
                    <span>Title :</span>
                  </div>
                </div>
                <br />
                <br />
                <div
                  style={{
                    display: 'flex',
                    textAlign: 'left',
                    lineHeight: '40px',
                  }}
                >
                  <div style={{ width: '50%' }}>
                    <span style={{ fontWeight: '600' }}>
                      Witnesses:
                      <br></br>
                    </span>
                    <br></br>
                    <br></br>
                    <span>1 : _______________</span>
                    <br />
                    <span>Name :</span>
                    <br />
                    <span>CNIC/Passport :</span>
                  </div>
                  <div style={{ width: '50%' }}>
                    <span style={{ fontWeight: '600' }}>
                      <br></br>
                    </span>
                    <br></br>
                    <br></br>

                    <span>2 : _______________</span>
                    <br />
                    <span>Name :</span>
                    <br />
                    <span>CNIC/Passport :</span>
                  </div>
                </div>
                <br />
                <br />
                <br />
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <div className='header' style={{ textDecoration: 'none' }}>
                  CUSTOMER & PACKAGE DETAILS
                </div>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <div className='subtitle'>
                  Full Legal Name of Contractor : {Data && Data.contractorName}
                  <hr className='underline'></hr>
                </div>
                <div className='subtitle'>
                  CNIC/Registration No : {Data && Data['CNIC_NO']}
                  <hr className='underline'></hr>
                </div>
                <div className='subtitle'>
                  Permanent Address : {Data && Data.address}{' '}
                  <hr className='underline'></hr>
                </div>
                <div className='subtitle'>
                  Email ID: {Data && Data.email}
                  <hr className='underline'></hr>
                </div>
                <div className='subtitle'>
                  Contact No : {Data && Data.number}
                  <hr className='underline'></hr>
                </div>
                <div className='title'>
                  Membership Information
                  <span className=''> (for official use only)</span>
                </div>
                <div className='subtitle'>
                  Membership Start Date : {Data && Data.startDate}
                </div>
                <div className='subtitle'>
                  Membership End Date : {Data && Data.endDate}
                </div>
                <div className='subtitle'>
                  Membership Duration : {Data && Data.duration} Months
                </div>
                {/* {Data &&
                  Data.offices.forEach((office: any) => {
                    of = office.label + ' , ' + of;
                  })} */}{' '}
                {Data &&
                  Data.offices.forEach((office: any) => {
                    if (Data[`${office.label}_sharedSeats`]) {
                      sharedSeats =
                        sharedSeats +
                        Number(Data[`${office.label}_sharedSeats`]);
                    }
                  })}
                {(function () {
                  let virtualCheck = false;
                  if (!Data.hasOwnProperty('virtualPrice')) {
                    virtualCheck = true;
                  }

                  if (
                    Data &&
                    (Data.sharedSeats !== '' || Data.sharedSeats !== 0) &&
                    (Data.privateSeats === '' || Data.privateSeats === 0) &&
                    (virtualCheck ||
                      Data.virtualSeats === '' ||
                      Data.virtualSeats === 0)
                  ) {
                    totalAmount = Number(Data.sharedPrice) * sharedSeats;
                    packageName = 'Dedicated Space';
                  } else if (
                    Data &&
                    (Data.sharedSeats === '' || Data.sharedSeats === 0) &&
                    Data.privateSeats !== '' &&
                    Data.privateSeats !== 0 &&
                    (virtualCheck ||
                      Data.virtualSeats === '' ||
                      Data.virtualSeats === 0)
                  ) {
                    totalAmount =
                      Number(Data.privatePrice) * Number(Data.privateSeats);

                    packageName = 'Private Space';
                  } else if (
                    Data &&
                    (Data.sharedSeats === '' || Data.sharedSeats === 0) &&
                    Data.privateSeats === '' &&
                    Data.privateSeats === 0 &&
                    (virtualCheck ||
                      Data.virtualSeats !== '' ||
                      Data.virtualSeats !== 0)
                  ) {
                    totalAmount =
                      Number(Data.virtualPrice) * Number(Data.virtualSeats);

                    packageName = 'Virtual Space';
                  } else if (
                    Data &&
                    (Data.sharedSeats !== '' || Data.sharedSeats !== 0) &&
                    Data &&
                    Data.privateSeats !== '' &&
                    Data.privateSeats !== 0 &&
                    Data &&
                    (virtualCheck ||
                      Data.virtualSeats !== '' ||
                      Data.virtualSeats !== 0)
                  ) {
                    totalAmount =
                      Number(Data.sharedPrice) * sharedSeats +
                      Number(Data.privatePrice) * Number(Data.privateSeats) +
                      Number(Data.virtualPrice) * Number(Data.virtualSeats);

                    packageName =
                      'Dedicated Space & Private Space & Virtual Space';
                  }

                  if (Data && Data.dedicatedParkingPrice) {
                    totalAmount =
                      totalAmount + Number(Data.dedicatedParkingPrice);
                  }
                })()}
                <div className='subtitle'>MemberShip Type : {packageName}</div>
                {Data &&
                  Data.offices.forEach((office: any) => {
                    of = office.label + ' , ' + of;
                  })}
                <div className='subtitle'>
                  Offices Name : {of.replace(/,\s*$/, '')}
                </div>
                {/* {Data &&
                  Data.privateSeats !== '' &&
                  Data.privateSeats !== 0 &&
                  Data.privateSeats !== '0' && (
                    <div className='subtitle'>
                      Office Seats : {Data.privateSeats}
                    </div>
                  )}
                {Data &&
                  Data.offices.forEach((office: any) => {
                    if (Data[`${office.label}_sharedSeats`]) {
                      sharedSeats =
                        sharedSeats +
                        Number(Data[`${office.label}_sharedSeats`]);
                    }
                  })}
                {sharedSeats !== 0 && (
                  <div className='subtitle'>
                    Dedicated Seats : {sharedSeats}
                  </div>
                )} */}
                {Data &&
                  Data.dedicatedParkingPrice &&
                  Data.dedicatedParkingPrice !== 0 &&
                  Data.dedicatedParkingPrice !== '0' && (
                    <div className='subtitle'>
                      Dedicated Parking :{' '}
                      <span className='priceTag'>
                        PKR {Data.dedicatedParkingPrice}
                      </span>
                    </div>
                  )}
                <div className='subtitle'>
                  Monthly Membership Price :{' '}
                  <span className='priceTag'>
                    PKR {numberWithCommas(totalAmount)}
                  </span>{' '}
                  /- exclusive of tax
                </div>
                <div className='note' style={{ textAlign: 'left' }}>
                  <span>
                    Membership is not valid unless payment has been made for the
                    upcoming period along with security deposit.Payments are due
                    at least 1 day prior to the Membership Payment Period to
                    which they apply.
                  </span>
                </div>
                <div className='note' style={{ textAlign: 'left' }}>
                  <span>
                    Payments are due at least 1 day prior to the Membership
                    Payment Period to which they apply.
                  </span>
                </div>
                <div className='title'>Information for Invoice</div>
                <div className='subtitle'>
                  Business or Personal Name for Invoice :{' '}
                  {Data && Data.nameForInvoice}
                </div>
                <div className='subtitle'>
                  Invoice Address : {Data && Data.invoiceEmail}
                </div>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <div style={{ textAlign: 'center', marginTop: '30px' }}>
                  <span style={{ fontWeight: '600' }}>
                    Package Name: {packageName}
                  </span>
                </div>
                <br></br>
                <br></br>
                <div className='terms_paragraph '>
                  <br></br>
                  This package is ideal for regular entrepreneurs who like
                  flexibility and are willing to just plug-in and start working
                  from an available spot.
                </div>
                <div className='terms_paragraph '>
                  <br></br>
                  <span className='termList '>a)</span> A discounted monthly fee
                  of{' '}
                  <span style={{ fontWeight: '600' }}>
                    PKR {numberWithCommas(totalAmount)}
                  </span>
                  /- for Office will be charged with access from Monday-Friday 9
                  am - 6 pm. This will include unlimited tea &amp; coffee
                  (self-service and fair-usage policies apply); wireless
                  internet (fair-usage policy applies); power back-up; access to
                  common spaces; occasional promotion on social media and in the
                  PAXICO space; and access to PAXICO community events.
                </div>
                <div className='terms_paragraph '>
                  <br></br>
                  <span className='termList '>b)</span> A security deposit of
                  <span style={{ fontWeight: '600' }}>
                    {''} PKR {numberWithCommas(totalAmount)}
                  </span>
                  /- will be paid at the time of joining.
                </div>
                <div className='terms_paragraph '>
                  <br></br>
                  <span className='termList '>c)</span> Conference room will be
                  available at a rate of{' '}
                  <span style={{ fontWeight: '600' }}>
                    {''} PKR {numberWithCommas(1500)}
                  </span>{' '}
                  /hour, subject to availability and the meeting room may also
                  be made available at a rate of{' '}
                  <span style={{ fontWeight: '600' }}>
                    {''} PKR {numberWithCommas(800)}
                  </span>
                  /hour, subject to availability.
                </div>
                <div className='terms_paragraph '>
                  <br></br>
                  <span className='termList '>d)</span>Residents will be allowed
                  to host 2 guests on any given day at PAXICO. Guests may only
                  be accommodated in the common spaces. Please note the limit
                  for each guest is 2 hours per day. In case the number of
                  guests exceeds 2 or the time limit is exceeded, they may be
                  hosted at a charge of PKR300 /hour in the Common Spaces or PKR
                  700/hour in the Meeting Room & 1500/hour for the Conference
                  Room.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Contract;
