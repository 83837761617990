export default interface Client {
  id: string;
  name: string;
  companyName: string;
  number: number | string;
  email: string;
  contract: string;
  offices: string;
}

export const isClient = (obj: any, validate = false): obj is Client => {
  const hasProperties =
    'name' in obj &&
    'companyName' in obj &&
    'number' in obj &&
    'email' in obj &&
    'contract' in obj &&
    'offices' in obj;

  if (validate && hasProperties) {
    if (
      obj.name.length === 0 ||
      obj.companyName.length === 0 ||
      obj.number.length === 0 ||
      obj.email.length === 0 ||
      obj.contract.length === 0 ||
      obj.offices.length === 0
    ) {
      return false;
    }
  }

  return hasProperties;
};
