import styled from 'styled-components';

export const FormWrapper = styled.div`
  position: relative;
`;

export const ScheduleFormWrapper = styled.div`
  display: flex;
  > div {
    margin-right: 4px;
    width: 300px;
  }
  > input {
    width: 300px;
  }
`;
export const InputWrapper = styled.div`
  > label {
    margin-right: 20px;
    margin-bottom: 5px;
    margin-top: 8px;
    font-weight: 600;
  }

  > input {
    width: 100%;
    border-radius: 6px;
    outline: none;
    border: 1px solid darkgrey;
    padding-left: 10px;
    height: 38px;
    box-shadow: rgb(0 0 0 / 5%) 0px 6px 24px 0px,
      rgb(0 0 0 / 8%) 0px 0px 0px 1px;
    border: 1px solid lightgray;
  }

  > textarea {
    border-radius: 9px;
    border: 2px solid lightgray;
    outline: none;
    box-shadow: rgb(0 0 0 / 5%) 0px 6px 24px 0px,
      rgb(0 0 0 / 8%) 0px 0px 0px 1px;
  }
`;
