export default interface Contract {
  id?: string;
  contractorName: string;
  contractorId: string;
  CNIC_NO: string;
  email: string;
  number: number | string;
  address: string;
  startDate: string;
  endDate: string;
  duration: string;
  offices: any;
  privateSeats: string;
  sharedSeats: string;
  type: any;
  dedicatedParkingPrice: string;
  sharedPrice: string;
  privatePrice: string;
}

export const isContract = (obj: any, validate = false): obj is Contract => {
  const hasProperties =
    ('contractorName' in obj &&
      'CNIC_NO' in obj &&
      'email' in obj &&
      'number' in obj &&
      'address' in obj &&
      'startDate' in obj &&
      'endData' in obj &&
      'duration' in obj &&
      'offices' in obj &&
      'type' in obj &&
      'contractorId' in obj &&
      'privatePrice' in obj) ||
    'dedicatedParking' in obj ||
    'privateSeats' in obj ||
    'sharedPrice' in obj ||
    'sharedSeats' in obj;

  if (validate && hasProperties) {
    if (obj.name.length === 0 || obj.seats.length === 0) {
      return false;
    }
  }

  return hasProperties;
};
