import { useMemo, useRef, useState } from 'react';
import { Table } from '../../Components/Table';
import { InvoicesWrapper } from '../Page.styles';
import { TbFileInvoice } from 'react-icons/tb';
import { toast } from 'react-toastify';
import { FaTrash } from 'react-icons/fa';
import store from '@jitcoder/usestore';
export default function Invoices({
  parentData,
  viewInvoice,
  handleScheduleRowDelete,
}: any) {
  const childRef: any = useRef();
  const [isPaid, setIsPaid] = useState('No');

  const columns = useMemo(
    () => [
      {
        id: 1,
        name: 'Invoice ID',
        selector: (row: any) => row.invoiceId,
        sortable: true,
        reorder: true,
      },

      {
        id: 2,
        name: 'Creation Date',
        selector: (row: any) => row.creationDate,
        sortable: true,
        reorder: true,
      },
      {
        id: 3,
        name: 'Due Date',
        selector: (row: any) => row.dueDate,
        sortable: true,
        reorder: true,
      },
      {
        id: 4,
        name: 'Is Paid ?',
        sortable: true,
        reorder: true,
        cell: (row: any) => (
          <>
            <input
              type='checkBox'
              disabled={
                (isPaid === 'Yes' || row.isPaid === 'Yes') &&
                row.hasOwnProperty('isPaid')
              }
              checked={row.isPaid === 'Yes' ? true : false}
              onChange={async () => {
                try {
             
                  setIsPaid('Yes');
                  childRef &&
                  childRef.current &&
                  childRef.current.renderModalFromChild();
                 
                  store.set('isPaidData', { row, parentData });
                } catch (e) {
                  toast.error('an Error accured during Invoice Update');
                  console.log('error', e);
                }
              }}
            ></input>
          </>
        ),
      },
      {
        id: 5,
        name: 'Paid Date',
        selector: (row: any) => row.paymentDate,
        sortable: true,
        reorder: true,
      },
      {
        id: 6,
        name: 'View Invoice',

        right: false,
        center: true,
        cell: (row: any) => (
          <>
            {/* <NavLink to='/invoiceView'> View</NavLink> */}
            <TbFileInvoice
              style={{ marginRight: '5px', fontSize: '1rem' }}
              onClick={() => {
                viewInvoice(true, row);
              }}
            ></TbFileInvoice>
          </>
        ),
      },
      {
        id: 7,
        right: false,
        center: true,
        cell: (row: any, index: any) => (
          <FaTrash
            style={{
              fontSize: '1rem',

              marginLeft: '6px',
            }}
            id={`delete ${index}`}
            onClick={() =>
              handleScheduleRowDelete(parentData, index, 'DELETE INVOICE')
            }
          />
        ),
      },
    ],
    [handleScheduleRowDelete, isPaid, parentData, viewInvoice]
  );

  return (
    <InvoicesWrapper>
      <Table
        columns={columns}
        title={'Invoices'}
        parentData={parentData.invoices}
        childRef={childRef}
        hideButton={true}
        onRowClick={false}
      ></Table>
    </InvoicesWrapper>
  );
}
