import {
  useState,
  useEffect,
  useCallback,
  forwardRef,
  useImperativeHandle,
} from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { TableButton } from '../Table/Table.styles';
import LeadForm from '../Forms/LeadForm';
import ClientForm from '../Forms/ClientForm';
import OfficeForm from '../Forms/OfficeForm';
import MeetingRoomForm from '../Forms/MeetingRoomForm';
import ScheduleRoomForm from '../Forms/ScheduleRoomForm';
import InvoiceForm from '../Forms/InvoiceForm';
import ContractForm from '../Forms/ContractForm';
import { getClientId } from '../../lib/getClientById';
import { TiWarningOutline } from 'react-icons/ti';
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
import { toastMSG } from '../../lib/toastMSG';
import DeleteModal from '../DeleteModal/DeleteModal';
import { InputWrapper } from '../Forms/Forms.styles';
import 'react-toastify/dist/ReactToastify.css';
import { deleteLead, getLead, upsertLead } from '../../api/leads';
import { deleteOffice, getOffice, upsertOffice } from '../../api/offices';
import { deleteClient, getClient, upsertClient } from '../../api/clients';
import {
  deleteContract,
  getContract,
  upsertContract,
} from '../../api/contracts';
import Cookie from 'js-cookie';
import jwt_decode from 'jwt-decode';
import { deleteMeetingRoom } from '../../api/meetingRooms';
import { ModalInnerWrapper } from './Modal.styles';
import store from '@jitcoder/usestore';
import Client from '../../models/client';
import { FaPlusCircle } from 'react-icons/fa';
import { getMeetingRoom, upsertMeetingRoom } from '../../api/meetingRooms';
import MeetingRoom from '../../models/meetingRoom';
import Lead from '../../models/lead';
import { getTotalSeatsOfClientByType } from '../../lib/getTotalSeatsofClientByType';
import { formatAMPM } from '../../lib/formatTimeAMPM';
import { findOfficeById } from '../../lib/findOfficeById';
import { getById } from '../../lib/getById';
import { get2ArrayofObjectsDiff } from '../../lib/get2ArrayofObjectsDiff';
import { dateWithMonthsDelay } from '../../lib/addMonthToCurrentMonth';
import { getHourBtwTwoTimes } from '../../lib/getHourBwtTwoTimes';
import { isObjectEmpty } from '../../lib/isObjectEmpty';
import { getCalled, getMissedCalls, upsertCallLog } from '../../api/callLogs';
import { monthDiff } from '../../lib/monthDiff';
import AddItemInvoiceModal from './AddItemInvoiceModal';
import {
  ClientFormObj,
  ContractFormObj,
  LeadFormObj,
  OfficeFormObj,
  ScheduleClientObj,
} from '../../consts/FormObjs';
import {
  IClientForm,
  IContract,
  ILeadForm,
  IMeetingRoomForm,
  IOfficeForm,
  IScheduledClient,
  Office,
} from '../../interfaces';
import { capitalizeFirstLetter } from '../../lib/capitalizeFirstLetter';
const ReactModal = forwardRef(({ title, modalType, rowId }: any, ref: any) => {
  const [show, setShow] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isActionPending, setActionPending] = useState(false);
  const [elementIndex, setElementIndex] = useState(0);
  const [validateElement, setValidateElement] = useState('');
  const [rowID, setRowID] = useState('');
  const [titleforDelete, setTitleForDelete] = useState('');
  const [optionalData, setOptionalData] = useState({});
  const [beforeDeleteMeetingRoom, setBeforeDeleteMeetingRoom] = useState([]);
  const [validateElement2, setValidateElement2] = useState('');
  const [invoiceMonth, setInvoiceMonth] = useState('');
  const [consumedDays, setConsumedDays] = useState('');
  const [dueDateInvoice, setDueDateInvoice] = useState('');
  const [, setValidateSchedule] = useState(false);
  const [hideAddSchedule, setHideAddSchedule] = useState(false);
  const [disabledContract, setDisabledContract] = useState(false);
  const [officeAlreadyAssigned, setOfficeAlreadyAssigned] = useState('');
  const storedClients = store.get<Client[]>('clients', []);
  const storedOffices = store.get<Office[]>('offices', []);
  const storedLeads = store.get<Lead[]>('leads', []);
  const storedMeetingRooms = store.get<MeetingRoom[]>('meetingRooms', []);
  const dataFromCallLog = store.get<any>('dataFromCallLog', {});
  const storedContracts = store.get<IContract[]>('contracts', []);
  const [renderCustomModal, setCustomRenderModal] = useState(false);
  const [renderAddItemModal, setRenderAddItemModal] = useState(false);
  const [additionalItems, setAdditionalItems] = useState<any>([]);
  const [leadData, setLeadData] = useState<ILeadForm>(LeadFormObj);
  const [officeData, setOfficeData] = useState<IOfficeForm>(OfficeFormObj);
  const [clientData, setClientData] = useState<IClientForm>(ClientFormObj);
  const [contractData, setContractData] = useState<IContract>(ContractFormObj);
  const scheduledClients: Array<IScheduledClient> = [ScheduleClientObj];
  const [meetingRoomData, setMeetingRoomDate] = useState<IMeetingRoomForm>({
    name: '',
    scheduledClients: scheduledClients,
  });

  useImperativeHandle(ref, () => ({
    handleShow: () => {
      setShow(true);
    },
    renderModalFromChild: () => {
      setCustomRenderModal(renderCustomModal === true ? false : true);
    },
  }));

  const renderAddItemModalCallback = useCallback(() => {
    setRenderAddItemModal(!renderAddItemModal);
  }, [renderAddItemModal]);

  const additionalItemCallback = useCallback(
    (item: any) => {
      let dummy = [...additionalItems];
      dummy.push(item);
      setAdditionalItems(dummy);
    },
    [additionalItems]
  );

  const renderModalFromChild = useCallback(() => {
    setCustomRenderModal(renderCustomModal === true ? false : true);
  }, [renderCustomModal]);

  useEffect(() => {
    if (!isObjectEmpty(dataFromCallLog)) {
      setLeadData((rest: any) => ({
        ...rest,
        number: dataFromCallLog.data.number,
      }));
    }
  }, [dataFromCallLog, title]);

  const getDateConflict: any = useCallback(
    async (validateTime: Boolean) => {
      let scheduledClient: any = [...meetingRoomData.scheduledClients];

      for (let i = 0; i < scheduledClient.length; i++) {
        let from = new Date(scheduledClient[i].startDate);
        let to = new Date(scheduledClient[i].startDate);
        let checkStartDate;
        // let checkEndDate;

        if (i + 1 === scheduledClient.length) {
          return;
        } else {
          checkStartDate = new Date(
            scheduledClient[i + 1] && scheduledClient[i + 1]!.startDate
          );
          // checkEndDate = new Date(scheduledClient[i + 1] && scheduledClient[i + 1]!.endDate);
        }
        if (
          validateTime &&
          checkStartDate!.getTime() >= from.getTime() &&
          checkStartDate!.getTime() <= to.getTime()
        ) {
          setValidateElement('Please Fix the Scheduling Conflict');
          break;
        } else {
          setValidateElement('');
        }
      }
      if (scheduledClient.length === 1) {
        setValidateElement('');
      }
    },
    [meetingRoomData.scheduledClients]
  );

  const validateDailyHours = useCallback(() => {
    let scheduledClient: any = [...meetingRoomData.scheduledClients];

    let clientId =
      scheduledClient &&
      scheduledClient[scheduledClient && scheduledClient.length - 1] &&
      scheduledClient[scheduledClient && scheduledClient.length - 1].client
        .value;
    let lastScheduledClient =
      meetingRoomData &&
      scheduledClient &&
      scheduledClient[scheduledClient && scheduledClient.length - 1];

    let scheduleClient =
      scheduledClient &&
      scheduledClient.filter((item: any) => item.client.value === clientId);

    let filteredByDate = scheduleClient.filter(
      (item: any) => item.startDate === lastScheduledClient.startDate
    );
    let hour = 0;
    filteredByDate.forEach((item: any) => {
      hour =
        hour +
        getHourBtwTwoTimes(
          item.startDate,
          item.startDate,
          item.startTime,
          item.endTime
        );
    });

    if (hour > 3) {
      setValidateElement2('Maximum Daily Hours limit reached');
    } else {
      setValidateElement2('');
    }
  }, [meetingRoomData]);

  useEffect(() => {
    validateDailyHours();
  }, [meetingRoomData, validateDailyHours]);

  const getTimeConfilct: any = useCallback(() => {
    let result = false;
    let scheduledClient: any = [...meetingRoomData.scheduledClients];
    let startTime;
    let endTime;
    let checkStartTimeMins;
    let checkStartTimeSecs;
    let checkStartTime;
    let startTimeMins;
    let startTimeSecs;
    let endTimeMins;
    let endTimeSecs;

    startTime = startTimeMins + '.' + startTimeSecs;
    endTime = endTimeMins + '.' + endTimeSecs;

    for (let i = 0; i < scheduledClient.length; i++) {
      startTimeMins =
        scheduledClient[elementIndex] &&
        scheduledClient[i]['startTime'].split(':')[0];

      startTimeSecs =
        scheduledClient[elementIndex] &&
        scheduledClient[i]['startTime'].split(':')[1];

      endTimeMins =
        scheduledClient[elementIndex] &&
        scheduledClient[i]['endTime'].split(':')[0];

      endTimeSecs =
        scheduledClient[elementIndex] &&
        scheduledClient[i]['endTime'].split(':')[1];

      startTime = startTimeMins + '.' + startTimeSecs;
      endTime = endTimeMins + '.' + endTimeSecs;

      if (i + 1 === scheduledClient.length) {
        break;
      } else {
        checkStartTimeMins =
          scheduledClient[elementIndex] &&
          scheduledClient[i + 1]['startTime'].split(':')[0];

        checkStartTimeSecs =
          scheduledClient[elementIndex] &&
          scheduledClient[i + 1]['startTime'].split(':')[1];

        checkStartTime = checkStartTimeMins + '.' + checkStartTimeSecs;
      }

      if (checkStartTime >= startTime && checkStartTime <= endTime) {
        // if (
        //   checkStartTime !== undefined &&
        //   startTime !== undefined &&
        //   endTime !== undefined
        // ) {
        result = true;
        break;
        // }
        // result = false;
      } else {
        result = false;
      }
    }
    if (scheduledClient.length === 1) {
      result = false;
    }

    return result;
  }, [elementIndex, meetingRoomData.scheduledClients]);

  useEffect(() => {
    let validateTime = getTimeConfilct();
    getDateConflict(validateTime);
  }, [getDateConflict, getTimeConfilct, meetingRoomData]);

  useEffect(() => {
    let dummyObj = { ...leadData };
    dummyObj.status = { label: 'New', value: 'New' };
    if (modalType === 'ADD' && leadData.status === '') {
      setLeadData(dummyObj);
    }
  }, [leadData, modalType]);

  useEffect(() => {
    if (show && modalType === title && title.split(' ')[0]) {
      setLeadData(LeadFormObj);
      setOfficeData(OfficeFormObj);
      setClientData(ClientFormObj);
    }
  }, [modalType, show, title]);

  const invoiceMonthCallback = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.name === 'dueDateInvoice') {
      setDueDateInvoice(event.target.value.split('-').reverse().join('/'));
    } else if (event.target.name === 'days') {
      setConsumedDays(event.target.value);
    } else {
      setInvoiceMonth(event.target.value);
    }
  };

  const hasClientInOffice = () => {
    let dummOffices = [...storedOffices];
    let index = dummOffices.findIndex((item: any) => item.id === rowId);

    for (const client of storedClients) {
      for (const office of client.offices) {
        if (office.value === dummOffices[index].id) {
          setOfficeAlreadyAssigned(
            `This Office is Assigned to  ${client.name}, to Delete it remove it from the Client`
          );
          return;
        } else {
          setOfficeAlreadyAssigned('');
        }
      }
    }
  };

  const updateClientOnSelectChange: any = useCallback(
    (option: any) => {
      for (const client of storedClients) {
        //client from storedClient from store
        for (const office of client.offices) {
          //office form client.offices
          if (office.value === rowId) {
            // compare office value as id to rowId of clicked office

            if (client.id !== option.value) {
              setOfficeAlreadyAssigned(
                `This Office is Assigned to  ${client.name}, To Change it remove it from the Client`
              );
            } else {
              setOfficeAlreadyAssigned('');
            }
          }
        }
      }
    },
    [rowId, storedClients]
  );
  // const recentChangedOfficeFunc = (dataObj: any) => {
  //   let recentoffices;
  //   let arr: any = [];
  //   let filteredContract = getById(dataObj.id, storedContracts);
  //   let diff = get2ArrayofObjectsDiff(
  //     dataObj && dataObj.offices,
  //     filteredContract && filteredContract[0].offices,
  //     'value'
  //   );
  //   if (diff.length > 0) {
  //     dataObj.offices.forEach((office: any) => {
  //       if (diff.some((e: any) => e.value === office.value)) {
  //         if (dataObj[`${office.label}_sharedSeats`]) {
  //           recentoffices = {
  //             label: `${office.label}, ${
  //               dataObj[`${office.label}_sharedSeats`]
  //             } Seat,${new Date().toISOString().substring(0, 10)}`,
  //             value: `${office.label}, ${
  //               dataObj[`${office.label}_sharedSeats`]
  //             } Seat,${new Date().toISOString().substring(0, 10)}`,
  //           };
  //         } else {
  //           let off = findOfficeById(office.value, storedOffices);
  //           recentoffices = {
  //             label: `${office.label}, ${off[0].seats} Seat,${new Date()
  //               .toISOString()
  //               .substring(0, 10)}`,
  //             value: `${office.label}, ${off[0].seats} Seat,${new Date()
  //               .toISOString()
  //               .substring(0, 10)}`,
  //           };
  //         }
  //         arr.unshift({ ...recentoffices });
  //       }
  //     });
  //   }
  //   dataObj.recentChangedOffice = [...arr];
  // };

  const createInvoice = (clientData: any) => {
    let dummyClient = { ...clientData };
    let seats = getTotalSeatsOfClientByType(storedClients, storedOffices);
    let dummyDescription = {
      title: '',
      quantity: 0,
      price: 0,
      amount: 0,
    };
    let clientInvoice;
    let dummyDesArr: any = [];

    for (const eachSeat in seats) {
      if (dummyClient.name === eachSeat) {
        let keys: any = Object.keys(seats[eachSeat]);
        let seatsObjLength = 0;

        seatsObjLength = Object.keys(seats[eachSeat]).length;

        if (seatsObjLength > 1) {
          for (const key of keys) {
            if (key.split('_')[0] + 'Seats' === 'sharedSeats') {
              dummyDescription = {
                ...dummyDescription,
                title: 'Dedicated Desk',
                price: Number(clientData.sharedPrice),
                quantity: seats[eachSeat][key],
                amount:
                  Number(seats[eachSeat][key]) * Number(clientData.sharedPrice),
              };
              dummyDesArr.push({ ...dummyDescription });
            } else if (key === 'private_Seats') {
              dummyDescription = {
                ...dummyDescription,
                title: 'Private Office',
                price: Number(clientData.privatePrice),
                quantity: seats[eachSeat][key],
                amount:
                  Number(seats[eachSeat][key]) *
                  Number(clientData.privatePrice),
              };
              dummyDesArr.push({ ...dummyDescription });
            } else if (key === 'virtualPrice') {
              dummyDescription = {
                ...dummyDescription,
                title: 'Virtual Office',
                price: Number(clientData.virtualPrice),
                quantity: seats[eachSeat][key],
                amount:
                  Number(seats[eachSeat][key]) *
                  Number(clientData.virtualPrice),
              };
              dummyDesArr.push({ ...dummyDescription });
            }
          }
        } else {
          if (keys[0] !== undefined) {
            let priceKey: any = keys[0].split('_')[0] + 'Price';

            if (priceKey === 'sharedPrice')
              dummyDescription = {
                ...dummyDescription,
                title: 'Dedicated Desk',
                price: Number(clientData.sharedPrice),
                quantity: seats[eachSeat][keys[0]],
              };
            else if (keys[0] === 'private_Seats') {
              dummyDescription = {
                ...dummyDescription,
                title: 'Private Office',
                price: Number(clientData.privatePrice),
                quantity: seats[eachSeat][keys[0]],
              };
            } else if (keys[0] === 'virtual_Seats') {
              dummyDescription = {
                ...dummyDescription,
                title: 'Virtual Office',
                price: Number(clientData.virtualPrice),
                quantity: seats[eachSeat][keys[0]],
              };
            }

            dummyDescription = {
              ...dummyDescription,
              amount:
                Number(seats[eachSeat][keys[0]]) *
                Number(dummyDescription.price),
            };
          }

          dummyDesArr.push({ ...dummyDescription });
        }
      }
    }

    // let extraDays = new Date().getTime() + 172800000;
    // let due_Date = new Date(extraDays)
    //   .toISOString()
    //   .substring(0, 10)
    //   .split('-')
    //   .reverse()
    //   .join('/');
    let Inv = 0;

    if (dummyClient.invoices.length > 0) {
      Inv = Number(dummyClient.invoices[0].invoiceId.split('-')[1]);
      ++Inv;
    } else {
      Inv = 1;
    }
    const contract: any = getById(
      dummyClient &&
        dummyClient.contract[dummyClient.contract.length - 1].value,
      storedContracts
    );

    let newSecurity = '';
    if (contract && contract[0] && contract[0].addendumContract.length > 0) {
      if (
        contract[0].addendumContract[contract[0].addendumContract.length - 1]
          .security !== ''
      ) {
        if (
          dummyClient.invoices &&
          dummyClient.invoices[0] &&
          dummyClient.invoices[0].security !==
            contract[0].addendumContract[
              contract[0].addendumContract.length - 1
            ].security
        ) {
          newSecurity =
            contract[0].addendumContract[
              contract[0].addendumContract.length - 1
            ].security;
        } else {
          newSecurity = '';
        }
      }
    }

    if (consumedDays !== '') {
      let perDayCount = 0;
      let officesAmount: any = 0;
      let totalDaysUseAmount = 0;
      dummyDesArr.forEach((des: any) => {
        officesAmount = officesAmount + des.price * des.quantity;
      });
      perDayCount = officesAmount / 30;
      if (dummyClient.invoices.length >= 1) {
      } else {
        totalDaysUseAmount = perDayCount * Number(consumedDays);
      }

      dummyClient.clientBalance =
        dummyClient.advancePayment - totalDaysUseAmount;
      setConsumedDays('');
    } else {
      let officesAmount: any = 0;

      dummyDesArr.forEach((des: any) => {
        officesAmount = officesAmount + des.amount;
      });
      if (
        dummyClient.invoices.length > 0 &&
        dummyClient.clientBalance !== 0 &&
        dummyClient.clientBalance !== '' &&
        dummyClient.clientData !== 0
      ) {
        dummyClient.clientBalance = dummyClient.clientBalance - officesAmount;
      }
    }

    clientInvoice = {
      name: dummyClient.name,
      number: dummyClient.number,
      email: dummyClient.email,
      invoiceId: `INV-000${Inv}`,
      security:
        dummyClient.invoices.length >= 1 && newSecurity === ''
          ? 0
          : newSecurity !== ''
          ? newSecurity
          : dummyClient.security,
      totalSeatsForSecurity:
        dummyClient.invoices.length >= 1
          ? 0
          : dummyClient.totalSeatsForSecurity,
      nameForInvoice: contract && contract[0].nameForInvoice,
      invoiceEmail: contract && contract[0].invoiceEmail,
      invoiceMonth: invoiceMonth,
      consumedDays: dummyClient.invoices.length >= 1 ? '' : consumedDays,
      creationDate: String(
        new Date().toISOString().substring(0, 10).split('-').reverse().join('/')
      ),
      additionalItems: additionalItems,
      // firstPaymentLessThan1Month:
      //   dummyClient.invoices.length >= 1
      //     ? ''
      //     : dummyClient.firstPaymentLessThan1Month,
      dueDate: dueDateInvoice,
      description: dummyDesArr,
      clientBalance: dummyClient.clientBalance,
    };
    let dummyInv = [...dummyClient.invoices];

    dummyInv.unshift({ ...clientInvoice });
    dummyClient = { ...dummyClient, invoices: [...dummyInv] };
    return dummyClient;
  };

  const handleSave = async (e: any) => {
    e.preventDefault();
    try {
      setActionPending(true);

      if (title && title.split(' ')[1] === 'LEAD') {
        let dummyLead = { ...leadData };
        let recentstatus;
        if (modalType === 'ADD' || dummyLead.leadDate === '')
          dummyLead.leadDate = new Date().toISOString().substring(0, 10);
        if (
          dummyLead.statusLastUpdatedDate &&
          dummyLead.statusLastUpdatedTime
        ) {
          recentstatus = {
            label: `${dummyLead.status.value} | ${
              dummyLead.statusLastUpdatedDate
            } | ${formatAMPM(
              dummyLead.statusLastUpdatedTime &&
                dummyLead.statusLastUpdatedTime.split(':')[0],
              dummyLead.statusLastUpdatedTime &&
                dummyLead.statusLastUpdatedTime.split(':')[1]
            )}`,
            value: `${dummyLead.status.value} | ${
              dummyLead.statusLastUpdatedDate
            } | ${formatAMPM(
              dummyLead.statusLastUpdatedTime &&
                dummyLead.statusLastUpdatedTime.split(':')[0],
              dummyLead.statusLastUpdatedTime &&
                dummyLead.statusLastUpdatedTime.split(':')[1]
            )}`,
          };

          dummyLead.recentStatus &&
            dummyLead.recentStatus.unshift({ ...recentstatus });
        }

        await upsertLead(dummyLead);
        if (!isObjectEmpty(dataFromCallLog)) {
          if (dummyLead.number === dataFromCallLog.data.number) {
            let obj = {
              number: dataFromCallLog && dataFromCallLog.data.number,
              status: 'ACCEPTED',
            };
            await upsertCallLog(obj);
            if (dataFromCallLog.type === 'Called') await getCalled();
            else if (dataFromCallLog.type === 'Missed') await getMissedCalls();
          }
        }

        toastMSG('', 'Lead', dummyLead);
      } else if (title && title.split(' ')[1] === 'OFFICE') {
        if ('id' in officeData && modalType === 'ADD') delete officeData.id;

        let updatedData: any = await upsertOffice(officeData);

        if (officeData.hasOwnProperty('client')) {
          if (officeData.client.hasOwnProperty('value') && updatedData.client) {
            let client: any = [...storedClients];
            let dummyOffice: any = {};
            let clientId: any = getClientId(
              String(updatedData.client.value),
              'id',
              storedClients
            );

            dummyOffice = { label: updatedData.name, value: updatedData.id };
            client[clientId] &&
              client[clientId].offices.push({ ...dummyOffice });

            await upsertClient(client[clientId]);
          }
        }
      } else if (
        title &&
        title.split(' ')[1] === 'CLIENT' &&
        modalType !== 'INVOICE'
      ) {
        await upsertClient(clientData);
        toastMSG('', 'Client', clientData);
      } else if (
        title === 'ADD MEETING ROOM' ||
        modalType === 'SCHEDULE ROOM'
      ) {
        if (meetingRoomData.scheduledClients.length !== 0) {
          let dumyClientData = getById(
            meetingRoomData &&
              meetingRoomData.scheduledClients &&
              meetingRoomData.scheduledClients[
                meetingRoomData.scheduledClients &&
                  meetingRoomData.scheduledClients.length - 1
              ].client.value,
            storedClients
          );
          let ClientData = { ...dumyClientData[0] };

          if (dumyClientData.length !== 0) {
            // ClientData.scheduledMeetingRoom = { ...meetingRoomData };

            let filteredScheduledRooms =
              meetingRoomData.scheduledClients.filter(
                (room: any) => room.client.label === ClientData.name
              );

            ClientData.scheduledMeetingRoom.scheduledClients = [
              ...filteredScheduledRooms,
            ];

            if (
              ClientData.scheduledMeetingRoom &&
              ClientData.scheduledMeetingRoom.scheduledClients
            ) {
              let consumedHoursObj: any;
              let lastSchedule =
                ClientData.scheduledMeetingRoom.scheduledClients[
                  ClientData.scheduledMeetingRoom.scheduledClients.length - 1
                ];
              let hour = 0;
              hour =
                hour +
                getHourBtwTwoTimes(
                  lastSchedule.startDate,
                  lastSchedule.startDate,
                  lastSchedule.startTime,
                  lastSchedule.endTime
                );

              if (
                ClientData.consumedHours &&
                ClientData.consumedHours[lastSchedule.startDate]
              ) {
                consumedHoursObj = {
                  [lastSchedule.startDate]:
                    hour + ClientData.consumedHours[lastSchedule.startDate],
                };
              } else {
                consumedHoursObj = {
                  [lastSchedule.startDate]: hour,
                };
              }

              let newObj = {
                ...ClientData.consumedHours,
                ...consumedHoursObj,
              };
              ClientData.consumedHours = {
                ...newObj,
              };
            }

            await upsertClient(ClientData);
          }
        }

        await upsertMeetingRoom(meetingRoomData);

        // setHideAddSchedule(false);

        toastMSG('', 'Meeting Room', meetingRoomData);
      } else if (modalType === 'INVOICE') {
        let dummyClient = createInvoice(clientData);

        await upsertClient(dummyClient);
        setAdditionalItems([]);
        toast.success('Invoice has Successfully Created');
      } else if (title && title.split(' ')[1] === 'CONTRACT') {
        let stored = storedContracts.filter(
          (item: any) => item.id === contractData.id
        );

        let addendumLength = contractData['addendumContract'].length;
        let dummyData: any;
        if (addendumLength !== 0) {
          dummyData = {
            ...contractData.addendumContract[
              contractData.addendumContract.length - 1
            ],
          };
          dummyData.addendumContract = stored[0].addendumContract;
        } else if (addendumLength === 0) {
          dummyData = { ...contractData };
          if (stored.length > 0) {
            dummyData.addendumContract = stored[0].addendumContract;
          }
        }

        let sharedSeats: any = 0;
        let privateSeats: any = 0;
        let virtualSeats: any = 0;

        let totalSecurity: any = 0;

        dummyData &&
          dummyData.offices.forEach((office: any) => {
            if (dummyData[`${office.label}_sharedSeats`]) {
              sharedSeats =
                sharedSeats + Number(dummyData[`${office.label}_sharedSeats`]);
            } else {
              let filteredOffice = storedOffices.filter(
                (off: any) => off.id === office.value
              );

              if (filteredOffice[0].type.label === 'Private')
                privateSeats = Number(filteredOffice[0].seats) + privateSeats;
              else
                virtualSeats = Number(filteredOffice[0].seats) + virtualSeats;
            }
          });

        if (dummyData.security === '' || dummyData.security === 0) {
          if (
            ((dummyData && dummyData.sharedPrice !== '') ||
              dummyData.sharedPrice !== 0) &&
            ((dummyData && dummyData.privatePrice === '') ||
              dummyData.privatePrice === 0) &&
            ((dummyData && dummyData.virtualPrice === '') ||
              dummyData.virtualPrice === 0)
          ) {
            totalSecurity = Number(dummyData.sharedPrice) * sharedSeats;
          } else if (
            ((dummyData && dummyData.sharedPrice === '') ||
              dummyData.sharedPrice === 0) &&
            ((dummyData && dummyData.privatePrice !== '') ||
              dummyData.privatePrice !== 0) &&
            ((dummyData && dummyData.virtualPrice === '') ||
              dummyData.virtualPrice === 0)
          ) {
            totalSecurity =
              Number(dummyData.privatePrice) * Number(privateSeats);
          } else if (
            ((dummyData && dummyData.sharedPrice !== '') ||
              dummyData.sharedPrice !== 0) &&
            ((dummyData && dummyData.privatePrice !== '') ||
              dummyData.privatePrice !== 0) &&
            ((dummyData && dummyData.virtualPrice !== '') ||
              dummyData.virtualPrice !== 0)
          ) {
            totalSecurity =
              Number(dummyData.sharedPrice) * sharedSeats +
              Number(dummyData.privatePrice) * Number(privateSeats) +
              Number(dummyData.virtualPrice) * Number(virtualSeats);
          } else if (
            ((dummyData && dummyData.sharedPrice === '') ||
              dummyData.sharedPrice === 0) &&
            ((dummyData && dummyData.privatePrice === '') ||
              dummyData.privatePrice === 0) &&
            ((dummyData && dummyData.virtualPrice !== '') ||
              dummyData.virtualPrice !== 0)
          ) {
            totalSecurity =
              Number(dummyData.virtualPrice) * Number(virtualSeats);
          }

          dummyData.security = totalSecurity;
        }
        let isAddendum = false;
        let isSigned = false;
        let isContractSigned: any;
        if (modalType !== 'RENEW CONTRACT') {
          isAddendum = true;
          isContractSigned = storedClients.filter(
            (client: any) =>
              client.contract &&
              client.contract[client.contract.length - 1].value ===
                contractData.id
          );
          if (isContractSigned.length > 0) {
            isSigned = true;
          }
        }
        dummyData.virtualSeats = virtualSeats;
        dummyData.privateSeats = privateSeats;
        dummyData.sharedSeats = sharedSeats;

        if (isAddendum) {
          if (isSigned) {
            let obj: any = { ...isContractSigned[0] };
            let dummyClients: any = [...storedClients];
            let filteredContract = storedContracts.filter(
              (item: any) => item.id === contractData.id
            );
            let currentFilteredAddendum =
              filteredContract[0].addendumContract[
                contractData.addendumContract.length - 1
              ];
            let currentAddendum =
              contractData.addendumContract[
                contractData.addendumContract.length - 1
              ];
            let diff;
            if (contractData.addendumContract.length > 0) {
              diff = get2ArrayofObjectsDiff(
                currentAddendum && currentAddendum.offices,
                currentFilteredAddendum && currentFilteredAddendum.offices,
                'value'
              );
            } else {
              diff = get2ArrayofObjectsDiff(
                dummyData && dummyData.offices,
                filteredContract && filteredContract[0].offices,
                'value'
              );
            }

            let copyContract;
            if (contractData.addendumContract.length > 0) {
              copyContract = {
                ...contractData.addendumContract[
                  contractData.addendumContract.length - 1
                ],
              };
            } else {
              copyContract = { ...dummyData };
            }

            let checking;
            if (contractData.addendumContract.length > 0) {
              checking =
                currentAddendum.startTime !== currentFilteredAddendum.endTime ||
                currentFilteredAddendum.endTime;
            } else {
              checking =
                dummyData.startTime !== filteredContract[0].startTime ||
                dummyData.endTime !== filteredContract[0].endTime;
            }

            if (diff.length !== 0 || checking) {
              if (contractData.addendumContract.length > 0) {
                currentAddendum.offices = currentFilteredAddendum.offices;
                currentAddendum.type = currentFilteredAddendum.type;
                currentAddendum.sharedPrice =
                  currentFilteredAddendum.sharedPrice;
                currentAddendum.security = String(currentAddendum.security);
              } else {
                dummyData.offices = filteredContract[0].offices;
                dummyData.type = filteredContract[0].type;
                dummyData.sharedPrice = filteredContract[0].sharedPrice;
              }

              if (contractData.addendumContract.length > 0) {
                copyContract.id = `addendum-${
                  contractData.addendumContract.length + 1
                }`;
              } else {
                copyContract.id = `addendum-1`;
              }

              dummyData = { ...stored[0] };
              dummyData.addendumContract.push({ ...copyContract });

              let addendumContract =
                dummyData.addendumContract[
                  dummyData.addendumContract.length - 1
                ];
              if (diff.length > 0) {
                addendumContract.security = String(addendumContract.security);
                // let token: any = Cookie.get('jwt');
                // let decoded: any = jwt_decode(token);
                // addendumContract.creater = decoded.email.split('@')[0];
              }

              dummyData.addendumContract[
                dummyData.addendumContract.length - 1
              ].addendumContract = [];

              let filteredClient = dummyClients.filter(
                (client: any) =>
                  client.name === contractData.contractorName &&
                  client.email === contractData.email
              );
              let arr: any = [...filteredClient[0].contract];

              if (filteredClient.length === 0) {
                let contractObj: any = {
                  label:
                    dummyData.contractorName + '-' + (obj.contract.length + 1),
                  value: dummyData.id,
                };

                arr.push({ ...contractObj });
                obj.contract = arr;
              }
              obj.name = dummyData.contractorName;
              obj.companyName = dummyData.companyName;
              obj.type = addendumContract.type;
              obj.email = dummyData.email;
              obj.number = dummyData.number;
              obj.offices = addendumContract.offices;
              obj.privatePrice = addendumContract.privatePrice;
              obj.sharedPrice = addendumContract.sharedPrice;

              if (dummyData.addendumContract.length > 0) {
                for (const office of addendumContract.offices) {
                  if (
                    addendumContract.hasOwnProperty(
                      `${office.label}_sharedSeats`
                    )
                  ) {
                    obj[`${office.label}_sharedSeats`] =
                      addendumContract[`${office.label}_sharedSeats`];
                  }
                }
              } else {
                for (const office of dummyData.offices) {
                  obj[`${office.label}_sharedSeats`] =
                    dummyData[`${office.label}_sharedSeats`];
                }
              }

              obj.security = dummyData.security;
              obj.totalSeatsForSecurity = dummyData.totalSeatsForSecurity;

              if (dummyData.hasOwnProperty('_id')) {
                delete dummyData._id;
              }
              // dummyData.privateSeats = privateSeats;
              // dummyData.sharedSeats = sharedSeats;
              obj.privateSeats = privateSeats;
              obj.sharedSeats = sharedSeats;
             
              Promise.allSettled([
                await upsertClient(obj),
                await upsertContract(dummyData),
              ]);
            }
          } else {
            dummyData.contractId = dummyData.contractorName + '-' + 1;
           
            await upsertContract(dummyData);
          }
        } else {
          if (dummyData.hasOwnProperty('id')) {
            delete dummyData.id;
          }

          let id = dummyData.contractId.split('-')[1];

          dummyData.isSigned = 'No';
          dummyData.contractId = dummyData.contractorName + '-' + ++id;


          await upsertContract(dummyData);
        }
        if (modalType === 'RENEW CONTRACT') {
          toast.success('New Contract Has been Created for Renewal');
        } else toastMSG('', 'Contract', dummyData);
      }
      setShow(false);
      setActionPending(false);
    } catch (e) {
      toast.error('An error occured');
      // alert('An error occured while creating page');
      console.error(e);
    }
  };

  const handleDelete = async (rowId: string) => {
    try {
      setActionPending(true);
      if (title.split(' ')[1] === 'LEAD') {
        await deleteLead(rowId);
        toastMSG('delete', 'Lead');
      } else if (title.split(' ')[1] === 'OFFICE') {
        await deleteOffice(rowId);
        toastMSG('delete', 'Office');
      } else if (title.split(' ')[1] === 'CLIENT') {
        let roomIndex = 0;
        let dummyRooms = [...storedMeetingRooms];
        for (let i = 0; i < dummyRooms.length; i++) {
          for (let j = 0; j < dummyRooms[i].scheduledClients.length; j++) {
            if (dummyRooms[i].scheduledClients[j].client.value === rowId) {
              dummyRooms[i].scheduledClients.splice(j, 1);
              roomIndex = i;
            }
          }
        }

        await deleteClient(rowId);
        toastMSG('delete', 'Client');

        if (dummyRooms[roomIndex])
          await upsertMeetingRoom(dummyRooms[roomIndex]);
      } else if (title.split(' ')[1] === 'MEETING') {
        await deleteMeetingRoom(rowId);
        toastMSG('delete', 'Meeting Room');
      } else if (title.split(' ')[1] === 'CONTRACT') {
        await deleteContract(rowId);
        toastMSG('delete', 'Contract');
      }
      setShow(false);
      setActionPending(false);
    } catch (e) {
      toast.error('an Error occured while Deleting ');
      console.error(e);
    }
  };

  const setSharedSeats = useCallback(
    (type: any, option: any, title: any, modalType: any) => {
      let opt: any = {};
      let options: any = [];

      if (type === 'type' || type === 'type2') {
        let dummyData: any;

        if (title === 'ADD CLIENT' || modalType === 'EDIT CLIENT') {
          dummyData = { ...clientData };
        } else if (title === 'ADD CONTRACT' || modalType === 'EDIT CONTRACT') {
          if (contractData.addendumContract.length > 0) {
            let addendum = {
              ...contractData.addendumContract[
                contractData.addendumContract.length - 1
              ],
            };

            dummyData = { ...addendum };
          } else dummyData = { ...contractData };
        }

        if (option.length === 0) {
          dummyData.offices.forEach((office: any) => {
            dummyData[`${office.label}_sharedSeats`] = '';
          });
          dummyData.offices = [];

          if (title === 'ADD CLIENT' || modalType === 'EDIT CLIENT') {
            setClientData((values: any) => ({
              ...values,
              ...dummyData,
              [type]: [...option],
            }));
          } else if (
            title === 'ADD CONTRACT' ||
            modalType === 'EDIT CONTRACT'
          ) {
            if (contractData.addendumContract.length > 0) {
              let addendum = {
                ...contractData.addendumContract[
                  contractData.addendumContract.length - 1
                ],
              };
              let addendumArr = [...contractData.addendumContract];
              addendum = { ...dummyData };
              addendumArr[addendumArr.length - 1] = addendum;

              setContractData((values: any) => ({
                ...values,
                addendumContract: addendumArr,
              }));
            } else
              setContractData((values: any) => ({
                ...values,
                ...dummyData,
                [type]: [...option],
              }));
          }
        } else {
          if (title === 'ADD CLIENT' || modalType === 'EDIT CLIENT') {
            setClientData((values: any) => ({
              ...values,
              [type]: [...option],
            }));
          } else if (
            title === 'ADD CONTRACT' ||
            modalType === 'EDIT CONTRACT'
          ) {
            if (contractData.addendumContract.length > 0) {
              let addendum = {
                ...contractData.addendumContract[
                  contractData.addendumContract.length - 1
                ],
              };
              let addendumArr = [...contractData.addendumContract];
              addendum[type] = [...option];
              addendumArr[addendumArr.length - 1] = addendum;

              setContractData((values: any) => ({
                ...values,
                addendumContract: addendumArr,
              }));
            } else
              setContractData((values: any) => ({
                ...values,
                [type]: [...option],
              }));
          }
        }
      } else {
        let dummy: any;
        if (title === 'ADD CLIENT' || modalType === 'EDIT CLIENT') {
          dummy = { ...clientData };
        } else if (title === 'ADD CONTRACT' || modalType === 'EDIT CONTRACT') {
          if (contractData.addendumContract.length > 0) {
            let addendum = {
              ...contractData.addendumContract[
                contractData.addendumContract.length - 1
              ],
            };

            dummy = { ...addendum };
          } else dummy = { ...contractData };
        }

        if (option.length < dummy.offices.length) {
          let dummyData: any = { ...dummy };
          let filtered: any = [];
          dummy.offices &&
            dummy.offices.forEach((item: any) => {
              for (let i = 0; i < option.length; i++) {
                if (item.label !== option[i].label) {
                  filtered.push(item.label);
                }
              }
            });

          let removedItem = `${filtered[0]}_sharedSeats`;
          if (filtered.length !== 0) {
            if (title === 'ADD CLIENT' || modalType === 'EDIT CLIENT') {
              setClientData((values: any) => ({
                ...values,
                [removedItem]: '',
                offices: [...option],
              }));
            } else if (
              title === 'ADD CONTRACT' ||
              modalType === 'EDIT CONTRACT'
            ) {
              if (contractData.addendumContract.length > 0) {
                let addendum = {
                  ...contractData.addendumContract[
                    contractData.addendumContract.length - 1
                  ],
                };
                let addendumArr = [...contractData.addendumContract];
                addendum['offices'] = [...option];
                addendum['removedItem'] = '';

                addendumArr[addendumArr.length - 1] = addendum;

                setContractData((values: any) => ({
                  ...values,
                  addendumContract: addendumArr,
                }));
              } else
                setContractData((values: any) => ({
                  ...values,
                  [removedItem]: '',
                  offices: [...option],
                }));
            }
          } else {
            let dummy: any;
            if (title === 'ADD CLIENT' || modalType === 'EDIT CLIENT') {
              dummy = { ...clientData };
            } else if (
              title === 'ADD CONTRACT' ||
              modalType === 'EDIT CONTRACT'
            ) {
              if (contractData.addendumContract.length > 0) {
                let addendum = {
                  ...contractData.addendumContract[
                    contractData.addendumContract.length - 1
                  ],
                };

                dummy = { ...addendum };
              } else dummy = { ...contractData };
            }

            dummy.offices.forEach((office: any) => {
              dummyData[`${office.label}_sharedSeats`] = '';
            });
            if (title === 'ADD CLIENT' || modalType === 'EDIT CLIENT') {
              setClientData((values: any) => ({
                ...values,
                ...dummyData,
                offices: [...option],
              }));
            } else if (
              title === 'ADD CONTRACT' ||
              modalType === 'EDIT CONTRACT'
            ) {
              if (contractData.addendumContract.length > 0) {
                let addendum = {
                  ...contractData.addendumContract[
                    contractData.addendumContract.length - 1
                  ],
                };
                let addendumArr = [...contractData.addendumContract];
                addendum = { ...dummyData };
                addendumArr[addendumArr.length - 1] = addendum;

                setContractData((values: any) => ({
                  ...values,
                  addendumContract: addendumArr,
                }));
              } else
                setContractData((values: any) => ({
                  ...values,
                  ...dummyData,
                  offices: [...option],
                }));
            }
          }
        } else {
          for (let i = 0; i < option.length; i++) {
            opt['label'] = option[i].label;
            opt['value'] = option[i].value;

            options.push({ ...opt });
          }

          if (title === 'ADD CLIENT' || modalType === 'EDIT CLIENT') {
            setClientData((values: any) => ({
              ...values,
              offices: [...options],
            }));
          } else if (
            title === 'ADD CONTRACT' ||
            modalType === 'EDIT CONTRACT'
          ) {
            if (contractData.addendumContract.length > 0) {
              let addendum = {
                ...contractData.addendumContract[
                  contractData.addendumContract.length - 1
                ],
              };
              let addendumArr = [...contractData.addendumContract];
              addendum['offices'] = [...options];
              addendumArr[addendumArr.length - 1] = addendum;

              setContractData((values: any) => ({
                ...values,
                addendumContract: addendumArr,
              }));
            } else
              setContractData((values: any) => ({
                ...values,
                offices: [...options],
              }));
          }
        }
      }
    },
    [clientData, contractData]
  );
  useEffect(() => {
    if (title === 'ADD CONTRACT' || modalType === 'EDIT CONTRACT') {
      let dummyData = { ...contractData };
      let seats: any = 0;

      contractData &&
        contractData.offices.forEach((office: any) => {
          let getOffice = findOfficeById(office.value, storedOffices);
          if (getOffice[0]) {
            if (getOffice[0].type && getOffice[0].type.label === 'Private') {
              seats = seats + Number(getOffice[0].seats);
            }
          }
        });
      dummyData.privateSeats = String(seats);
    }
  }, [contractData, modalType, storedOffices, title]);

  const getLeadById = useCallback(
    (id: string) => {
      return storedLeads.filter((item: any) => item.id === id);
    },
    [storedLeads]
  );

  const disabledContractOnAddendumOptionChange = useCallback(
    (check: boolean) => {
      if (!check) {
        setDisabledContract(true);
      } else {
        setDisabledContract(false);
      }
    },
    []
  );

  const handleOptionChange = useCallback(
    (option: any, index: any, type: string) => {
      if (title === 'ADD LEAD' || modalType === 'EDIT LEAD') {
        if (type === 'status') {
          let dummyStatus: any;
          let Data: any = { ...leadData };
          let lead: any;
          if (leadData.hasOwnProperty('id')) {
            lead = getLeadById(Data['id']);
            dummyStatus = lead[0].status;
            if (
              option &&
              option.value !== '' &&
              dummyStatus &&
              dummyStatus.value !== option.value
            ) {
              setLeadData((values: any) => ({
                ...values,

                statusLastUpdatedDate: new Date()
                  .toISOString()
                  .substring(0, 10),
                statusLastUpdatedTime: new Date()
                  .toTimeString()
                  .substring(0, 5),
              }));
            } else if (
              (option && option === null) ||
              leadData.status === 'New'
            ) {
              setLeadData((values: any) => ({
                ...values,
                statusLastUpdatedDate: '',
                statusLastUpdatedTime: '',
              }));
            } else if (option && option.value === dummyStatus.value) {
              setLeadData((values: any) => ({
                ...values,
                statusLastUpdatedDate: lead[0].statusLastUpdatedDate,
                statusLastUpdatedTime: lead[0].statusLastUpdatedTime,
              }));
            }
          } else {
            setLeadData((values: any) => ({
              ...values,
              statusLastUpdatedDate: new Date().toISOString().substring(0, 10),
              statusLastUpdatedTime: new Date().toTimeString().substring(0, 5),
            }));
          }
        }

        setLeadData((values: any) => ({
          ...values,
          [type]: { ...option },
        }));
      } else if (title === 'ADD CLIENT' || modalType === 'EDIT CLIENT') {
        setSharedSeats(type, option, title, modalType);
      } else if (title === 'ADD OFFICE' || modalType === 'EDIT OFFICE') {
        if (type === 'type') {
          setOfficeData((values: any) => ({
            ...values,
            type: { ...option },
          }));
        } else {
          setOfficeData((values: any) => ({
            ...values,
            client: { ...option },
          }));
        }

        if (modalType === 'EDIT OFFICE') updateClientOnSelectChange(option);
      } else if (
        title === 'ADD MEETING ROOM' &&
        modalType === 'SCHEDULE ROOM'
      ) {
        let dummy: any = [...meetingRoomData.scheduledClients];
        dummy[parseInt(index)]['client'] = option;

        setMeetingRoomDate((values: any) => ({
          ...values,
          scheduledClients: [...dummy],
        }));
      } else if (title === 'ADD CONTRACT' || modalType === 'EDIT CONTRACT') {
        setSharedSeats(type, option, title, modalType);
      }

      // console.log('title and modalType', title, modalType);
    },
    [
      getLeadById,
      leadData,
      meetingRoomData.scheduledClients,
      modalType,
      setSharedSeats,
      title,
      updateClientOnSelectChange,
    ]
  );

  const onsqftChange = (e: any) => {
    const value = Number(e.target.value) / 25;
    setLeadData((values: any) => ({
      ...values,
      seats: value,
    }));
  };

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, type: string) => {
      if (type === 'Lead') {
        let value: any = e.target.value;
        let Id: any = e.target.id;
        let dummyNote: any;

        let lead: any;
        if (leadData.id) {
          lead = getLeadById(leadData.id);
          dummyNote = lead[0].notes;
        }
        if (Id === 'notes' && value !== '' && dummyNote !== value) {
          setLeadData((values: any) => ({
            ...values,

            notesLastUpdatedDate: new Date().toISOString().substring(0, 10),
            notesLastUpdatedTime: new Date().toTimeString().substring(0, 5),
          }));
        } else if (Id === 'notes' && value === '') {
          setLeadData((values: any) => ({
            ...values,
            notesLastUpdatedDate: '',
            notesLastUpdatedTime: '',
          }));
        } else {
          setLeadData((values: any) => ({
            ...values,
            notesLastUpdatedDate: leadData.notesLastUpdatedDate,
            notesLastUpdatedTime: leadData.notesLastUpdatedTime,
          }));
        }
        setLeadData((values: any) => ({
          ...values,
          [Id]: Id === 'seats' ? Number(value) : value,
        }));
      } else if (type === 'Office') {
        setOfficeData((values: any) => ({
          ...values,
          [e.target.id]: e.target.value,
        }));
      } else if (type === 'Client') {
        setClientData((values: any) => ({
          ...values,
          [e.target.id]: e.target.value,
        }));
      } else if (
        title === 'ADD MEETING ROOM' &&
        modalType !== 'SCHEDULE ROOM'
      ) {
        setMeetingRoomDate((values: any) => ({
          ...values,
          [e.target.id]: e.target.value,
        }));
      } else if (
        title === 'ADD MEETING ROOM' &&
        modalType === 'SCHEDULE ROOM'
      ) {
        let index: any = e.target.id.split(' ')[1];
        let targetId = e.target.id.split(' ')[0];
        let dummy: any = [...meetingRoomData.scheduledClients];

        dummy[parseInt(index)][targetId] = e.target.value;
        setMeetingRoomDate((values: any) => ({
          ...values,
          scheduledClients: [...dummy],
        }));
        setElementIndex(index);
      } else if (e.target.id === 'delete') {
        let index: any = e.target.id.split(' ')[1];
        let dummy: any = [...meetingRoomData.scheduledClients];
        dummy.splice(parseInt(index), 1);

        setMeetingRoomDate((values: any) => ({
          ...values,
          scheduledClients: [...dummy],
        }));
      } else if (type === 'Contract') {
        if (e.target.id === 'duration') {
          setContractData((values: any) => ({
            ...values,
            [e.target.id]: e.target.value,
            startDate: new Date().toISOString().substring(0, 10),
            endDate: dateWithMonthsDelay(Number(e.target.value)),
          }));
        } else {
          if (contractData.addendumContract.length > 0) {
            let addendum = {
              ...contractData.addendumContract[
                contractData.addendumContract.length - 1
              ],
            };
            let addendumArr = [...contractData.addendumContract];
            addendum[e.target.id] = e.target.value;
            addendumArr[addendumArr.length - 1] = addendum;

            setContractData((values: any) => ({
              ...values,
              addendumContract: addendumArr,
            }));
          } else {
            setContractData((values: any) => ({
              ...values,
              [e.target.id]: e.target.value,
            }));
          }
        }
      }
    },
    [
      contractData.addendumContract,
      getLeadById,
      leadData.id,
      leadData.notesLastUpdatedDate,
      leadData.notesLastUpdatedTime,
      meetingRoomData.scheduledClients,
      modalType,
      title,
    ]
  );

  useEffect(() => {
    if (contractData.startDate !== '' && contractData.endDate) {
      let duration = monthDiff(
        new Date(
          Number(contractData.startDate.split('-')[0]),
          Number(contractData.startDate.split('-')[1])
        ),
        new Date(
          Number(contractData.endDate.split('-')[0]),
          Number(contractData.endDate.split('-')[1])
        )
      );
      setContractData((values: any) => ({
        ...values,
        duration: duration,
      }));
    }
  }, [contractData.startDate, contractData.endDate]);

  const handleClick = (e: any) => {
    if (e.target.id === 'add_room') {
      let dummy: any = [...meetingRoomData.scheduledClients];
      let obj = scheduledClients[0];

      dummy.push({ ...obj });

      for (const clientObj of dummy) {
        if (
          clientObj.endTime === '' ||
          clientObj.startDate === '' ||
          clientObj.startTime === '' ||
          (clientObj.client && clientObj.client.label === '') ||
          (clientObj.client && clientObj.client.value === '')
        ) {
          setValidateSchedule(true);
          break;
        }
      }

      setMeetingRoomDate((values: any) => ({
        ...values,
        scheduledClients: [...dummy],
      }));
    }
  };

  useEffect(() => {
    let room = getById(meetingRoomData['id'], storedMeetingRooms);
    if (
      room &&
      room[0] &&
      room[0].scheduledClients.length + 1 ===
        meetingRoomData.scheduledClients.length
    ) {
      setHideAddSchedule(true);
    }
  }, [meetingRoomData, storedMeetingRooms]);

  const handleScheduleRowDelete = useCallback(
    (item: any, index: string, type: string) => {
      if (type === 'DELETE SCHEDULE ROOM') {
        let dummy: any = [...meetingRoomData.scheduledClients];
        dummy.splice(parseInt(index), 1);

        for (const clientObj of dummy) {
          if (
            clientObj.endDate === '' ||
            clientObj.endTime === '' ||
            clientObj.startDate === '' ||
            clientObj.startTime === '' ||
            (clientObj.client && clientObj.client.label === '') ||
            (clientObj.client && clientObj.client.value === '')
          ) {
          } else {
            setValidateSchedule(false);
            break;
          }
        }

        let fulteredClient = storedClients.filter(
          (client: any) => client.id === item.client.value
        );
        let index1 =
          fulteredClient[0] &&
          fulteredClient[0].scheduledMeetingRoom.scheduledClients.findIndex(
            (client: any) =>
              client.endTime === item.endTime &&
              client.startDate === item.startDate &&
              client.startTime === item.startTime
          );
        if (fulteredClient[0] === undefined || index1 === -1) {
          setMeetingRoomDate((values: any) => ({
            ...values,
            scheduledClients: [...dummy],
          }));
          setHideAddSchedule(false);
        } else {
          setBeforeDeleteMeetingRoom(dummy);
          setOptionalData({ item, storedClients });
          setShowDeleteModal(true);
          setRowID(item.client.value);
          setTitleForDelete('DELETE SCHEDULE ROOM');
        }
      } else if (type === 'DELETE INVOICE') {
        setShowDeleteModal(true);
        setRowID(index);
        setTitleForDelete('DELETE INVOICE');
        setOptionalData(item);
      }
    },
    [meetingRoomData.scheduledClients, storedClients]
  );

  const afterDeleteCallback = useCallback(() => {
    let copyMeetingRoom = { ...meetingRoomData };
    copyMeetingRoom.scheduledClients = [...beforeDeleteMeetingRoom];
    (async function () {
      await upsertMeetingRoom(copyMeetingRoom);
    })();

    setMeetingRoomDate((values: any) => ({
      ...values,
      scheduledClients: [...beforeDeleteMeetingRoom],
    }));
  }, [beforeDeleteMeetingRoom, meetingRoomData]);

  const fetchAPI = useCallback(
    async (rowId: any) => {
      try {
        if (modalType === 'EDIT LEAD' || modalType === 'SHOW LEAD') {
          let getData: any = await getLead(rowId);
          setLeadData(getData);
        } else if (modalType === 'EDIT OFFICE' || modalType === 'SHOW OFFICE') {
          let getData: any = await getOffice(rowId);
          setOfficeData(getData);
        } else if (
          modalType === 'EDIT CLIENT' ||
          modalType === 'SHOW CLIENT' ||
          modalType === 'INVOICE'
        ) {
          let getData: any = await getClient(rowId);

          setClientData(getData);
        } else if (
          title === 'ADD MEETING ROOM' ||
          modalType === 'SCHEDULE ROOM' ||
          modalType === 'SHOW SCHEDULE ROOM'
        ) {
          let getData: any = await getMeetingRoom(rowId);
          setMeetingRoomDate(getData);
        } else if (
          modalType === 'EDIT CONTRACT' ||
          modalType === 'SHOW CONTRACT' ||
          modalType === 'RENEW CONTRACT'
        ) {
          let getData: any = await getContract(rowId);

          if (modalType === 'RENEW CONTRACT') {
            if (getData.addendumContract.length > 0) {
              let addendumContract =
                getData.addendumContract[getData.addendumContract.length - 1];
              addendumContract.sharedPrice =
                Number(addendumContract.sharedPrice) +
                (10 / 100) * addendumContract.sharedPrice;
              addendumContract.privatePrice =
                Number(addendumContract.privatePrice) +
                (10 / 100) * addendumContract.privatePrice;
              addendumContract.virtualPrice =
                Number(addendumContract.virtualPrice) +
                (10 / 100) * addendumContract.virtualPrice;

              setContractData(addendumContract);
            } else {
              getData.sharedPrice =
                Number(getData.sharedPrice) + (10 / 100) * getData.sharedPrice;
              getData.privatePrice =
                Number(getData.privatePrice) +
                (10 / 100) * getData.privatePrice;
              getData.virtualPrice =
                Number(getData.virtualPrice) +
                (10 / 100) * getData.virtualPrice;

              setContractData(getData);
            }
          } else {
            setContractData(getData);
          }
        }
      } catch (e: any) {
        console.log(e);
      }
    },
    [modalType, title]
  );

  const handleClose = () => {
    setShow(false);
    setShowDeleteModal(false);
    setOfficeAlreadyAssigned('');
    setClientData(ClientFormObj);
    setContractData(ContractFormObj);
    setHideAddSchedule(false);
    setDisabledContract(false);
    setActionPending(false);
    setAdditionalItems([]);
  };
  const handleCloseForDelete = () => {
    setShowDeleteModal(false);
  };

  const pageModal = () => {
    if (
      title === 'ADD LEAD' ||
      modalType === 'EDIT LEAD' ||
      modalType === 'SHOW LEAD'
    ) {
      return (
        <LeadForm
          modalType={modalType}
          handleChange={handleChange}
          leadData={leadData}
          handleOptionChange={handleOptionChange}
          onsqftChange={onsqftChange}
        />
      );
    } else if (title === 'ADD CLIENT' && modalType !== 'INVOICE') {
      return (
        <ClientForm
          modalType={modalType}
          handleChange={handleChange}
          clientData={clientData}
          handleOptionChange={handleOptionChange}
        />
      );
    } else if (title === 'ADD OFFICE' || modalType === 'SHOW OFFICE') {
      return (
        <OfficeForm
          modalType={modalType}
          handleChange={handleChange}
          officeData={officeData}
          handleOptionChange={handleOptionChange}
          officeAlreadyAssigned={officeAlreadyAssigned}
          rowId={rowId}
        ></OfficeForm>
      );
    } else if (
      title === 'ADD MEETING ROOM' &&
      modalType !== 'SCHEDULE ROOM' &&
      modalType !== 'SHOW SCHEDULE ROOM'
    ) {
      return (
        <MeetingRoomForm
          modalType={modalType}
          handleChange={handleChange}
          meetingRoomData={meetingRoomData}
        ></MeetingRoomForm>
      );
    } else if (
      (title === 'ADD MEETING ROOM' && modalType === 'SCHEDULE ROOM') ||
      modalType === 'SHOW SCHEDULE ROOM'
    ) {
      return (
        <ScheduleRoomForm
          modalType={modalType}
          handleChange={handleChange}
          handleOptionChange={handleOptionChange}
          meetingRoomData={meetingRoomData}
          validateElement={validateElement}
          validateElement2={validateElement2}
          handleScheduleRowDelete={handleScheduleRowDelete}
        ></ScheduleRoomForm>
      );
    } else if (modalType === 'INVOICE') {
      return (
        <InvoiceForm
          modalType={modalType}
          handleChange={handleChange}
          clientData={clientData}
          handleOptionChange={handleOptionChange}
          invoiceMonthCallback={invoiceMonthCallback}
          handleScheduleRowDelete={handleScheduleRowDelete}
          renderAddItemModalCallback={renderAddItemModalCallback}
        ></InvoiceForm>
      );
    } else if (title === 'ADD CONTRACT' || modalType === 'SHOW CONTRACT') {
      return (
        <ContractForm
          modalType={modalType}
          handleChange={handleChange}
          contractData={contractData}
          handleOptionChange={handleOptionChange}
          disabledContractOnAddendumOptionChange={
            disabledContractOnAddendumOptionChange
          }
        ></ContractForm>
      );
    }
    return null;
  };

  return (
    <>
      {renderCustomModal === true && (
        <CustomModal
          showModal={renderCustomModal}
          handleCloseOuterModal={handleClose}
          renderModalFromChild={renderModalFromChild}
        />
      )}

      {renderAddItemModal && (
        <AddItemInvoiceModal
          showModal={renderAddItemModal}
          renderAddItemModalCallback={renderAddItemModalCallback}
          clientData={clientData}
          additionalItems={additionalItems}
          additionalItemCallback={additionalItemCallback}
        />
      )}

      {modalType !== 'Delete' && show === true ? (
        <Modal
          show={show}
          onHide={handleClose}
          size='lg'
          onEntering={() => {
            if (rowId !== '') {
              if (modalType === 'SCHEDULE ROOM') {
                setHideAddSchedule(false);
              }
              fetchAPI(rowId);
            }
            if (modalType === 'ADD' && title === 'ADD CONTRACT') {
              let token: any = Cookie.get('jwt');
              let decoded: any = jwt_decode(token);
              setContractData((values: any) => ({
                ...values,
                creater: capitalizeFirstLetter(decoded.email.split('@')[0]),
              }));
            }
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {modalType && modalType === title && title.split(' ')[0]
                ? title
                : modalType}
            </Modal.Title>
            {modalType && modalType === 'SCHEDULE ROOM' && !hideAddSchedule && (
              <FaPlusCircle
                style={{ fontSize: '20px', marginLeft: '20px' }}
                onClick={(e) => handleClick(e)}
                id='add_room'
              />
            )}
          </Modal.Header>
          <ModalInnerWrapper onSubmit={handleSave}>
            <Modal.Body
              style={{
                background:
                  modalType === 'SHOW LEAD' ||
                  modalType === 'SHOW CLIENT' ||
                  modalType === 'SHOW OFFICE' ||
                  modalType === 'SHOW SCHEDULE ROOM'
                    ? 'whiteSmoke'
                    : 'none',
              }}
            >
              {pageModal()}
            </Modal.Body>
            {modalType && modalType.split(' ')[0] !== 'SHOW' && (
              <Modal.Footer>
                <Button
                  variant='secondary'
                  onClick={handleClose}
                  style={{
                    height: '38px',
                    borderRadius: '12px',
                    fontSize: '0.8rem',
                    width: '98px',
                  }}
                >
                  Close
                </Button>

                <TableButton
                  type='submit'
                  style={{
                    pointerEvents:
                      disabledContract ||
                      validateElement !== '' ||
                      validateElement2 !== '' ||
                      officeAlreadyAssigned !== '' ||
                      isActionPending
                        ? // ||
                          // validateSchedule
                          'none'
                        : 'auto',
                    background:
                      disabledContract ||
                      validateElement !== '' ||
                      validateElement2 !== '' ||
                      officeAlreadyAssigned !== '' ||
                      isActionPending
                        ? //  ||
                          // validateSchedule
                          'lightgrey'
                        : 'cadetblue',
                  }}
                >
                  {modalType === 'INVOICE' ? 'Create Invoice' : 'Save Changes'}
                </TableButton>
              </Modal.Footer>
            )}
          </ModalInnerWrapper>
        </Modal>
      ) : (
        <Modal
          show={show}
          onHide={handleClose}
          onEntered={(e) => {
            if (title.split(' ')[1] === 'OFFICE') {
              hasClientInOffice();
            }
          }}
          onExited={(e) => {
            setValidateElement('');
            setValidateElement2('');
          }}
        >
          <Modal.Body
            style={{
              width: modalType === 'SCHEDULE ROOM' ? '800px' : '100%',
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <TiWarningOutline
                style={{ fontSize: '7rem', color: '#f7c220' }}
              />
              <label style={{ textAlign: 'center' }}>
                {officeAlreadyAssigned !== ''
                  ? officeAlreadyAssigned
                  : ` Are you Sure? You want to Delete ${
                      title && title.split(' ')[1]
                    }`}
              </label>
            </div>
          </Modal.Body>
          <Modal.Footer style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              variant='secondary'
              onClick={handleClose}
              style={{
                height: '38px',
                borderRadius: '12px',
                fontSize: '0.8rem',
                width: '98px',
              }}
            >
              Close
            </Button>

            <TableButton
              onClick={() => handleDelete(rowId)}
              style={{
                pointerEvents:
                  isActionPending || officeAlreadyAssigned !== ''
                    ? 'none'
                    : 'auto',
                background:
                  isActionPending || officeAlreadyAssigned !== ''
                    ? 'lightgrey'
                    : 'cadetblue',
              }}
            >
              Delete
            </TableButton>
          </Modal.Footer>
        </Modal>
      )}
      <ToastContainer autoClose={2000} />
      {showDeleteModal === true && (
        <DeleteModal
          rowId={rowID}
          title={titleforDelete}
          show={showDeleteModal}
          handleClose={handleCloseForDelete}
          optionalData={optionalData}
          afterDeleteCallback={afterDeleteCallback}
          handleCloseOuterModal={handleClose}
        />
      )}
    </>
  );
});

export default ReactModal;

const CustomModal = ({
  showModal,
  handleCloseOuterModal,
  renderModalFromChild,
}: any) => {
  const [show, setShow] = useState(showModal);
  const [currentPaid, setCurrentPaid] = useState(false);
  const [customPaid, setCustomPaid] = useState('');
  const Data = store.get<any>('isPaidData', {});

  const handleClose = () => {
    renderModalFromChild();
    setShow(false);
  };
  const handleChange = (e: any) => {
    if (e.target.id === 'currentPaid') {
      setCurrentPaid(e.target.checked);
    } else if (e.target.id === 'customPaid') {
      setCustomPaid(e.target.value);
    }
  };

  const setDate = async () => {
    let dummyRow: any = { ...Data.row };
    let dummyParent: any = { ...Data.parentData };

    if (dummyRow.hasOwnProperty('_id')) {
      delete dummyRow._id;
    }

    const index =
      dummyParent.invoices &&
      dummyParent.invoices.findIndex(
        (item: any) => item.invoiceId === dummyRow.invoiceId
      );
    dummyParent.invoices[index].isPaid = 'Yes';
    if (currentPaid !== false) {
      dummyParent.invoices[index].paymentDate = new Date()
        .toISOString()
        .substring(0, 10)
        .split('-')
        .reverse()
        .join('/');
    } else if (customPaid !== '') {
      dummyParent.invoices[index].paymentDate = customPaid
        .split('-')
        .reverse()
        .join('/');
    }
    handleCloseOuterModal();

    await upsertClient(dummyParent);

    store.set('showCustom', false);
    setShow(false);
    toast.success('Invoice has been Paid');
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        onExited={(e) => {
          store.set('showCustom', false);
          setShow(false);
        }}
        style={{
          position: 'fixed',
          width: 'auto',
          right: ' calc(-50vw + 50%)',
          left: 'calc(-50vw + 50%)',
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Set Current or Custom Paid Date</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div key='currentPaid'>
              <label style={{ fontWeight: '600', paddingRight: '10px' }}>
                Click to Set Current Paid Date
              </label>
              <input
                type='checkBox'
                disabled={customPaid !== ''}
                id='currentPaid'
                checked={currentPaid === true ? true : false}
                onChange={(e) => handleChange(e)}
              ></input>
            </div>
            <InputWrapper key='customPaid'>
              <label>Click to Set Custom Paid Date</label>
              <input
                type='date'
                id='customPaid'
                disabled={currentPaid !== false}
                onChange={(e) => handleChange(e)}
              ></input>
            </InputWrapper>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleClose}>
            Close
          </Button>
          <Button
            variant='primary'
            id='save'
            disabled={currentPaid === false && customPaid === ''}
            onClick={setDate}
          >
            Set Paid Date
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
