import { navItems } from '../../consts/navItems';
import Routes from '../../Routes';
import {
  FaUserFriends,
  FaDoorOpen,
  FaRoad,
  FaUsersCog,
  FaFileContract,
} from 'react-icons/fa';
import { MdPhoneCallback, MdWifiCalling } from 'react-icons/md';
import { HiUserGroup, HiPhoneMissedCall } from 'react-icons/hi';
import { HashRouter } from 'react-router-dom';
import { Nav, StyledNavLink, NavHeader, FlexLabelIcon } from './Navbar.styles';

export default function Navbar() {
  return (
    <>
      <HashRouter>
        <Nav id='navbar'>
          <FlexLabelIcon style={{ borderBottom: '1px solid white' }}>
            <FaUsersCog />
            <NavHeader>PAXICO CRM</NavHeader>
          </FlexLabelIcon>

          {navItems &&
            navItems.map((item) => (
              <StyledNavLink to={item.to} key={item.id}>
                <FlexLabelIcon>
                  {item.title === 'Leads' ? <FaRoad /> : null}
                  {item.title === 'Offices' ? <FaDoorOpen /> : null}
                  {item.title === 'Clients' ? <FaUserFriends /> : null}
                  {item.title === 'Meeting Rooms' ? <HiUserGroup /> : null}
                  {item.title === 'Contracts' ? <FaFileContract /> : null}
                  {item.title === 'Call Logs' ? <MdPhoneCallback /> : null}
                  &nbsp;
                  {item.title}
                </FlexLabelIcon>

                {item &&
                  item.subMenu &&
                  item.subMenu.map((subItem: any) => (
                    <StyledNavLink to={subItem.to} key={subItem.id}>
                      <FlexLabelIcon style={{ paddingLeft: '60px' }}>
                        {subItem.title === 'Missed Call' ? (
                          <HiPhoneMissedCall />
                        ) : null}
                        {subItem.title === 'Answered Calls' ? (
                          <MdWifiCalling />
                        ) : null}
                        &nbsp;
                        {subItem.title}
                      </FlexLabelIcon>
                    </StyledNavLink>
                  ))}
              </StyledNavLink>
            ))}
        </Nav>

        <Routes />
      </HashRouter>
    </>
  );
}
