import store from '@jitcoder/usestore';
import HTTP from './http';
import Office, { isOffice } from '../models/office';

export const getOffices = async () => {
  const offices = await HTTP.get<Office[]>('/paxico/offices');
  store.set('offices', offices);
  return offices;
};

export const getOffice = async (id: string) => {
  return await HTTP.get<Office>(`/paxico/offices/${id}`);
};

export const upsertOffice = async (office: any) => {
  if (isOffice(office)) {
    if ('id' in office) {
      let updatedData = await HTTP.put<Office>(
        `/paxico/offices/${office.id}`,
        office
      );

      await getOffices();

      return updatedData;
    } else {
      const insertedOffice = await HTTP.post<Office>('/paxico/offices', office);
      const offices = store.get<Office[]>('offices', []);
      offices.push(insertedOffice);
      store.set('offices', [...offices]);
      return insertedOffice;
    }
  }

  throw Error('An error occured while upserting office');
};

export const deleteOffice = async (id: string) => {
  await HTTP.delete(`/paxico/offices/${id}`);
  await getOffices();
};
