import { useRef } from 'react';
import ReactToPrint from 'react-to-print';
import { numberWithCommas } from '../../lib/numberWithCommas';
import { BsDownload } from 'react-icons/bs';
import { monthNames } from '../../consts/monthNames';
import Cookie from 'js-cookie';
import jwt_decode from 'jwt-decode';
import { isMultiple } from '../../lib/checkMultiple';
export default function InvoiceView({ invoiceData, clientData }: any) {
  const componentRef: any = useRef();

  let hours = 0;
  let totalOfficesAmount;
  let securitySeats: number = 0;
  let advancePaymentMonths = 0;
  let months = 0;
  let Amount = 0;
  let amount: any;
  let currentIndex = 0;
  let nextIndex = 0;
  amount =
    invoiceData.description.length > 1
      ? invoiceData.description[0].amount + invoiceData.description[1].amount
      : invoiceData.description[0].amount;

  currentIndex = clientData.invoices.findIndex(
    (val: any) => val.invoiceId === invoiceData.invoiceId
  );
  nextIndex = currentIndex + 1;
  if (nextIndex === clientData.invoices.length) {
    nextIndex = clientData.invoices.length - 1;
  }

  if (clientData.invoices.length > 1) {
    if (clientData.invoices[nextIndex].clientBalance !== 0) {
      Amount =
        clientData.advancePayment -
        clientData.invoices[nextIndex].clientBalance;
    }
  } else if (
    invoiceData.clientBalance !== 0 &&
    invoiceData.invoiceId.split('-')[1] === '0001'
  ) {
    Amount = clientData.advancePayment - clientData.clientBalance;
  }

  let remainderCheck = 0;
  // let nextCurrentBalance=clientData.invoices&&clientData.invoices[nextIndex].clientBalance
  if (nextIndex !== -1 && clientData.invoices[nextIndex] !== undefined) {
    remainderCheck =
      Number(
        clientData.invoices && clientData.invoices[nextIndex].clientBalance
      ) / Number(invoiceData && invoiceData.clientBalance);
  }
  if (
    nextIndex !== -1 &&
    clientData.invoices[nextIndex] !== undefined &&
    clientData.invoices &&
    clientData.invoices[nextIndex].clientBalance === 0
  ) {
    remainderCheck = 2;
  }

  if (
    clientData.invoices.length > 1 &&
    (remainderCheck === 0 || remainderCheck === Infinity)
  ) {
    if (
      clientData.invoices[nextIndex].clientBalance !== '' ||
      clientData.invoices[nextIndex].clientBalance !== 0
    ) {
      advancePaymentMonths =
        Number(clientData.invoices[nextIndex].clientBalance) / Number(amount);
      // if (advancePaymentMonths < 1) advancePaymentMonths = 0;
      months = isMultiple(
        Number(clientData.advancePayment),
        Number(invoiceData.clientBalance)
      );
    }
  } else {
    if (invoiceData.clientBalance !== '' || invoiceData.clientBalance !== 0) {
      advancePaymentMonths = Number(invoiceData.clientBalance) / Number(amount);
      months = isMultiple(
        Number(clientData.advancePayment),
        Number(invoiceData.clientBalance)
      );

      // if (advancePaymentMonths < 1) advancePaymentMonths = 0;
    }
  }
  if (clientData.advancePayment === 0) {
    months = 0;
  }

  if (
    invoiceData &&
    invoiceData.security !== '' &&
    invoiceData.security !== 0
  ) {
    totalOfficesAmount =
      invoiceData.description.length > 1
        ? invoiceData.description[0].amount +
          invoiceData.description[1].amount +
          Number(invoiceData.security)
        : invoiceData.description[0].amount + Number(invoiceData.security);

    if (invoiceData.clientBalance !== 0) {
      if (
        invoiceData.invoiceId.split('-')[1] === '0001' &&
        advancePaymentMonths < 2
      ) {
        if (months !== 0) {
          totalOfficesAmount = Amount + Number(invoiceData.security) * months;
        } else if (
          months === 0 &&
          invoiceData.consumedDays !== '' &&
          clientData.advancePayment !== 0
        ) {
          totalOfficesAmount = Amount + Number(invoiceData.security) + amount;
        } else {
          totalOfficesAmount = Amount + Number(invoiceData.security);
        }
      } else if (
        invoiceData.invoiceId.split('-')[1] === '0001' &&
        advancePaymentMonths > 1
      ) {
        totalOfficesAmount =
          Amount + Number(invoiceData.security) + amount * advancePaymentMonths;
      }
    }
  } else {
    if (invoiceData.description.length > 1) {
      totalOfficesAmount =
        invoiceData.description[0].amount + invoiceData.description[1].amount;
    } else {
      totalOfficesAmount = invoiceData.description[0].amount;
    }

    if (
      invoiceData.invoiceId.split('-')[1] !== '0001' &&
      (months > 0 || advancePaymentMonths > 1)
    ) {
      totalOfficesAmount = '00.00';
    }
  }

  Object.entries(clientData.consumedHours).forEach(([key, value]: any) => {
    let hourDateObj = key.split('-').reverse().join('/').split('/');
    let invoiceDateObj = invoiceData.invoiceMonth.split('-');

    if (
      hourDateObj[1] === invoiceDateObj[1] &&
      hourDateObj[2] === invoiceDateObj[0]
    ) {
      hours = hours + value;
    }
  });
  let additionalItemsAmount = 0;
  if (
    invoiceData.hasOwnProperty('additionalItems') &&
    invoiceData.additionalItems.length > 0
  ) {
    invoiceData.additionalItems.forEach((obj: any) => {
      additionalItemsAmount = additionalItemsAmount + Number(obj.amount);
    });
  }
  totalOfficesAmount = additionalItemsAmount + Number(totalOfficesAmount);
  if (hours < clientData.freeHours) {
    hours = 0;
  } else {
  }
  let token: any = Cookie.get('jwt');
  let decoded: any = jwt_decode(token);

  return (
    <>
      <ReactToPrint
        trigger={() => (
          <BsDownload
            style={{
              position: 'absolute',
              top: '6%',
              left: '93%',
              zIndex: '33333',
              fontSize: '30px',
              color: 'blue',
            }}
            // disabled={key !== 3}
          />
        )}
        content={() => componentRef.current}
      />
      <div
        ref={componentRef}
        style={{
          flexDirection: 'column',
          display: 'flex',
          backgroundColor: '#fff',

          // paddingLeft: 100,
          // paddingRight: 100,
          paddingRight: '0px',
          height: '278mm',
          width: '210mm',
          fontFamily: 'monospace',
          fontSize: 20,
        }}
      >
        <div
          style={{
            width: '100%',
            paddingLeft: '50px',
            paddingRight: '50px',
            background: 'rgb(1 10 36)',
            height: 'inherit',
          }}
        >
          <div
            style={{
              display: 'flex',
              marginTop: '40px',
              fontWeight: '700',
              color: 'white',
              letterSpacing: '1px',
            }}
          >
            <div style={{ width: '60%', textAlign: 'left' }}>
              {' '}
              <span>{'{Paxico}'}</span>
              <br></br>
              <span>Plot No.398, Service Road E, I-9/3 Industrial Area</span>
              <br></br>
              <span>
                Islamabad, Islamabad Capital Territory 44000, Pakistan
              </span>
              <br></br>
              <span>03030888761</span>
              <br></br>
              <span>{decoded.email}</span>
              <br></br>
              <span>www.Paxico.pk</span>
            </div>
            <div style={{ width: '40%', textAlign: 'right' }}>
              <span style={{ fontSize: '3rem' }}>INVOICE</span>
            </div>
          </div>
          <div
            style={{
              background: 'white',
              marginTop: '30px',
              paddingBottom: '30px',
              paddingTop: '30px',
              borderRadius: '10px',
              display: 'flex',
              flexDirection: 'column',
              fontWeight: '700',
            }}
          >
            <div
              style={{
                display: 'flex',
                paddingLeft: '50px',
                paddingRight: '50px',
              }}
            >
              <div style={{ width: '50%', textAlign: 'left' }}>
                <span> BILL TO</span>
                <br></br>
                <span>
                  {' '}
                  {invoiceData && invoiceData.nameForInvoice
                    ? invoiceData.nameForInvoice
                    : invoiceData.name}
                </span>
                <br></br>
                <span>Plot No.398, Service Road E, I-9/3 Industrial Area</span>
                <br></br>
                <span>
                  Islamabad, Islamabad Capital Territory 44000, Pakistan
                </span>
                <br></br>
                <span>{invoiceData && invoiceData.number}</span>
                <br></br>
                <span>
                  {invoiceData && invoiceData.invoiceEmail
                    ? invoiceData.invoiceEmail
                    : invoiceData.email}
                </span>
              </div>
              <div style={{ width: '25%', textAlign: 'right' }}>
                <span>INVOICE #</span>
                <br></br>
                <span>INVOICE MONTH</span>
                <br></br>
                <span>DUE DATE</span>
              </div>

              <div style={{ width: '25%', textAlign: 'right' }}>
                {' '}
                <span>{invoiceData && invoiceData.invoiceId}</span>
                <br></br>
                <span>
                  {monthNames[new Date(invoiceData.invoiceMonth).getMonth()]}
                </span>
                <br></br>
                <span>{invoiceData && invoiceData.dueDate}</span>
              </div>
            </div>
            <div>
              <div
                style={{
                  background: 'lightgrey',
                  display: 'flex',
                  marginLeft: '50px',
                  marginRight: '50px',
                  paddingLeft: '10px',
                  paddingRight: '10px',
                  marginTop: '30px',
                  height: '40px',
                  alignItems: 'center',
                  textAlign: 'left',
                  borderRadius: '10px',
                }}
              >
                <span
                  style={{
                    width: invoiceData.consumedDays !== '' ? '35%' : '50%',
                  }}
                >
                  {' '}
                  DESCRIPTION
                </span>
                <span
                  style={{
                    width: invoiceData.consumedDays === '' ? '13%' : '12.5%',
                  }}
                >
                  QTY
                </span>
                {invoiceData.consumedDays !== '' && (
                  <span
                    style={{
                      width: invoiceData.consumedDays === '' ? '13%' : '12.5%',
                    }}
                  >
                    DAYS
                  </span>
                )}

                <span
                  style={{
                    width: invoiceData.consumedDays === '' ? '13%' : '12.5%',
                    position: 'relative',
                  }}
                >
                  PRICE
                  <span
                    style={{
                      position: 'absolute',
                      fontSize: '0.7rem',
                      top: '65%',
                      left: '0%',
                    }}
                  >
                    Per Seat
                  </span>
                </span>
                {invoiceData.invoiceId.split('-')[1] === '0001' &&
                  invoiceData.consumedDays !== '' && (
                    <span
                      style={{
                        width:
                          invoiceData.consumedDays === '' ? '13%' : '12.5%',
                        position: 'relative',
                      }}
                    >
                      AMOUNT
                      <span
                        style={{
                          position: 'absolute',
                          fontSize: '0.7rem',
                          top: '65%',
                          left: '0%',
                        }}
                      >
                        {advancePaymentMonths < 2 ? 'By Days ' : 'Advance'}
                      </span>
                    </span>
                  )}
                {
                  <span
                    style={{
                      width: invoiceData.consumedDays === '' ? '13%' : '12.5%',
                      marginLeft: advancePaymentMonths > 1 ? '0px' : '0px',
                      position: 'relative',
                    }}
                  >
                    AMOUNT
                    <span
                      style={{
                        position: 'absolute',
                        fontSize: '0.7rem',
                        top: '65%',
                        left: '0%',
                      }}
                    >
                      {advancePaymentMonths === 0 || months === 0
                        ? ''
                        : 'Advance'}
                    </span>
                  </span>
                }
              </div>

              <div style={{ display: 'flex', flexDirection: 'column' }}>
                {invoiceData &&
                  invoiceData.description.map((des: any) => {
                    securitySeats = securitySeats + Number(des.quantity);
                    return (
                      <div
                        style={{
                          display: 'flex',
                          marginLeft: '50px',
                          marginRight: '50px',
                          paddingLeft: '10px',
                          paddingRight: '10px',
                          // flexDirection: 'column',
                          height: '40px',
                          alignItems: 'center',
                          textAlign: 'left',
                          borderRadius: '10px',
                        }}
                        key={des.title}
                      >
                        <span
                          style={{
                            width:
                              invoiceData.consumedDays !== '' ? '35%' : '50%',
                            position: 'relative',
                          }}
                        >
                          {' '}
                          {des && des.title}
                          {advancePaymentMonths > 0 && (
                            <span
                              style={{
                                position: 'absolute',
                                fontSize: '0.7rem',
                                top: '77%',
                                left: '4%',
                              }}
                            >
                              {months !== 0
                                ? invoiceData.invoiceId.split('-')[1] !== '0001'
                                  ? 'Advance Payment'
                                  : `${
                                      advancePaymentMonths < months
                                        ? months
                                        : advancePaymentMonths
                                    } Months Advance Payment`
                                : invoiceData.invoiceId.split('-')[1] !==
                                    '0001' && advancePaymentMonths > 1
                                ? 'Advance Payment'
                                : advancePaymentMonths > 1
                                ? `${advancePaymentMonths} Months Advance Payment`
                                : advancePaymentMonths > 1
                                ? 'Advance Payment'
                                : ''}
                            </span>
                          )}
                        </span>
                        <span
                          style={{
                            width:
                              invoiceData.consumedDays === '' ? '13%' : '12.5%',
                          }}
                        >
                          {des && des.quantity + '.00'}
                        </span>
                        {invoiceData.consumedDays !== '' && (
                          <span
                            style={{
                              width:
                                invoiceData.consumedDays === ''
                                  ? '13%'
                                  : '12.5%',
                            }}
                          >
                            {invoiceData && invoiceData.consumedDays}
                          </span>
                        )}

                        <span
                          style={{
                            width:
                              invoiceData.consumedDays === '' ? '13%' : '12.5%',
                          }}
                        >
                          {numberWithCommas(des && des.price)}
                        </span>
                        {invoiceData.invoiceId.split('-')[1] === '0001' &&
                          invoiceData.consumedDays !== '' && (
                            <span
                              style={{
                                width:
                                  invoiceData.consumedDays === ''
                                    ? advancePaymentMonths > 1
                                      ? '22%'
                                      : '13%'
                                    : '13.5%',
                                whiteSpace: 'nowrap',
                              }}
                            >
                              {advancePaymentMonths < 2
                                ? Amount === 0
                                  ? des && numberWithCommas(des && des.amount)
                                  : numberWithCommas(Amount)
                                : `(${advancePaymentMonths}x${numberWithCommas(
                                    des && des.amount
                                  )})`}
                              +{' '}
                            </span>
                          )}
                        {
                          <span
                            style={{
                              width:
                                invoiceData.consumedDays === ''
                                  ? '13%'
                                  : '12.5%',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            {invoiceData.invoiceId.split('-')[1] === '0001' &&
                            clientData.advancePayment === 0 &&
                            clientData.clientBalance !== 0
                              ? '0'
                              : months !== 0
                              ? invoiceData.invoiceId.split('-')[1] === '0001'
                                ? `${
                                    advancePaymentMonths < months
                                      ? months
                                      : advancePaymentMonths
                                  }x${
                                    des && numberWithCommas(des && des.amount)
                                  }`
                                : des && numberWithCommas(des && des.amount)
                              : invoiceData.invoiceId.split('-')[1] === '0001'
                              ? advancePaymentMonths > 1
                                ? `${advancePaymentMonths}x${
                                    des && numberWithCommas(des && des.amount)
                                  }`
                                : des && numberWithCommas(des && des.amount)
                              : amount === 0 &&
                                invoiceData.invoiceId.split('-')[1] === '0001'
                              ? 0
                              : des && numberWithCommas(des && des.amount)}
                          </span>
                        }
                      </div>
                    );
                  })}
                {invoiceData.hasOwnProperty('additionalItems') &&
                  invoiceData.additionalItems.map(
                    (data: any, index: number) => {
                      return (
                        <div
                          style={{
                            display: 'flex',
                            marginLeft: '50px',
                            marginRight: '50px',
                            paddingLeft: '10px',
                            paddingRight: '10px',
                            // flexDirection: 'column',
                            height: '40px',
                            alignItems: 'center',
                            textAlign: 'left',
                            borderRadius: '10px',
                          }}
                          key={index}
                        >
                          <span
                            style={{
                              width:
                                invoiceData.consumedDays !== '' ? '35%' : '50%',
                              position: 'relative',
                            }}
                          >
                            {' '}
                            {data && data.item}
                          </span>
                          <span
                            style={{
                              width:
                                invoiceData.consumedDays === ''
                                  ? '13%'
                                  : '12.5%',
                            }}
                          ></span>

                          <span
                            style={{
                              width:
                                invoiceData.consumedDays === ''
                                  ? '13%'
                                  : '12.5%',
                            }}
                          ></span>

                          {
                            <span
                              style={{
                                width:
                                  invoiceData.consumedDays === ''
                                    ? '13%'
                                    : '12.5%',
                                whiteSpace: 'nowrap',
                              }}
                            >
                              {data && numberWithCommas(data.amount)}
                            </span>
                          }
                        </div>
                      );
                    }
                  )}
              </div>
            </div>
            {hours !== 0 && (
              <div>
                <div
                  style={{
                    background: 'lightgrey',
                    display: 'flex',
                    marginLeft: '50px',
                    marginRight: '50px',
                    paddingLeft: '10px',
                    paddingRight: '10px',
                    height: '40px',
                    marginTop: '15px',
                    alignItems: 'center',
                    textAlign: 'left',
                    borderRadius: '10px',
                  }}
                >
                  <span
                    style={{
                      width: invoiceData.consumedDays !== '' ? '35%' : '50%',
                    }}
                  >
                    {' '}
                    ROOM
                  </span>
                  <span style={{ width: '13%' }}>HOURS</span>
                  <span style={{ width: '13%', position: 'relative' }}>
                    PRICE
                    <span
                      style={{
                        fontSize: '11px',
                        position: 'absolute',
                        top: '20%',
                      }}
                    >
                      {' '}
                      /hr{' '}
                    </span>
                  </span>
                  <span style={{ width: '13%' }}>AMOUNT</span>
                </div>

                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <div
                    style={{
                      display: 'flex',
                      marginLeft: '50px',
                      marginRight: '50px',
                      paddingLeft: '10px',
                      paddingRight: '10px',
                      height: '40px',
                      alignItems: 'center',
                      textAlign: 'left',
                      borderRadius: '10px',
                    }}
                  >
                    <span
                      style={{
                        width: invoiceData.consumedDays !== '' ? '35%' : '50%',
                      }}
                    >
                      {' '}
                      {clientData && clientData.scheduledMeetingRoom.name}
                    </span>
                    <span style={{ width: '13%' }}>
                      {hours - clientData.freeHours}
                    </span>
                    <span style={{ width: '13%' }}>
                      {numberWithCommas(1200)}
                    </span>
                    <span style={{ width: '13%' }}>
                      {numberWithCommas((hours - clientData.freeHours) * 1200)}
                    </span>
                  </div>
                </div>
              </div>
            )}
            {invoiceData.invoiceId.split('-')[1] !== '0001' &&
              (months > 0 || advancePaymentMonths > 1) && (
                <div
                  style={{
                    background: '#f4f3f3',
                    display: 'flex',
                    marginLeft: '50px',
                    marginRight: '50px',
                    paddingLeft: '10px',
                    paddingRight: '10px',
                    height: '40px',
                    alignItems: 'center',
                    textAlign: 'left',
                    borderRadius: '10px',
                    marginTop: '15px',
                  }}
                >
                  <span
                    style={{
                      width: invoiceData.consumedDays !== '' ? '35%' : '50%',
                    }}
                  >
                    {' '}
                    Advance Paid
                  </span>
                  <span
                    style={{
                      width: '13%',
                    }}
                  >
                    -
                  </span>
                  <span
                    style={{
                      width: '13%',
                    }}
                  >
                    -
                  </span>
                  <span style={{ width: '13%' }}>
                    {' '}
                    {numberWithCommas(amount)}
                  </span>
                </div>
              )}
            {invoiceData &&
              invoiceData.security !== '' &&
              invoiceData.security !== 0 && (
                <div
                  style={{
                    background: '#f4f3f3',
                    display: 'flex',
                    marginLeft: '50px',
                    marginRight: '50px',
                    paddingLeft: '10px',
                    paddingRight: '10px',
                    height: '40px',
                    alignItems: 'center',
                    textAlign: 'left',
                    borderRadius: '10px',
                    marginTop: '15px',
                  }}
                >
                  <span
                    style={{
                      width: invoiceData.consumedDays !== '' ? '35%' : '53%',
                    }}
                  >
                    {' '}
                    Security
                  </span>
                  <span
                    style={{
                      width: invoiceData.consumedDays === '' ? '13%' : '12.5%',
                      paddingLeft:
                        typeof invoiceData.security !== 'string'
                          ? '0px'
                          : '16px',
                    }}
                  >
                    {typeof invoiceData.security !== 'string'
                      ? securitySeats
                        ? securitySeats + '.00'
                        : ''
                      : '-'}
                  </span>
                  <span
                    style={{
                      width: invoiceData.consumedDays === '' ? '13%' : '12.5%',
                    }}
                  ></span>
                  <span
                    style={{
                      width: invoiceData.consumedDays === '' ? '13%' : '12.5%',
                      paddingLeft:
                        typeof invoiceData.security !== 'string'
                          ? '8px'
                          : '16px',
                    }}
                  >
                    {/* {typeof invoiceData.security !== 'string'
                      ? invoiceData.description.length > 1
                        ? '-------'
                        : numberWithCommas(invoiceData.description[0].price)
                      : '-'} */}
                    {invoiceData.consumedDays === '' &&
                      numberWithCommas(invoiceData.security)}
                  </span>

                  <span
                    style={{
                      width: invoiceData.consumedDays === '' ? '13%' : '12.5%',
                    }}
                  >
                    {' '}
                    {invoiceData.consumedDays !== '' &&
                      numberWithCommas(invoiceData.security)}
                  </span>
                </div>
              )}

            <div>
              <div
                style={{
                  display: 'flex',
                  marginLeft: '50px',
                  marginRight: '50px',

                  textAlign: 'left',
                }}
              >
                <span style={{ width: '50%' }}> </span>
                <span style={{ width: '12.6%' }}></span>
                <span
                  style={{
                    width: '36.2%',
                    background: 'lightgrey',
                    height: '40px',
                    display: 'flex',
                    paddingLeft: '1.5%',
                    alignItems: 'center',
                    borderRadius: '10px',
                    marginTop: '10px',
                  }}
                >
                  <span style={{ width: '50%' }}>Total :</span>
                  <span style={{ width: '50%' }}>
                    {hours === 0
                      ? numberWithCommas(totalOfficesAmount)
                      : numberWithCommas(
                          totalOfficesAmount +
                            1200 * (hours - clientData.freeHours)
                        )}
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
