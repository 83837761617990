import { FormWrapper, InputWrapper } from './Forms.styles';
import { clientFormItems } from '../../consts/FormFields';
import { IClientForm, Office } from '../../interfaces';
import Select from 'react-select';
import { useCallback, useEffect, useState } from 'react';
import store from '@jitcoder/usestore';
import Client from '../../models/client';
import { getClientInOffice } from '../../lib/getClientInOffice';
import { getOffices } from '../../api/offices';
import { removeDuplicateObjFromArray } from '../../lib/removeDuplicateObjFromArray';
import CustomRequired from '../CustomRequired/CustomRequired';
export default function ClientForm({
  modalType,
  handleChange,
  clientData,
  handleOptionChange,
}: any) {
  const offices = store.get<Office[]>('offices', []);
  const clients = store.get<Client[]>('clients', []);
  const [option, setOption] = useState([]);
  const [sharedOffices, setSharedOffices] = useState<any>([]);
  const [selectedOptionsOffice, setSelectedOptionsOffice] = useState<any>();
  const [selectedOptionsType, setSelectedOptionsType] = useState<any>();
  const [selectedOptionsContract, setSelectedOptionsContract] = useState<any>();

  const [renderStop, setRenderStop] = useState(false);

  const handleMultiChangeType = async (option: any) => {
    setSelectedOptionsType(option);
    handleOptionChange(option, 0, 'type');
  };

  const handleMultiChangeContract = async (option: any) => {
    setSelectedOptionsType(option);
    handleOptionChange(option, 0, 'contract');
  };

  const updateOffice = useCallback(() => {
    let opt: any = {};

    let options: any = [];
    // allOfficesAreNUll will check if no office is assigned to any client in offices tab
    let allOfficesAreNUll = clients.every(
      (client: any) => client.offices.length === 0
    );

    if (allOfficesAreNUll) {
      //none of the office is assigned to any client
      let privateOffice: any = offices.filter(
        (item: any) => item.type.label === 'Private'
      );
      let sharedOffice = offices.filter(
        (item: any) => item.type.label === 'Shared'
      );
      selectedOptionsType &&
        selectedOptionsType.forEach((opti: any) => {
          if (opti.label === 'Private') {
            for (let i = 0; i < privateOffice.length; i++) {
              opt['label'] = privateOffice[i].name;
              opt['value'] = privateOffice[i].id;
              options.push({ ...opt });
            }
          } else if (opti.label === 'Shared') {
            for (let i = 0; i < sharedOffice.length; i++) {
              opt['label'] = sharedOffice[i].name;
              opt['value'] = sharedOffice[i].id;
              options.push({ ...opt });
            }
          }
        });
    } else {
      selectedOptionsType &&
        selectedOptionsType.forEach((opti: any) => {
          if (opti.label === 'Private') {
            for (let i = 0; i < offices.length; i++) {
              if (
                getClientInOffice(offices[i].id) === 'undefined' ||
                (getClientInOffice(offices[i].id) === undefined &&
                  offices[i].type.label === opti.label)
              ) {
                opt['label'] = offices[i].name;
                opt['value'] = offices[i].id;
                options.push({ ...opt });
              }
            }
          } else if (opti.label === 'Shared') {
            for (let i = 0; i < offices.length; i++) {
              let fetchedClient = getClientInOffice(offices[i].id);
              if (offices[i].type.label === 'Shared') {
                if (fetchedClient === undefined) {
                  opt['label'] = offices[i].name;
                  opt['value'] = offices[i].id;
                  options.push({ ...opt });
                } else {
                  fetchedClient &&
                    fetchedClient.offices.forEach((shared: any) => {
                      if (
                        shared.label === offices[i].name &&
                        fetchedClient[`${shared.label}_sharedSeats`] <
                          offices[i].seats
                      ) {
                        opt['label'] = offices[i].name;
                        opt['value'] = offices[i].id;
                        options.push({ ...opt });
                      }
                    });
                }
              }
            }
          }
        });
    }

    const result = removeDuplicateObjFromArray(options, 'value');
    // if (clients.length === 0) {
    //   for (let i = 0; i < offices.length; i++) {
    //     opt['label'] = offices[i].name;
    //     opt['value'] = offices[i].id;
    //     options.push({ ...opt });
    //   }
    // }

    setOption(result);
  }, [clients, offices, selectedOptionsType]);

  useEffect(() => {
    updateOffice();
  }, [updateOffice]);

  const handleMultiChangeOffice = (option: any) => {
    setSelectedOptionsOffice(option);
    handleOptionChange(option);

    let shared: any = [];
    let sharedOffice: any = offices.filter(
      (item: any) => item.type.label === 'Shared'
    );

    sharedOffice &&
      sharedOffice.forEach((office: any) => {
        option &&
          option.forEach((opti: any) => {
            if (opti.value === office.id) {
              shared.push({ ...office });
            }
          });
      });

    setSharedOffices(shared);
  };

  const updateOption: any = useCallback(async () => {
    if (!renderStop) {
      let opt: any = {};
      let options: any = [];
      // allOfficesAreNUll will check if no office is assigned to any client in offices tab
      let allOfficesAreNUll = clients.every(
        (client: any) => client.offices.length === 0
      );

      if (allOfficesAreNUll) {
        //none of the office is assigned to any client
        for (let i = 0; i < offices.length; i++) {
          opt['label'] = offices[i].name;
          opt['value'] = offices[i].id;
          options.push({ ...opt });
        }
      } else {
        for (let i = 0; i < offices.length; i++) {
          if (
            getClientInOffice(offices[i].id) === 'undefined' ||
            getClientInOffice(offices[i].id) === undefined
          ) {
            opt['label'] = offices[i].name;
            opt['value'] = offices[i].id;
            options.push({ ...opt });
          }
        }
      }

      if (clients.length === 0) {
        for (let i = 0; i < offices.length; i++) {
          opt['label'] = offices[i].name;
          opt['value'] = offices[i].id;
          options.push({ ...opt });
        }
      }

      setOption(options);
      setRenderStop(true);

      if (offices.length === 0) {
        await getOffices();
      }
    }
  }, [clients, offices, renderStop]);

  const validateSharedSeats = (name: string) => {
    let seats;
    for (const client of clients) {
      if (client.hasOwnProperty(`${name}_sharedSeats`)) {
        seats = client[`${name}_sharedSeats`];
        break;
      }
    }
    return seats;
  };

  useEffect(() => {
    updateOption();
  }, [updateOption]);

  useEffect(() => {
    setSelectedOptionsType(clientData.type);
    setSelectedOptionsContract(clientData.contract);
  }, [clientData.contract, clientData.type]);

  useEffect(() => {
    let options: any = [];
    for (let i = 0; i < clientData.offices.length; i++) {
      options.push({ ...clientData.offices[i] });
    }

    setSelectedOptionsOffice(options);

    let shared: any = [];
    let sharedOffice: any = offices.filter(
      (item: any) => item.type.label === 'Shared'
    );

    sharedOffice &&
      sharedOffice.forEach((sharedOffice: any) => {
        clientData.offices.forEach((office: any) => {
          if (office.value === sharedOffice.id) {
            shared.push({ ...sharedOffice });
          }
        });
      });

    setSharedOffices(shared);
  }, [clientData.offices, clientData.type, offices, option]);

  return (
    <FormWrapper
      style={{
        pointerEvents: modalType === 'SHOW CLIENT' ? 'none' : 'auto',
      }}
    >
      {clientFormItems.map((item) => (
        <InputWrapper key={item.id}>
          <label className={item.isRequired ? 'should-required' : ''}>
            {item.label}
          </label>
          <input
            type={item.type}
            id={item.id}
            onChange={(e) => handleChange(e, 'Client')}
            value={clientData && clientData[item.id as keyof IClientForm]}
            disabled={modalType === 'SHOW CLIENT'}
            required={item.isRequired}
          ></input>
          {item.label === 'Email' && (
            <>
              <InputWrapper key='officeType'>
                <label className='should-required'>Office Type</label>
                <Select
                  isMulti
                  name='colors'
                  placeholder='Please Select Office Type'
                  options={[
                    { label: 'Private', value: 'Private' },
                    { label: 'Shared', value: 'Shared' },
                  ]}
                  onChange={handleMultiChangeType}
                  value={selectedOptionsType}
                  className='basic-multi-select'
                  noOptionsMessage={() => 'No Type available'}
                  classNamePrefix='select'
                  isDisabled={modalType === 'SHOW CLIENT'}
                />
              </InputWrapper>
              {selectedOptionsType && (
                <div style={{ display: 'flex' }}>
                  <InputWrapper
                    key='office'
                    style={{
                      width:
                        sharedOffices && sharedOffices.length > 0
                          ? '50%'
                          : '100%',
                    }}
                  >
                    <label className='should-required'>Office</label>
                    <CustomRequired
                      requiredCondition={
                        clientData.type.length > 0 &&
                        selectedOptionsOffice &&
                        selectedOptionsOffice.length === 0
                      }
                      value={selectedOptionsOffice}
                    >
                      <Select
                        isMulti
                        name='colors'
                        placeholder='Assign Office'
                        options={option}
                        onChange={handleMultiChangeOffice}
                        value={selectedOptionsOffice}
                        className='basic-multi-select'
                        noOptionsMessage={() => 'No Office available'}
                        classNamePrefix='select'
                        isDisabled={modalType === 'SHOW CLIENT'}
                      />
                    </CustomRequired>
                  </InputWrapper>
                  {sharedOffices &&
                    sharedOffices.map((sharedOffice: any, i: any) => (
                      <InputWrapper
                        key={`sharedSeats${i}`}
                        style={{ width: '50%' }}
                      >
                        <label className='should-required'>{`${sharedOffice.name} Shared Seats`}</label>
                        <input
                          type='number'
                          min='0'
                          onChange={(e) => handleChange(e, 'Client')}
                          max={sharedOffice.seats}
                          id={`${sharedOffice.name}_sharedSeats`}
                          required
                          onInvalid={(e) => {
                            let event = e.target as HTMLInputElement;
                            let validateSeats = validateSharedSeats(
                              sharedOffice.name
                            );

                            if (event.value > sharedOffice.seats) {
                              event.setCustomValidity(
                                `${sharedOffice.name} has ${
                                  validateSeats === undefined
                                    ? sharedOffice.seats
                                    : validateSeats
                                }  seats ${
                                  validateSeats === undefined
                                    ? 'in total'
                                    : 'remaining'
                                } `
                              );
                            } else {
                              if (event.value === '')
                                event.setCustomValidity(
                                  'Please Enter the Required Seats'
                                );
                              else {
                                event.setCustomValidity('');
                              }
                            }
                          }}
                          value={
                            clientData &&
                            clientData[`${sharedOffice.name}_sharedSeats`]
                          }
                          disabled={modalType === 'SHOW CLIENT'}
                        ></input>
                      </InputWrapper>
                    ))}
                </div>
              )}
              <InputWrapper key='contract'>
                <label className='should-required'>Contract</label>
                <Select
                  isMulti
                  name='colors'
                  placeholder='Please Select Office Type'
                  options={clientData.contract}
                  onChange={handleMultiChangeContract}
                  value={selectedOptionsContract}
                  className='basic-multi-select'
                  noOptionsMessage={() => 'No Type available'}
                  classNamePrefix='select'
                  isDisabled={modalType === 'SHOW CLIENT'}
                />
              </InputWrapper>
            </>
          )}
        </InputWrapper>
      ))}
    </FormWrapper>
  );
}
