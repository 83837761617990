import { toast } from 'react-toastify';

export const toastMSG = (type?: string, Name?: string, Data?: any) => {
  if (type !== 'delete') {
    if (Data.hasOwnProperty('id')) {
      toast.success(`${Name} has been Modified`);
    } else {
      toast.success(`${Name} has been Created`);
    }
  } else {
    toast.success(`${Name} has been Deleted`);
  }
};
