import { useCallback, useEffect, useState } from 'react';
import { FormWrapper, InputWrapper } from './Forms.styles';
import { contractFormItems } from '../../consts/FormFields';
import { IContract, IOfficeForm, Office } from '../../interfaces';
import Select from 'react-select';
import store from '@jitcoder/usestore';
import Client from '../../models/client';
import { getClientInOffice } from '../../lib/getClientInOffice';
import { getOffices } from '../../api/offices';
import { removeDuplicateObjFromArray } from '../../lib/removeDuplicateObjFromArray';
import CustomRequired from '../CustomRequired/CustomRequired';
import { capitalizeFirstLetter } from '../../lib/capitalizeFirstLetter';
import { ToggleSwitch } from 'react-dragswitch';
// import { isObjectEmpty } from '../../lib/isObjectEmpty';

export default function OfficeForm({
  modalType,
  handleChange,
  contractData,
  handleOptionChange,
  disabledContractOnAddendumOptionChange,
  //   validateSelect,
  rowId,
}: any) {
  const [option, setOption] = useState([]);
  const [selectedOptionsType, setSelectedOptionsType] = useState<any>();
  // const [selectedOptionsCreater, setSelectedOptionsCreater] = useState<any>();
  const [selectedOptionsAddendum, setSelectedOptionsAddendum] = useState<any>();
  const [optionsAddendum, setOptionsAddendum] = useState([]);
  const [sharedOffices, setSharedOffices] = useState<any>([]);
  const [Data, setData] = useState<any>({ ...contractData });
  const [selectedOptionsOffice, setSelectedOptionsOffice] = useState<any>();
  const [renderStop, setRenderStop] = useState(false);
  const [renderStopp, setRenderStopp] = useState(false);
  const [disabledForm, setDisabledForm] = useState(true);
  const [byDateorDuration, setByDateorDuration] = useState(false);

  const clients = store.get<Client[]>('clients', []);
  const offices = store.get<Office[]>('offices', []);

  const handleMultiChangeType = async (option: any) => {
    setSelectedOptionsType(option);
    handleOptionChange(option, 0, 'type');
  };

  // const handleMultiChangeCreater = async (option: any) => {
  //   setSelectedOptionsCreater(option);
  //   handleOptionChange(option, 0, 'creater');
  // };

  const handleMultiChangeAddendum = async (option: any) => {
    if (option.value === 'Orignal Contract') {
      setData(contractData);
    } else {
      let filtered = contractData.addendumContract.filter(
        (item: any) => item.id === option.value
      );

      setData(filtered[0]);
    }
    let indexOption = optionsAddendum.findIndex(
      (op: any) => op.value === option.value
    );
    if (indexOption === optionsAddendum.length - 1) {
      setDisabledForm(true);
      disabledContractOnAddendumOptionChange(true);
    } else {
      setDisabledForm(false);
      disabledContractOnAddendumOptionChange(false);
    }
    setSelectedOptionsAddendum(option);
  };

  useEffect(() => {
    if (contractData.addendumContract.length > 0) {
      setData(
        contractData.addendumContract[contractData.addendumContract.length - 1]
      );
    } else {
      setData(contractData);
    }
  }, [contractData, contractData.addendumContract]);

  const updateOffice = useCallback(() => {
    // if (!renderStoppp) {
    let opt: any = {};

    let options: any = [];
    // allOfficesAreNUll will check if no office is assigned to any client in offices tab
    let allOfficesAreNUll = clients.every(
      (client: any) => client.offices.length === 0
    );

    if (allOfficesAreNUll) {
      //none of the office is assigned to any client
      let privateOffice: any = offices.filter(
        (item: any) => item.type.label === 'Private'
      );
      let sharedOffice: any = offices.filter(
        (item: any) => item.type.label === 'Shared'
      );
      let virtualOffice: any = offices.filter(
        (item: any) => item.type.label === 'Virtual'
      );
      selectedOptionsType &&
        selectedOptionsType.forEach((opti: any) => {
          if (opti.label === 'Private') {
            for (let i = 0; i < privateOffice.length; i++) {
              opt['label'] = privateOffice[i].name;
              opt['value'] = privateOffice[i].id;
              options.push({ ...opt });
            }
          } else if (opti.label === 'Shared') {
            for (let i = 0; i < sharedOffice.length; i++) {
              opt['label'] = sharedOffice[i].name;
              opt['value'] = sharedOffice[i].id;
              options.push({ ...opt });
            }
          } else if (opti.label === 'Virtual') {
            for (let i = 0; i < virtualOffice.length; i++) {
              opt['label'] = virtualOffice[i].name;
              opt['value'] = virtualOffice[i].id;
              options.push({ ...opt });
            }
          }
        });
    } else {
      selectedOptionsType &&
        selectedOptionsType.forEach((opti: any) => {
          if (opti.label === 'Private') {
            for (let i = 0; i < offices.length; i++) {
              if (
                getClientInOffice(offices[i].id) === 'undefined' ||
                (getClientInOffice(offices[i].id) === undefined &&
                  offices[i].type.label === opti.label)
              ) {
                opt['label'] = offices[i].name;
                opt['value'] = offices[i].id;
                options.push({ ...opt });
              }
            }
          } else if (opti.label === 'Shared') {
            for (let i = 0; i < offices.length; i++) {
              let fetchedClient = getClientInOffice(offices[i].id);

              if (offices[i].type.label === 'Shared') {
                if (fetchedClient === undefined) {
                  opt['label'] = offices[i].name;
                  opt['value'] = offices[i].id;
                  options.push({ ...opt });
                } else {
                  fetchedClient &&
                    // eslint-disable-next-line no-loop-func
                    fetchedClient.offices.forEach((shared: any) => {
                      if (
                        shared.label === offices[i].name &&
                        fetchedClient[`${shared.label}_sharedSeats`] <
                          offices[i].seats
                      ) {
                        opt['label'] = offices[i].name;
                        opt['value'] = offices[i].id;
                        options.push({ ...opt });
                      }
                    });
                }
              }
            }
          } else if (opti.label === 'Virtual') {
            for (let i = 0; i < offices.length; i++) {
              if (
                getClientInOffice(offices[i].id) === 'undefined' ||
                (getClientInOffice(offices[i].id) === undefined &&
                  offices[i].type.label === opti.label)
              ) {
                opt['label'] = offices[i].name;
                opt['value'] = offices[i].id;
                options.push({ ...opt });
              }
            }
          }
        });
    }

    const result = removeDuplicateObjFromArray(options, 'value');

    setOption(result);
    //   setRenderStoppp(true);
    // }
  }, [clients, offices, selectedOptionsType]);

  useEffect(() => {
    updateOffice();
  }, [updateOffice]);

  const handleMultiChangeOffice: any = (option: any) => {
    setSelectedOptionsOffice(option);
    handleOptionChange(option);

    let shared: any = [];
    let sharedOffice: any = offices.filter(
      (item: any) => item.type.label === 'Shared'
    );

    sharedOffice &&
      sharedOffice.forEach((office: any) => {
        option &&
          option.forEach((opti: any) => {
            if (opti.value === office.id) {
              shared.push({ ...office });
            }
          });
      });

    setSharedOffices(shared);
  };

  const updateOption: any = useCallback(async () => {
    if (!renderStop) {
      let opt: any = {};
      let options: any = [];
      // allOfficesAreNUll will check if no office is assigned to any client in offices tab
      let allOfficesAreNUll = clients.every(
        (client: any) => client.offices.length === 0
      );

      if (allOfficesAreNUll) {
        //none of the office is assigned to any client
        for (let i = 0; i < offices.length; i++) {
          opt['label'] = offices[i].name;
          opt['value'] = offices[i].id;
          options.push({ ...opt });
        }
      } else {
        for (let i = 0; i < offices.length; i++) {
          if (
            getClientInOffice(offices[i].id) === 'undefined' ||
            getClientInOffice(offices[i].id) === undefined
          ) {
            opt['label'] = offices[i].name;
            opt['value'] = offices[i].id;
            options.push({ ...opt });
          }
        }
      }

      if (clients.length === 0) {
        for (let i = 0; i < offices.length; i++) {
          opt['label'] = offices[i].name;
          opt['value'] = offices[i].id;
          options.push({ ...opt });
        }
      }

      setOption(options);
      setRenderStop(true);

      if (offices.length === 0) {
        await getOffices();
      }
    }
  }, [clients, offices, renderStop]);

  const validateSharedSeats = (name: string) => {
    let seats;
    for (const client of clients) {
      if (client.hasOwnProperty(`${name}_sharedSeats`)) {
        seats = client[`${name}_sharedSeats`];
      }
    }

    return seats;
  };

  useEffect(() => {
    updateOption();
  }, [updateOption]);

  useEffect(() => {
    if (
      contractData &&
      contractData.addendumContract &&
      contractData.addendumContract.length > 0
    ) {
      let opt: any = {};
      let options: any = [];
      if (!renderStopp) {
        for (let i = 0; i < contractData.addendumContract.length; i++) {
          opt['label'] = capitalizeFirstLetter(
            contractData.addendumContract[i].id
          );
          opt['value'] = contractData.addendumContract[i].id;
          options.push({ ...opt });
          if (i === contractData.addendumContract.length - 1) {
            opt['label'] = 'Orignal Contract';
            opt['value'] = 'Orignal Contract';
            options.unshift({ ...opt });
          }
        }

        setOptionsAddendum(options);
        setRenderStopp(true);
      }
    }
  }, [contractData, renderStopp]);

  useEffect(() => {
    setSelectedOptionsType(Data.type);
    // setSelectedOptionsCreater(Data.creater);
  }, [Data.type]);

  useEffect(() => {
    let opt: any = {};

    if (contractData.addendumContract.length > 0) {
      let add =
        contractData.addendumContract[contractData.addendumContract.length - 1]
          .id;
      opt = {
        label: capitalizeFirstLetter(add),
        value: add,
      };

      setSelectedOptionsAddendum(opt);
    }
  }, [contractData.addendumContract]);

  useEffect(() => {
    let options: any = [];

    for (let i = 0; i < Data.offices.length; i++) {
      options.push({ ...Data.offices[i] });
    }

    setSelectedOptionsOffice(options);

    let shared: any = [];
    let sharedOffice: any = offices.filter(
      (item: any) => item.type.label === 'Shared'
    );

    sharedOffice &&
      sharedOffice.forEach((sharedOffice: any) => {
        Data.offices.forEach((office: any) => {
          if (office.value === sharedOffice.id) {
            shared.push({ ...sharedOffice });
          }
        });
      });

    setSharedOffices(shared);
  }, [Data.offices, offices]);

  const displayedInput = byDateorDuration ? (
    <div style={{ display: 'flex' }}>
      <InputWrapper style={{ width: '50%' }}>
        <label className={'should-required'}>Contract Start Data</label>
        <input
          type='date'
          id='startDate'
          onChange={(e) => handleChange(e, 'Contract')}
          value={Data && Data['startDate' as keyof IContract]}
          disabled={
            modalType === 'SHOW CONTRACT' || modalType === 'EDIT CONTRACT'
          }
          required
        ></input>
      </InputWrapper>
      <InputWrapper style={{ width: '50%' }}>
        <label className={'should-required'}>Contract End Date</label>
        <input
          type='date'
          id='endDate'
          onChange={(e) => handleChange(e, 'Contract')}
          value={Data && Data['endDate' as keyof IContract]}
          disabled={
            modalType === 'SHOW CONTRACT' || modalType === 'EDIT CONTRACT'
          }
          required
        ></input>
      </InputWrapper>
    </div>
  ) : (
    <InputWrapper>
      <label className={'should-required'}>Contract Duration</label>
      <input
        type='number'
        id='duration'
        onChange={(e) => handleChange(e, 'Contract')}
        value={Data && Data['duration' as keyof IContract]}
        disabled={
          modalType === 'SHOW CONTRACT' || modalType === 'EDIT CONTRACT'
        }
        required
        min='0'
      ></input>
    </InputWrapper>
  );

  return (
    <>
      {contractData.addendumContract.length > 0 && (
        <InputWrapper key='addendumContract'>
          <label>Addendums in Contract</label>
          <Select
            name='colors'
            // placeholder='Who are you?'
            options={optionsAddendum}
            onChange={handleMultiChangeAddendum}
            value={selectedOptionsAddendum}
            className='basic-multi-select'
            // noOptionsMessage={() => 'No Type available'}
            classNamePrefix='select'
          />
        </InputWrapper>
      )}

      <FormWrapper
        style={{
          pointerEvents: modalType === 'SHOW CONTRACT' ? 'none' : 'auto',
        }}
      >
        {/* <InputWrapper key='contractCreater'>
          <label className='should-required'>Contract Creater</label>
          <CustomRequired
            requiredCondition={true}
            value={
              isObjectEmpty(selectedOptionsCreater) === true
                ? ''
                : selectedOptionsCreater
            }
          >
            <Select
              name='colors'
              placeholder='Who are you?'
              options={[
                { label: 'Mirza Sameer Baig', value: 'Mirza Sameer Baig' },
                {
                  label: 'Mirza Shamoon Baig',
                  value: 'Mirza Shamoon Baig',
                },
                { label: 'Zeeshan Ahmad', value: 'Zeeshan Ahmad' },

                { label: 'Maryam Riaz', value: 'Maryam Riaz' },
              ]}
              onChange={handleMultiChangeCreater}
              value={selectedOptionsCreater}
              className='basic-multi-select'
              // noOptionsMessage={() => 'No Type available'}
              classNamePrefix='select'
              isDisabled={
                modalType === 'SHOW CONTRACT' || modalType === 'EDIT CONTRACT'
              }
            />
          </CustomRequired>
        </InputWrapper> */}
        <InputWrapper key={'creater'}>
          <label>Contract Creater</label>
          <input
            type='text'
            id='creater'
            onChange={(e) => handleChange(e, 'Contract')}
            value={Data && Data['creater' as keyof IContract]}
            disabled
          ></input>
        </InputWrapper>
        {contractFormItems.map((item) => (
          <InputWrapper key={item.id}>
            <label className={item.isRequired ? 'should-required' : ''}>
              {item.label}
            </label>
            <input
              type={item.type}
              id={item.id}
              onChange={(e) => handleChange(e, 'Contract')}
              value={Data && Data[item.id as keyof IContract]}
              disabled={
                modalType === 'SHOW CONTRACT' || modalType === 'EDIT CONTRACT'
              }
              required={item.isRequired}
              max={item.max}
              maxLength={item.max}
              min={item.min}
            ></input>

            {item.label === 'Contractor Permanent Address' && (
              <div>
                <label style={{ fontWeight: '600', marginTop: '8px' }}>
                  {!byDateorDuration ? 'By Duration' : 'By Dates'}
                </label>
                <InputWrapper>
                  <ToggleSwitch
                    checked={byDateorDuration}
                    onChange={(e) => {
                      setByDateorDuration(e);
                    }}
                  />
                  {displayedInput}
                </InputWrapper>
              </div>
            )}
            {item.label === 'Free Hours' && (
              <>
                <div style={{ display: 'flex' }}>
                  <InputWrapper key='type' style={{ width: '50%' }}>
                    <label className='should-required'>Office Type</label>
                    <CustomRequired
                      requiredCondition={Data && Data.type.length === 0}
                      value={selectedOptionsType}
                    >
                      <Select
                        isMulti
                        name='colors'
                        placeholder='Please Select Office Type'
                        options={[
                          { label: 'Private', value: 'Private' },
                          { label: 'Shared', value: 'Shared' },
                          { label: 'Virtual', value: 'Virtual' },
                        ]}
                        onChange={handleMultiChangeType}
                        value={selectedOptionsType}
                        className='basic-multi-select'
                        noOptionsMessage={() => 'No Type available'}
                        classNamePrefix='select'
                        isDisabled={
                          modalType === 'SHOW CLIENT' || !disabledForm
                        }
                      />
                    </CustomRequired>
                  </InputWrapper>

                  {selectedOptionsType && (
                    <InputWrapper key='office' style={{ width: '50%' }}>
                      <label className='should-required'>Office</label>
                      <CustomRequired
                        requiredCondition={selectedOptionsOffice.length === 0}
                        value={selectedOptionsOffice}
                      >
                        <Select
                          isMulti
                          name='colors'
                          placeholder='Assign Office'
                          options={option}
                          onChange={handleMultiChangeOffice}
                          value={selectedOptionsOffice}
                          className='basic-multi-select'
                          noOptionsMessage={() => 'No Office available'}
                          classNamePrefix='select'
                          isDisabled={
                            modalType === 'SHOW CLIENT' || !disabledForm
                          }
                        />
                      </CustomRequired>
                    </InputWrapper>
                  )}
                </div>

                {/* <InputWrapper key={'recentAssignedOffice'}>
                <label style={{ color: 'darkgray' }}>
                  {' '}
                  Previous Changed Office
                </label>
                <Select
                  name='colors'
                  placeholder='You Can Check Recent Changed or Assigned Offices Here?'
                  options={
                    Data && Data['recentChangedOffice' as keyof IContract]
                  }
                  noOptionsMessage={() => 'No Recent Changes in  Offices'}
                  // value={selectedOptionsStatus}
                  className='basic-multi-select'
                  classNamePrefix='select'
                  isOptionDisabled={(option: any) => true}
                  maxMenuHeight={200}
                  // styles={customStyles}
                />
              </InputWrapper> */}

                <div style={{ display: 'flex' }}>
                  <InputWrapper key={'startTime'} style={{ width: '50%' }}>
                    <label className='should-required'>
                      Client Office Start Time
                    </label>
                    <input
                      type='time'
                      id='startTime'
                      onChange={(e) => handleChange(e, 'Contract')}
                      max={Data && Data['startTime' as keyof IContract].endTime}
                      value={Data && Data['startTime' as keyof IContract]}
                      disabled={modalType === 'SHOW CONTRACT' || !disabledForm}
                      required={item.isRequired}
                    ></input>
                  </InputWrapper>
                  <InputWrapper key={'endTime'} style={{ width: '50%' }}>
                    <label className='should-required'>
                      Client Office End Time
                    </label>
                    <input
                      type='time'
                      id='endTime'
                      onChange={(e) => handleChange(e, 'Contract')}
                      value={Data && Data['endTime' as keyof IContract]}
                      disabled={modalType === 'SHOW CONTRACT' || !disabledForm}
                      required={item.isRequired}
                    ></input>
                  </InputWrapper>
                </div>
              </>
            )}
          </InputWrapper>
        ))}
        {Data && Data.type.some((item: any) => item.label === 'Shared') && (
          <>
            {sharedOffices &&
              sharedOffices.map((sharedOffice: IOfficeForm, i: any) => {
                return (
                  <InputWrapper
                    key={`sharedSeats${i}`}
                    style={{ width: '50%' }}
                  >
                    <label className='should-required'>{`${sharedOffice.name} Shared Seats`}</label>
                    <input
                      type='number'
                      min='0'
                      onChange={(e) => handleChange(e, 'Contract')}
                      onWheel={(e) => {
                        if (document.activeElement instanceof HTMLElement) {
                          document.activeElement.blur();
                        }
                      }}
                      max={
                        validateSharedSeats(sharedOffice.name)
                          ? validateSharedSeats(sharedOffice.name)
                          : sharedOffice.seats
                      }
                      id={`${sharedOffice.name}_sharedSeats`}
                      required
                      disabled={!disabledForm}
                      onInvalid={(e) => {
                        let event = e.target as HTMLInputElement;
                        let validateSeats = validateSharedSeats(
                          sharedOffice.name
                        );

                        if (
                          event.value > validateSeats ||
                          event.value > sharedOffice.seats
                        ) {
                          event.setCustomValidity(
                            `${sharedOffice.name} has ${
                              validateSeats === undefined
                                ? sharedOffice.seats
                                : validateSeats
                            }  seats ${
                              validateSeats === undefined
                                ? 'in total'
                                : 'remaining'
                            } `
                          );
                        } else {
                          if (event.value === '')
                            event.setCustomValidity(
                              'Please Enter the Required Seats'
                            );
                          else {
                            event.setCustomValidity('');
                          }
                        }
                      }}
                      value={
                        Data &&
                        Data[`${sharedOffice.name}_sharedSeats`] !== undefined
                          ? Data && Data[`${sharedOffice.name}_sharedSeats`]
                          : ''
                      }
                    ></input>
                  </InputWrapper>
                );
              })}
            <InputWrapper key='sharedPrice'>
              <label className='should-required'>Shared Seat Price</label>
              <input
                type='number'
                id='sharedPrice'
                onChange={(e) => handleChange(e, 'Contract')}
                value={
                  Data && Data.addendumContract.length > 0
                    ? Data.addendumContract[Data.addendumContract.length - 1][
                        'sharedPrice' as keyof IContract
                      ]
                    : Data['sharedPrice' as keyof IContract]
                }
                disabled={modalType === 'SHOW CONTRACT' || !disabledForm}
                required
                min='0'
              ></input>
            </InputWrapper>
          </>
        )}
        {Data && Data.type.some((item: any) => item.label === 'Private') && (
          <>
            <InputWrapper key='privatePrice'>
              <label className='should-required'>Private Seat Price</label>
              <input
                type='number'
                id='privatePrice'
                onChange={(e) => handleChange(e, 'Contract')}
                value={Data && Data['privatePrice' as keyof IContract]}
                disabled={modalType === 'SHOW CONTRACT'}
                required
                min='0'
              ></input>
            </InputWrapper>
          </>
        )}

        {Data && Data.type.some((item: any) => item.label === 'Virtual') && (
          <>
            <InputWrapper key='virtualPrice'>
              <label className='should-required'>Virtual Seat Price</label>
              <input
                type='number'
                id='virtualPrice'
                onChange={(e) => handleChange(e, 'Contract')}
                value={Data && Data['virtualPrice' as keyof IContract]}
                disabled={modalType === 'SHOW CONTRACT'}
                required
                min='0'
              ></input>
            </InputWrapper>
          </>
        )}
        <InputWrapper key='security'>
          <label>
            Security{' '}
            <span>
              Automatically Calculated (Not in Addendum).Write down if You Want
              to Change !{' '}
            </span>
          </label>
          <input
            type='number'
            id='security'
            onChange={(e) => handleChange(e, 'Contract')}
            value={Data && Data['security' as keyof IContract]}
            disabled={modalType === 'SHOW CONTRACT'}
            min='0'
          ></input>
        </InputWrapper>
        <InputWrapper key='advancePayment'>
          <label>
            Advance Payment (If payment is greater than 1 month then Write Down
            Payment){' '}
          </label>
          <input
            type='number'
            id='advancePayment'
            onChange={(e) => handleChange(e, 'Contract')}
            value={Data && Data['advancePayment' as keyof IContract]}
            disabled={modalType === 'SHOW CONTRACT'}
            min='0'
          ></input>
        </InputWrapper>
        {/* <InputWrapper key='firstPaymentLessThan1Month'>
          <label>
            Payment (If First payment is less than 1 month then Write Down
            Payment){' '}
          </label>
          <input
            type='number'
            id='firstPaymentLessThan1Month'
            onChange={(e) => handleChange(e, 'Contract')}
            value={
              Data && Data['firstPaymentLessThan1Month' as keyof IContract]
            }
            disabled={modalType === 'SHOW CONTRACT'}
            min='0'
          ></input>
        </InputWrapper> */}
      </FormWrapper>
    </>
  );
}
