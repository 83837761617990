import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import reportWebVitals from './reportWebVitals';
import Cookie from 'js-cookie';
import HTTP from './api/http';

const queryString = require('query-string');
const parsed = queryString.parse(window.location.search);

if (parsed.jwt) {
  const expires = new Date();
  expires.setDate(expires.getDate() + 1);
  Cookie.set('jwt', parsed.jwt, { expires });
}

const jwtCookie = Cookie.get('jwt');

if (!jwtCookie) {
  window.location.href =
    'https://api.msbtechnologies.pk/oauth/login?service=paxico.crm';
} else {
  HTTP.setHeader('authorization', jwtCookie);
  const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
  );

  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals();
}
