import store from '@jitcoder/usestore';
import HTTP from './http';
import Client, { isClient } from '../models/client';

export const getClients = async () => {
  const clients = await HTTP.get<Client[]>('/paxico/clients');
  store.set('clients', clients);
  return clients;
};

export const getClient = async (id: string) => {
  return await HTTP.get<Client>(`/paxico/clients/${id}`);
};

export const upsertClient = async (client: any) => {
  if (isClient(client)) {
    if ('id' in client) {
      await HTTP.put<Client>(`/paxico/clients/${client.id}`, client);
      await getClients();
    } else {
      const insertedClient = await HTTP.post<Client>('/paxico/clients', client);
      const clients = store.get<Client[]>('clients', []);
      clients.push(insertedClient);
      store.set('clients', [...clients]);
    }
    return;
  }

  throw Error('An error occured while upserting client');
};

export const deleteClient = async (id: string) => {
  await HTTP.delete(`/paxico/clients/${id}`);
  await getClients();
};
