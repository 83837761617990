import { FormWrapper, InputWrapper } from './Forms.styles';
import { leadFormItems } from '../../consts/FormFields';
import { ILeadForm } from '../../interfaces';
import { useEffect, useState } from 'react';
import { ToggleSwitch } from 'react-dragswitch';
import 'react-dragswitch/dist/index.css';
import Select from 'react-select';
import { statusOptions, sourceOptions } from '../../consts/FormObjs';
import CustomRequired from '../CustomRequired/CustomRequired';

const customStyles = {
  // For the options
  option: (styles: any) => {
    return {
      ...styles,

      color: 'black',
    };
  },

  singleValue: (provided: any, state: any) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';

    return { ...provided, opacity, transition };
  },
};
export default function LeadForm({
  modalType,
  handleChange,
  leadData,
  handleOptionChange,
  onsqftChange,
}: any) {
  const [selectedOptionsSource, setSelectedOptionsSource] = useState<any>();
  const [selectedOptionsStatus, setSelectedOptionsStatus] = useState<any>();
  const [, setNotesUpdatedDateTime] = useState({
    date: '',
    time: '',
  });

  const [squareFeet, setSquareFeet] = useState(false);
  // const [status, setStatus] = useState(false);

  const handleMultiChangeSource = (option: any) => {
    setSelectedOptionsSource(option);
    handleOptionChange(option, 0, 'source');
  };

  const handleMultiChangeStatus = (option: any) => {
    setSelectedOptionsStatus(option);
    handleOptionChange(option, 0, 'status');
  };

  useEffect(() => {
    setSelectedOptionsStatus(leadData.status);
    setSelectedOptionsSource(leadData.source);
    setNotesUpdatedDateTime({
      date: leadData['notesLastUpdatedDate' as keyof ILeadForm],
      time: leadData['notesLastUpdatedTime' as keyof ILeadForm],
    });
  }, [leadData]);

  const displayedInput = squareFeet ? (
    <input
      id='sqft'
      type='number'
      onChange={onsqftChange}
      value={leadData.seats * 25}
      disabled={modalType === 'SHOW LEAD'}
    />
  ) : (
    <input
      id='seats'
      type='number'
      onChange={(e) => handleChange(e, 'Lead')}
      value={leadData.seats}
      disabled={modalType === 'SHOW LEAD'}
    />
  );

  return (
    <FormWrapper
      style={{
        pointerEvents: modalType === 'SHOW LEAD' ? 'none' : 'auto',
      }}
    >
      {leadFormItems.map((item) => (
        <InputWrapper key={item.id}>
          <label className={item.isRequired ? 'should-required' : ''}>
            {item.label}
          </label>
          <input
            type={item.type}
            id={item.id}
            onChange={(e) => handleChange(e, 'Lead')}
            disabled={modalType === 'SHOW LEAD'}
            value={
              item.id === 'leadDate' &&
              leadData &&
              leadData[item.id as keyof ILeadForm] === '' &&
              modalType === 'ADD'
                ? new Date().toISOString().substring(0, 10)
                : leadData && leadData[item.id as keyof ILeadForm]
            }
            required={item.isRequired}
          ></input>
          {item.label === 'Email' && (
            <div>
              <label style={{ fontWeight: '600', marginTop: '8px' }}>
                {!squareFeet ? 'Seats' : 'Sq.ft'}
              </label>
              <InputWrapper>
                <ToggleSwitch
                  checked={squareFeet}
                  onChange={(e) => {
                    setSquareFeet(e);
                  }}
                />
                {displayedInput}
              </InputWrapper>
            </div>
          )}
          {item.label === 'Lead Date' && (
            <div key={'source'}>
              <InputWrapper>
                <label className='should-required'>Source</label>
                <CustomRequired
                  requiredCondition={
                    (selectedOptionsSource && selectedOptionsSource !== '') ||
                    selectedOptionsSource !== undefined
                  }
                  value={selectedOptionsSource}
                >
                  <Select
                    name='colors'
                    placeholder='Lead Source?'
                    options={sourceOptions}
                    onChange={handleMultiChangeSource}
                    value={selectedOptionsSource}
                    className='basic-multi-select'
                    classNamePrefix='select'
                    maxMenuHeight={200}
                    isDisabled={modalType === 'SHOW LEAD'}
                  />
                </CustomRequired>
              </InputWrapper>
              <div style={{ display: 'flex' }}>
                <InputWrapper
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '50%',
                  }}
                >
                  <label style={{ color: 'darkgray' }}>
                    Status Last Updated Date
                  </label>
                  <input
                    type='date'
                    id='statusLastUpdatedDate'
                    disabled
                    value={leadData['statusLastUpdatedDate' as keyof ILeadForm]}
                  ></input>
                </InputWrapper>

                <InputWrapper
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '50%',
                  }}
                >
                  <label style={{ color: 'darkgray' }}>
                    Status Last Updated Time
                  </label>
                  <input
                    type='time'
                    id='statusLastUpdatedTime'
                    disabled
                    value={leadData['statusLastUpdatedTime' as keyof ILeadForm]}
                  ></input>
                </InputWrapper>
              </div>
              <div style={{ display: 'flex' }}>
                <InputWrapper
                  key={'status'}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '50%',
                  }}
                >
                  <label className='should-required'> Status</label>
                  <CustomRequired
                    requiredCondition={
                      selectedOptionsStatus &&
                      selectedOptionsStatus.length === 0
                    }
                    value={selectedOptionsStatus}
                  >
                    <Select
                      name='colors'
                      placeholder='Whats the Status?'
                      options={statusOptions}
                      onChange={handleMultiChangeStatus}
                      value={selectedOptionsStatus}
                      className='basic-multi-select'
                      classNamePrefix='select'
                      maxMenuHeight={200}
                      isDisabled={modalType === 'SHOW LEAD'}
                    />
                  </CustomRequired>
                </InputWrapper>
                <InputWrapper
                  key={'recentStatus'}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '50%',
                  }}
                >
                  <label style={{ color: 'darkgray' }}> Previous Status</label>
                  <Select
                    name='colors'
                    placeholder='You Can Check Last Updated Status Here?'
                    options={leadData['recentStatus' as keyof ILeadForm]}
                    noOptionsMessage={() => 'No Previous Status'}
                    // value={selectedOptionsStatus}
                    className='basic-multi-select'
                    classNamePrefix='select'
                    isOptionDisabled={(option: any) => true}
                    maxMenuHeight={200}
                    styles={customStyles}
                  />
                </InputWrapper>
              </div>
            </div>
          )}
        </InputWrapper>
      ))}
      <div style={{ display: 'flex' }}>
        <InputWrapper
          style={{ display: 'flex', flexDirection: 'column', width: '50%' }}
        >
          <label style={{ color: 'darkgray' }}>Notes Last Updated Date</label>
          <input
            type='date'
            id='notesLastUpdatedDate'
            onChange={(e) => handleChange(e, 'Lead')}
            disabled
            value={leadData['notesLastUpdatedDate' as keyof ILeadForm]}
          ></input>
        </InputWrapper>

        <InputWrapper
          style={{ display: 'flex', flexDirection: 'column', width: '50%' }}
        >
          <label style={{ color: 'darkgray' }}>Notes Last Updated Time</label>
          <input
            type='time'
            id='notesLastUpdatedTime'
            onChange={(e) => handleChange(e, 'Lead')}
            disabled
            value={leadData['notesLastUpdatedTime' as keyof ILeadForm]}
          ></input>
        </InputWrapper>
      </div>
      <div style={{ display: 'flex' }}>
        <InputWrapper
          style={{ display: 'flex', flexDirection: 'column', width: '50%' }}
        >
          <label>Requirments</label>
          <textarea
            rows={4}
            cols={50}
            onChange={(e) => handleChange(e, 'Lead')}
            id='requirments'
            disabled={modalType === 'SHOW LEAD'}
            value={leadData && leadData['requirments' as keyof ILeadForm]}
          />
        </InputWrapper>
        <InputWrapper
          style={{ display: 'flex', flexDirection: 'column', width: '50%' }}
        >
          <label>Notes</label>
          <textarea
            rows={4}
            cols={50}
            onChange={(e) => handleChange(e, 'Lead')}
            id='notes'
            disabled={modalType === 'SHOW LEAD'}
            value={leadData && leadData['notes' as keyof ILeadForm]}
          />
        </InputWrapper>
      </div>
    </FormWrapper>
  );
}
