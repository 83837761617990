import store from '@jitcoder/usestore';
import HTTP from './http';
import Contract, { isContract } from '../models/contract';

export const getContracts = async () => {
  const contracts = await HTTP.get<Contract[]>('/paxico/contracts');
  store.set('contracts', contracts);
  return contracts;
};

export const getContract = async (id: string) => {
  return await HTTP.get<Contract>(`/paxico/contracts/${id}`);
};

export const upsertContract = async (contract: any) => {
  if (isContract(contract)) {
    if ('id' in contract) {
      await HTTP.put<Contract>(`/paxico/contracts/${contract.id}`, contract);
      await getContracts();
    } else {
      const insertedContract = await HTTP.post<Contract>(
        '/paxico/contracts',
        contract
      );
      const contracts = store.get<Contract[]>('contracts', []);
      contracts.push(insertedContract);
      store.set('contracts', [...contracts]);
    }
    return;
  }

  throw Error('An error occured while upserting contract');
};

export const deleteContract = async (id: string) => {
  await HTTP.delete(`/paxico/contracts/${id}`);
  await getContracts();
};
