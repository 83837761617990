import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import DataTable from 'react-data-table-component';
import { InputWrapper } from '../Forms/Forms.styles';
import { GoArrowSmallUp } from 'react-icons/go';
import { ModalInnerWrapper } from './Modal.styles';
import { TableButton } from '../Table/Table.styles';

const columns = [
  {
    id: 1,
    name: 'Item Label',
    selector: (row: any) => row.item,
    sortable: true,
    reorder: true,
  },
  {
    id: 2,
    name: 'Amount',
    selector: (row: any) => row.amount,
    sortable: true,
    reorder: true,
  },
];
function AddItemInvoiceModal({
  showModal,
  renderAddItemModalCallback,
  additionalItemCallback,
  additionalItems,
}: any) {
  const [show, setShow] = useState(false);
  const [additionalItem, setAdditionalItem] = useState({});

  // const Data = store.get<any>('isPaidData', {});
  useEffect(() => {
    setShow(showModal);
  }, [showModal]);

  const handleClose = () => {
    renderAddItemModalCallback();
    setShow(false);
  };

  const handleChange = (e: any) => {
    setAdditionalItem((prev: any) => ({
      ...prev,
      [e.target.id]: e.target.value,
    }));
  };

  const setDate = (e: any) => {
    e.preventDefault();
    console.log('calledddd');
    additionalItemCallback(additionalItem);

    renderAddItemModalCallback();

    setShow(false);
  };

  return (
    <>
      <Modal
        show={show}
        onHide={() => renderAddItemModalCallback()}
        onExited={(e) => {
          // store.set('showCustom', false);
          setShow(false);
        }}
        style={{
          position: 'fixed',
          width: 'auto',
          right: ' calc(-50vw + 50%)',
          left: 'calc(-50vw + 50%)',
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Items to Invoice </Modal.Title>
        </Modal.Header>
        <ModalInnerWrapper onSubmit={setDate}>
          <Modal.Body>
            <div style={{ display: 'flex' }}>
              <InputWrapper key='itemLabel'>
                <label style={{ fontWeight: '600', paddingRight: '10px' }}>
                  Item Label
                </label>
                <input
                  type='text'
                  id='item'
                  required
                  onChange={(e) => handleChange(e)}
                ></input>
              </InputWrapper>
              <InputWrapper key='itemAmount'>
                <label>Item Amount</label>
                <input
                  type='text'
                  id='amount'
                  required
                  onChange={(e) => handleChange(e)}
                ></input>
              </InputWrapper>
            </div>
            <DataTable
              columns={columns}
              data={additionalItems}
              defaultSortFieldId={1}
              sortIcon={<GoArrowSmallUp />}
              pagination
            />
          </Modal.Body>

          <Modal.Footer>
            <Button variant='secondary' onClick={handleClose}>
              Close
            </Button>
            <TableButton type='submit'>Save Item</TableButton>
          </Modal.Footer>
        </ModalInnerWrapper>
      </Modal>
    </>
  );
}

export default AddItemInvoiceModal;
