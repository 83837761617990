import Leads from '../Pages/Leads';
import Clients from '../Pages/Clients';
import Offices from '../Pages/Offices';
import MeetingRooms from '../Pages/MeetingRooms';
import Contracts from '../Pages/Contracts';
import MissedCalls from '../Pages/MissedCalls/index';
import Called from '../Pages/Called/index';
import { Routes, Route } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

export default function Routess() {
  const location = useLocation();

  useEffect(() => {
    if (location.pathname !== '/contracts') {
      let nav = document.getElementById('navbar');
      if (nav) nav.style.display = 'table';
    }
  }, [location.pathname]);

  return (
    <Routes>
      <Route path='/' element={<Leads />} />
      <Route path='/clients' element={<Clients />} />
      <Route path='/offices' element={<Offices />} />
      <Route path='/meetingRooms' element={<MeetingRooms />} />
      <Route path='/contracts' element={<Contracts />} />
      <Route path='/missedcalls' element={<MissedCalls />} />
      <Route path='/answeredcalls' element={<Called />} />
    </Routes>
  );
}
