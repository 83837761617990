import styled from 'styled-components';

export const PageWrapper = styled.div`
  width: 85%;
  overflow: auto;
  @media screen and (max-width: 400px) {
    width: 100%;
  }
`;

export const InvoicesWrapper = styled.div`
  overflow: auto;
  @media screen and (max-width: 400px) {
    width: 100%;
  }
`;
