export const isMultiple=((multiple:any,multipleOf:any)=>{
    let i=2;
    let result=0
    if(multipleOf>multiple)
    {
        return 0
    }
    while(i!==10)
    {
        result= multipleOf*i
      
        if(result===multiple)
        {
            return i
        }
        ++i
    }
    return 0

})