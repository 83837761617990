import styled from 'styled-components';
interface buttonProps {
  heigth?: string;
  backgroundColor?: string;
  onHoverBackgroundColor?: string;
}
export const TableWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding-top: 25px;

  @media screen and (max-width: 400px) {
    display: flex;
    flex-direction: column-reverse;
    > label {
      margin-left: 0px;
    }
    > div {
      margin-right: 0px;
    }
  }
`;
export const DataTableWrapper = styled.div`
  > div {
    overflow: auto;
  }
`;
export const TotalOfficesWraper = styled.div``;
export const SearchInputParentWrapper = styled.div`
  display: flex;
  margin-right: 70px;
  align-items: center;
  @media screen and (max-width: 400px) {
    margin-right: 0px;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    > label {
      margin-top: 10px;
    }
    > button {
      margin-top: 10px;
      margin-right: 10px;
      margin-left: 10px;
      width: 50%;
    }
  }
`;

export const TableTitle = styled.label`
  font-size: 2.2rem;
  font-weight: 500;
  margin-left: 50px;
`;
export const TableButton = styled.button`
  border: none;
  width: auto;
  height: ${(p: buttonProps) => (p.heigth ? p.heigth : '36px')};
  border-radius: 12px;
  min-width: 100px;
  background-color: ${(p: buttonProps) =>
    p.backgroundColor ? p.backgroundColor : 'cadetblue'};
  color: white;
  cursor: pointer;
  font-size: 0.8rem;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  &:hover {
    background: ${(p: buttonProps) =>
      p.onHoverBackgroundColor ? p.onHoverBackgroundColor : '#3c8285'};
  }
`;

export const TableSearchInputWrapper = styled.label`
  display: flex;
  align-items: center;
  position: relative;
  > input {
    font-size: 1.3rem;
    border-radius: 12px;
    outline: none;
    padding-left: 10px;
    margin-right: 50px;
    caret-shape: underscore;
    caret-color: cadetblue;
    border: none;
    padding-left: 20px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
      rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;

    @media screen and (max-width: 400px) {
      margin-right: 10px;
      margin-left: 10px;
      width: -webkit-fill-available;
    }
  }
  > svg {
    color: cadetblue;
  }
`;
