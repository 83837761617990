import { useState, useEffect, useCallback, useRef, memo } from 'react';
import { Table } from '../../Components/Table';
import { PageWrapper } from '../Page.styles';
import { FaTrash } from 'react-icons/fa';
import { TbFileInvoice } from 'react-icons/tb';

import { getClients } from '../../api/clients';
import { getOffices } from '../../api/offices';
import { getContracts } from '../../api/contracts';

import store from '@jitcoder/usestore';
import { IContract, Office } from '../../interfaces';
import { getTotalSeatsOfClient } from '../../lib/getTotalSeatsOfClient';
import { getById } from '../../lib/getById';

export const Clients = memo(() => {
  const [modalType, setModalType] = useState('');
  const [renderStop, setRenderStop] = useState(false);
  const storedOffices = store.get<Office[]>('offices', []);
  const storedContracts = store.get<IContract[]>('contracts', []);
  const childRef: any = useRef();

  const [rowId, setRowId] = useState('');

  const ref: any = useRef({
    totalAssignedSeats: '',
  });

  const columns = [
    {
      id: 1,
      name: 'Name',
      selector: (row: any) => row.name,
      sortable: true,
      reorder: true,
      center: true,
    },

    {
      id: 2,
      name: 'Email',
      selector: (row: any) => row.email,
      sortable: true,
      reorder: true,
      center: true,
    },
    {
      id: 3,
      name: 'Total Seats',
      selector: (row: any) => row.email,
      sortable: true,
      reorder: true,
      center: true,
      cell: (row: any) => {
        return (
          <span>
            {ref.current.totalAssignedSeats &&
            ref.current.totalAssignedSeats[row.name] === 0
              ? ''
              : ref.current.totalAssignedSeats &&
                ref.current.totalAssignedSeats[row.name]}
          </span>
        );
      },
    },
    {
      id: 4,
      name: 'Contract Status',
      right: false,
      center: true,
      cell: (row: any) => {
        const contract: any = getById(
          row.contract[0] && row.contract[0].value,
          storedContracts
        );

        let dueDate = contract && contract[0] && contract[0].endDate;
        let currentData = new Date().toISOString().substring(0, 10);
        currentData = String(new Date(currentData).getTime());
        dueDate = String(new Date(dueDate).getTime());
        let status = dueDate <= currentData;

        return (
          <>
            <span
              style={{ color: status ? 'red' : 'green', fontWeight: '500' }}
            >
              {status ? 'Expired' : 'Active'}
            </span>
          </>
        );
      },
    },

    {
      id: 5,
      name: 'Invoice',
      sortable: true,
      reorder: true,
      center: true,
      cell: (row: any) => (
        <>
          <TbFileInvoice
            style={{ marginRight: '5px', fontSize: '1rem' }}
            onClick={() => {
              childRef && childRef.current && childRef.current.handleShow();

              setModalType('INVOICE');
              setRowId(row.id);
            }}
          ></TbFileInvoice>
        </>
      ),
    },
    {
      id: 6,
      right: false,
      cell: (row: any) => (
        <>
          <FaTrash
            style={{ marginRight: '5px', fontSize: '1rem' }}
            onClick={() => {
              childRef && childRef.current && childRef.current.handleShow();

              setModalType('Delete');
              setRowId(row.id);
              store.set('active-modal', true);
            }}
          ></FaTrash>
        </>
      ),
    },
  ];

  const fetchAPI = useCallback(async () => {
    if (!renderStop) {
      let clients: any = await getClients();
      await getOffices();
      await getContracts();

      let seats = getTotalSeatsOfClient(clients, storedOffices);
      ref.current.totalAssignedSeats = seats;
      setRenderStop(true);
    }
  }, [renderStop, storedOffices]);

  useEffect(() => {
    fetchAPI();
  }, [fetchAPI]);

  return (
    <PageWrapper>
      <Table
        columns={columns}
        defaultSortFieldId={1}
        title={'Clients'}
        modalType={modalType}
        rowId={rowId}
        buttonTitle={'ADD CLIENT'}
        hideButton={true}
        refreshIcon={true}
        childRef={childRef}
      ></Table>
    </PageWrapper>
  );
});
export default Clients;
