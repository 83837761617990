import { useCallback, useRef, useState } from 'react';
import Invoices from '../../Pages/Clients/invoices';
import InvoiceView from '../../Pages/InvoiceView/InvoiceView';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { InputWrapper } from './Forms.styles';
import { MdOutlineAddCircleOutline } from 'react-icons/md';

export default function InvoiceForm({
  invoiceMonthCallback,
  handleScheduleRowDelete,
  renderAddItemModalCallback,
  clientData,
}: any) {
  const [invoiceData, SetInvoiceData] = useState({});
  const [key, setKey] = useState<any>();
  const ref = useRef();

  const viewInvoice = useCallback((value: boolean, data: any) => {
    SetInvoiceData(data);
    setKey(3);
  }, []);

  const handleSelect = (key: any) => {
    setKey(key);
  };

  return (
    <>
      <Tabs
        defaultActiveKey={2}
        activeKey={key}
        id='uncontrolled-tab-example'
        className='mb-3'
        onSelect={handleSelect}
      >
        <Tab eventKey={2} title='Invoices'>
          <>
            <InputWrapper
              style={{
                paddingBottom: '10px',
                top: '6%',
                left: '15%',
                position: 'absolute',
                display: 'flex',
              }}
            >
              <label
                htmlFor='searchByMonth'
                style={{ whiteSpace: 'nowrap', marginRight: '2px' }}
              >
                Invoice Month
              </label>
              <input
                type='month'
                name='searchByMonth'
                onChange={invoiceMonthCallback}
                style={{ width: '140px' }}
                required
                // disabled={modalType === 'SHOW SCHEDULE ROOM'}
              ></input>
            </InputWrapper>
            <InputWrapper
              style={{
                paddingBottom: '10px',
                top: '6%',
                left: '47%',
                position: 'absolute',
                display: 'flex',
              }}
            >
              <label
                htmlFor='dueDateInvoice'
                style={{ whiteSpace: 'nowrap', marginRight: '2px' }}
              >
                Due Date Invoice
              </label>
              <input
                type='date'
                name='dueDateInvoice'
                onChange={invoiceMonthCallback}
                style={{ width: '120px' }}
                required
                // disabled={modalType === 'SHOW SCHEDULE ROOM'}
              ></input>
            </InputWrapper>

            {clientData.invoices.length === 0 && (
              <InputWrapper
                style={{
                  paddingBottom: '10px',
                  top: '6%',
                  left: '79%',
                  position: 'absolute',
                  display: 'flex',
                }}
              >
                <input
                  type='number'
                  placeholder='Days'
                  name='days'
                  onChange={invoiceMonthCallback}
                  style={{ width: '80px' }}

                  // disabled={modalType === 'SHOW SCHEDULE ROOM'}
                ></input>
              </InputWrapper>
            )}
            <MdOutlineAddCircleOutline
              style={{
                paddingBottom: '10px',
                top: '9%',
                color: 'cadetblue',
                right: '5%',
                fontSize: '2rem',
                position: 'absolute',
                display: 'flex',
              }}
              onClick={() => renderAddItemModalCallback()}
            />
            <Invoices
              parentData={clientData}
              viewInvoice={viewInvoice}
              handleScheduleRowDelete={handleScheduleRowDelete}
            ></Invoices>
          </>
        </Tab>
        {key === 3 && (
          <Tab eventKey={3} title='View Invoice' id='viewInvoice'>
            <>
              <InvoiceView
                invoiceData={invoiceData}
                clientData={clientData}
                generateRef={ref}
              ></InvoiceView>
            </>
          </Tab>
        )}
      </Tabs>
    </>
  );
}
