import { findOfficeById } from './findOfficeById';

export const getTotalSeatsOfClient = (
  storedClients: any,
  storedOffices: any
) => {
  let totalSeats: any = {};
  storedClients.forEach((client: any, i: any) => {
    totalSeats[client.name] = 0;
    client.offices.forEach((office: any, j: any) => {
      let getOffice = findOfficeById(office.value, storedOffices);

      if (getOffice[0] && getOffice[0].type.label === 'Private') {
        totalSeats[client.name] =
          totalSeats[client.name] + Number(getOffice[0].seats);
      } else if (getOffice[0] && getOffice[0].type.label === 'Shared') {
        totalSeats[client.name] =
          totalSeats[client.name] +
          Number(client[`${office.label}_sharedSeats`]);
      } else if (getOffice[0] && getOffice[0].type.label === 'Virtual') {
        totalSeats[client.name] =
          totalSeats[client.name] + Number(getOffice[0].seats);
      }
    });
  });

  return totalSeats;
};
