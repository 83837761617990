import React, { useCallback, useEffect, useState } from 'react';
import { FormWrapper, InputWrapper, ScheduleFormWrapper } from './Forms.styles';
import Select from 'react-select';
import store from '@jitcoder/usestore';
import Client from '../../models/client';
import { FaTrash } from 'react-icons/fa';
import CustomRequired from '../CustomRequired/CustomRequired';

export default function ScheduleRoomForm({
  modalType,
  handleChange,
  handleOptionChange,
  meetingRoomData,
  validateElement,
  validateElement2,
  handleScheduleRowDelete,
}: any) {
  const clients = store.get<Client[]>('clients', []);
  const [option, setOption] = useState([]);
  const [Data, setData] = useState(meetingRoomData.scheduledClients);
  const [selectedOptions, setSelectedOptions] = useState<any>([]);
  const [renderStop, setRenderStop] = useState(false);
  const [Index, setIndex] = useState(0);

  const handleMultiChange = (opt: any) => {
    let dummySelected: any = [...selectedOptions];
    handleOptionChange(opt, Index);
    dummySelected[Index] = opt;
    setSelectedOptions(dummySelected);
  };

  const handleChangeSearchByDate = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    let filteredScheduled = meetingRoomData.scheduledClients.filter(
      (item: any) =>
        item.startDate.split('-')[0] + '-' + item.startDate.split('-')[1] ===
        event.target.value
    );

    if (filteredScheduled.length > 0) {
      setData(filteredScheduled);
    } else {
      setData(meetingRoomData.scheduledClients);
    }
  };

  const updateOption: any = useCallback(async () => {
    if (!renderStop) {
      let opt: any = {};
      let options: any = [];

      for (let i = 0; i < clients.length; i++) {
        opt['label'] = clients[i].name;
        opt['value'] = clients[i].id;
        options.push({ ...opt });
      }
      setOption(options);
      setRenderStop(true);
    }
  }, [clients, renderStop]);

  useEffect(() => {
    updateOption();
  }, [updateOption]);

  useEffect(() => {
    let selectedDummy: any = [];
    for (const clients of meetingRoomData.scheduledClients) {
      selectedDummy.push({ ...clients.client });
    }

    setSelectedOptions(selectedDummy);
  }, [
    meetingRoomData.scheduledClients,
    meetingRoomData.scheduledClients.client,
  ]);

  useEffect(() => {
    setData(meetingRoomData.scheduledClients);
  }, [meetingRoomData.scheduledClients]);
  return (
    <FormWrapper
      style={{
        pointerEvents: modalType === 'SHOW SCHEDULE ROOM' ? 'none' : 'auto',
      }}
    >
      <InputWrapper style={{ paddingBottom: '10px' }}>
        <label htmlFor='searchByMonth'>Search By Month</label>
        <input
          type='month'
          name='searchByMonth'
          placeholder='Search By Date'
          onChange={handleChangeSearchByDate}
          disabled={modalType === 'SHOW SCHEDULE ROOM'}
        ></input>
      </InputWrapper>

      {Data &&
        Data.map((item: any, index: any) => {
          let expire = false;
          // let clientDate = new Date(
          //   item.startDate.split('-')[0],
          //   item.startDate.split('-')[1] - 1,
          //   item.startDate.split('-')[2],
          //   item.endTime.split(':')[0],
          //   item.endTime.split(':')[1],
          //   0
          // );

          // if (
          //   item.startDate !== undefined &&
          //   clientDate.getTime() <= new Date().getTime()
          // ) {
          //   expire = true;
          // }
          return (
            <ScheduleFormWrapper
              key={index}
              style={{
                backgroundColor: expire ? 'lightgrey' : 'none',
                pointerEvents: expire ? 'none' : 'auto',
                padding: '10px',
              }}
            >
              <InputWrapper>
                <label>Client </label>
                <CustomRequired
                  requiredCondition={true}
                  value={selectedOptions[index] && selectedOptions[index].label}
                >
                  <Select
                    name={index}
                    placeholder='Select Client'
                    options={option}
                    onChange={handleMultiChange}
                    value={selectedOptions[index]}
                    className='basic-multi-select'
                    classNamePrefix='select'
                    id={`client ${index}`}
                    onMenuOpen={() => {
                      setIndex(index);
                    }}
                    isDisabled={modalType === 'SHOW SCHEDULE ROOM'}
                  />
                </CustomRequired>
              </InputWrapper>
              <InputWrapper>
                <label>Start Date</label>
                <input
                  type='date'
                  placeholder='Start Date'
                  id={`startDate ${index}`}
                  onChange={handleChange}
                  value={item.startDate}
                  // min={new Date().toISOString().split('T')[0]}
                  required
                  disabled={modalType === 'SHOW SCHEDULE ROOM'}
                ></input>
              </InputWrapper>

              <InputWrapper>
                <label>Start Time</label>
                <input
                  type='time'
                  placeholder='Start Time'
                  id={`startTime ${index}`}
                  onChange={handleChange}
                  value={item.startTime}
                  required
                  max={item.endTime}
                  // max={item.endTime}
                  disabled={modalType === 'SHOW SCHEDULE ROOM'}
                ></input>
              </InputWrapper>
              <InputWrapper>
                <label>End Time</label>
                <input
                  type='time'
                  placeholder='End Time'
                  id={`endTime ${index}`}
                  onChange={handleChange}
                  value={item.endTime}
                  required
                  disabled={modalType === 'SHOW SCHEDULE ROOM'}
                ></input>
              </InputWrapper>
              {!expire && (
                <FaTrash
                  style={{
                    fontSize: '1.5rem',
                    marginTop: '40px',
                    marginLeft: '6px',
                  }}
                  id={`delete ${index}`}
                  onClick={() =>
                    handleScheduleRowDelete(item, index, 'DELETE SCHEDULE ROOM')
                  }
                  display={modalType === 'SHOW SCHEDULE ROOM' ? 'none' : ''}
                />
              )}
            </ScheduleFormWrapper>
          );
        })}

      <div style={{ textAlign: 'center' }}>
        <span>
          {meetingRoomData.scheduledClients &&
          meetingRoomData.scheduledClients.length === 0 &&
          modalType !== 'SHOW SCHEDULE ROOM'
            ? 'Click on Add Button to make Room Schedule for Client'
            : null}
          {modalType === 'SHOW SCHEDULE ROOM' &&
          meetingRoomData.scheduledClients.length === 0
            ? 'No Client Scheduled for this Room'
            : null}
        </span>
      </div>
      <div style={{ textAlign: 'center' }}>
        <span style={{ color: 'red' }}>
          {validateElement}
          {validateElement2}
        </span>
      </div>
    </FormWrapper>
  );
}
