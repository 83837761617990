import store from '@jitcoder/usestore';
import HTTP from './http';
import ICallLog from '../interfaces';

export const getMissedCalls = async () => {
  const missedCalls = await HTTP.get<ICallLog[]>('/paxico/call-log/missed');
  store.set('missedCalls', missedCalls);
  return missedCalls;
};

export const getCalled = async () => {
  const answaredCalls = await HTTP.get<ICallLog[]>('/paxico/call-log/others');
  store.set('answaredCalls', answaredCalls);
  return answaredCalls;
};

export const upsertCallLog = async (callLog: any) => {
  const updatedCallLog = await HTTP.put<ICallLog>('/paxico/call-log', callLog);
  const callLogs = store.get<ICallLog[]>('callLogs', []);
  callLogs.push(updatedCallLog);
  store.set('callLogs', [...callLogs]);
};
