export default interface MeetingRoom {
  id: string;
  name: string;
  scheduledClients: any;
}

export const isMeetingRoom = (obj: any, validate = false) => {
  let isValidate = false;
  if (Array.isArray(obj)) {
    let hasProp = false;

    hasProp = obj.every(
      (item: any) => 'name' in item || 'scheduledClients' in item
    );

    if (hasProp) {
      isValidate = true;
    }
  } else {
    const hasProperties = 'name' in obj || 'scheduledClients' in obj;

    if (hasProperties) {
      isValidate = true;
    }
  }

  return isValidate;
};
