import store from '@jitcoder/usestore';
import HTTP from './http';
import Lead, { isLead } from '../models/lead';

export const getLeads = async () => {
  const leads = await HTTP.get<Lead[]>('/paxico/leads');
  store.set('leads', leads);
  return leads;
};

export const getLead = async (id: string) => {
  return await HTTP.get<Lead>(`/paxico/leads/${id}`);
};

export const upsertLead = async (lead: any) => {
  if (isLead(lead)) {
    if ('id' in lead) {
      await HTTP.put<Lead>(`/paxico/leads/${lead.id}`, lead);
      await getLeads();
    } else {
      const insertedLead = await HTTP.post<Lead>('/paxico/leads', lead);
      const leads = store.get<Lead[]>('leads', []);
      leads.push(insertedLead);
      store.set('leads', [...leads]);
    }
    return;
  }

  throw Error('An error occured while upserting lead');
};

export const deleteLead = async (id: string) => {
  await HTTP.delete(`/paxico/leads/${id}`);
  await getLeads();
};
