import DataTable from 'react-data-table-component';
import Modal from '../Modal';
import { FaAngleDown, FaSearch } from 'react-icons/fa';
import React, { useState, useEffect, useCallback } from 'react';
import { FiRefreshCw } from 'react-icons/fi';

import {
  TableWrapper,
  TableTitle,
  TableSearchInputWrapper,
  TableButton,
  SearchInputParentWrapper,
  DataTableWrapper,
  TotalOfficesWraper,
} from './Table.styles';
import store, { useStore } from '@jitcoder/usestore';
import Lead from '../../models/lead';
import moment from 'moment';
import Contract from '../../models/contract';
import { getTotalSeatsOfClient } from '../../lib/getTotalSeatsOfClient';
import Client from '../../models/client';
import ICallLog, { Office } from '../../interfaces';
import { getLead } from '../../api/leads';
import { getOffice } from '../../api/offices';
import { getClient } from '../../api/clients';
import { getMeetingRoom } from '../../api/meetingRooms';
import { getContract } from '../../api/contracts';
import { getMissedCalls, getCalled } from '../../api/callLogs';

const customStyles = {
  rows: {
    style: {
      minHeight: '40px', // override the row height
      '&:hover': {
        background: '#f6f6f6',
        cursor: 'pointer',
      },
    },
  },
  headCells: {
    style: {
      paddingLeft: '8px', // override the cell padding for head cells
      paddingRight: '8px',
    },
  },
  cells: {
    style: {
      paddingLeft: '8px', // override the cell padding for data cells
      paddingRight: '8px',
      // width: '160px',
    },
  },
};

export const Table = React.memo(
  ({
    columns,
    title,
    buttonTitle,
    modalShow,
    refreshIcon,
    onRowClick,
    parentData,
    modalType,
    rowId,
    childRef,
    hideButton,
    ...props
  }: any) => {
    const [filteredData, setFilteredData] = useState([]);
    // const [stopRender, setStopRender] = useState(0);
    const [data, setData] = useState([]);
    const [searchInput, setSearchInput] = useState('');
    const [leadDateSort, setLeadDateSort] = useState(false);
    const [type, setType] = useState('');
    const [totalSeats, setTotalSeats] = useState(0);
    const [assignedSeats, setAssignedSeats] = useState(0);
    const [Leads]: any = useStore<Lead[]>('leads', []);
    const [Offices]: any = useStore<Lead[]>('offices', []);
    const [Clients]: any = useStore<Lead[]>('clients', []);
    const [missedCalls]: any = useStore<ICallLog[]>('missedCalls', []);
    const [answaredCalls]: any = useStore<ICallLog[]>('answaredCalls', []);
    const storedClients = store.get<Client[]>('clients', []);
    const storedOffices = store.get<Office[]>('offices', []);
    const [Contracts]: any = useStore<Contract[]>('contracts', []);
    const [clickedRow, setClickedRow] = useState('');
    const [MeetingRooms]: any = useStore<Lead[]>('meetingRooms', []);
    const [rowID, setRowID] = useState(rowId);
    const [rotateRefreshIcon, setRotateRefreshIcon] = useState(false);

    const handleRotate = () => setRotateRefreshIcon(!rotateRefreshIcon);

    const conditionalRowStyles = [
      {
        when: (row: any) => row.id === clickedRow,
        style: {
          backgroundColor: 'rgb(36 135 138)',
          color: 'white',
        },
      },
      {
        when: (row: any) =>
          leadDateSort &&
          row.visitDate &&
          row.visitDate === moment().format('YYYY-MM-DD'),

        style: {
          backgroundColor: 'lightgrey',
          color: 'black',
        },
      },
    ];

    useEffect(() => {
      setType(modalType);
      setRowID(rowId);
    }, [modalType, rowId]);

    const fetchAPI = async (rowId: any) => {
      try {
        if (title === 'Leads') {
          let getData: any = await getLead(rowId);
          setData(getData);
        } else if (title === 'Offices') {
          let getData: any = await getOffice(rowId);
          setData(getData);
        } else if (title === 'Clients') {
          let getData: any = await getClient(rowId);
          setData(getData);
        } else if (title === 'Meeting Rooms') {
          let getData: any = await getMeetingRoom(rowId);
          setData(getData);
        } else if (title === 'Contracts') {
          let getData: any = await getContract(rowId);
          setData(getData);
        } else if (title === 'Missed Calls') {
          let getData: any = await getMissedCalls();
          setData(getData);
        } else if (title === 'Answered Calls') {
          let getData: any = await getCalled();
          setData(getData);
        }
      } catch (e: any) {
        console.log(e);
      }
    };

    const setDataForTable = useCallback(() => {
      if (title === 'Clients' && Clients.length > 0) setData(Clients);
      else if (title === 'Leads' && Leads.length > 0) setData(Leads);
      else if (title === 'Offices' && Offices.length > 0) {
        setData(Offices);
        let seats = 0;
        let assignSeats = 0;
        let totalSeats = 0;

        totalSeats = getTotalSeatsOfClient(storedClients, storedOffices);

        Object.values(totalSeats).forEach((value: any) => {
          assignSeats = assignSeats + value;
        });

        for (const office of Offices) {
          seats = seats + Number(office.seats);
        }

        setTotalSeats(seats);
        setAssignedSeats(assignSeats);
      } else if (title === 'Meeting Rooms' && MeetingRooms.length > 0)
        setData(MeetingRooms);
      else if (title === 'Invoices') {
        setData(parentData && parentData);
      } else if (title === 'Contracts' && Contracts.length > 0) {
        setData(Contracts);
      } else if (title === 'Missed Calls' && missedCalls.length > 0) {
        setData(missedCalls);
      } else if (title === 'Answered Calls' && answaredCalls.length > 0) {
        setData(answaredCalls);
      }
    }, [
      Clients,
      Contracts,
      Leads,
      MeetingRooms,
      Offices,
      answaredCalls,
      missedCalls,
      parentData,
      storedClients,
      storedOffices,
      title,
    ]);

    useEffect(() => {
      setDataForTable();
    }, [setDataForTable]);

    const filterByValue = useCallback(
      (arr = [], query = '') => {
        let dummyData: any = [...data];
        const reg = new RegExp(query, 'i');
        return arr.filter((item) => {
          let flag = false;
          for (dummyData in item) {
            if (reg.test(item[dummyData])) flag = true;
          }
          return flag;
        });
      },
      [data]
    );

    const handleSearchOnChange = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchInput(e.target.value);
        setFilteredData(filterByValue(data, e.target.value));
      },
      [data, filterByValue]
    );

    const handleRowClicked = (row: any) => {
      if (buttonTitle === 'ADD LEAD') {
        childRef.current && childRef.current.handleShow();

        setType('SHOW LEAD');
        setRowID(row.id);
      } else if (buttonTitle === 'ADD OFFICE') {
        childRef.current && childRef.current.handleShow();

        setType('SHOW OFFICE');
        setRowID(row.id);
      } else if (buttonTitle === 'ADD CLIENT') {
        childRef.current && childRef.current.handleShow();

        setType('SHOW CLIENT');
        setRowID(row.id);
      } else if (buttonTitle === 'ADD MEETING ROOM') {
        childRef.current && childRef.current.handleShow();

        setType('SHOW SCHEDULE ROOM');
        setRowID(row.id);
      } else if (buttonTitle === 'ADD CONTRACT') {
        childRef.current && childRef.current.handleShow();

        setType('SHOW CONTRACT');
        setRowID(row.id);
      }

      setClickedRow(row.id);
    };

    return (
      <>
        <TableWrapper>
          <TableTitle>{title}</TableTitle>
          <TotalOfficesWraper>
            {title === 'Offices' && data.length !== 0 && (
              <div style={{ marginLeft: '10px' }}>
                <span style={{ marginRight: '10px' }}>
                  <span style={{ fontWeight: 600 }}>Total Seats : </span>
                  {totalSeats}
                </span>
                <span style={{ marginRight: '10px' }}>
                  <span style={{ fontWeight: 600 }}> Assigned Seats : </span>
                  {assignedSeats}
                </span>
                <span style={{ marginRight: '10px' }}>
                  <span style={{ fontWeight: 600 }}> Remaining Seats : </span>
                  {totalSeats - assignedSeats}
                </span>
              </div>
            )}
          </TotalOfficesWraper>
          <SearchInputParentWrapper>
            <TableSearchInputWrapper>
              <input
                type='text'
                id='search'
                name='search'
                value={searchInput}
                onChange={(e) => handleSearchOnChange(e)}
              ></input>
              <FaSearch
                style={{ top: '25%', right: '19%', position: 'absolute' }}
              />
            </TableSearchInputWrapper>
            {refreshIcon ? (
              <FiRefreshCw
                style={{
                  color: 'cadetblue',
                  fontSize: '1.3rem',
                  transform: rotateRefreshIcon ? 'rotate(180deg)' : 'rotate(0)',
                  transition: 'all 0.2s linear',
                }}
                onClick={() => {
                  handleRotate();
                  fetchAPI(rowId);
                }}
              />
            ) : (
              ''
            )}

            {!hideButton && hideButton === undefined ? (
              <TableButton
                onClick={() => {
                  childRef.current && childRef.current.handleShow();
                  setType('ADD');
                }}
              >
                {buttonTitle}
              </TableButton>
            ) : null}
          </SearchInputParentWrapper>
        </TableWrapper>
        <DataTableWrapper>
          <DataTable
            columns={columns}
            {...props}
            data={filteredData.length > 0 ? filteredData : data}
            onRowClicked={onRowClick === false ? () => {} : handleRowClicked}
            conditionalRowStyles={conditionalRowStyles}
            responsive={true}
            sortIcon={<FaAngleDown />}
            defaultSortAsc={false}
            onSort={(e) => {
              if (e.name === 'Visit Date') setLeadDateSort(!leadDateSort);
            }}
            customStyles={customStyles}
            pagination
          />
        </DataTableWrapper>

        <Modal
          title={buttonTitle}
          modalType={type}
          rowId={rowID ? rowID : rowId}
          ref={childRef}
        />
      </>
    );
  }
);
